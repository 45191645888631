import React, { Component } from "react";
import { Link } from "react-router-dom";

class OverzichtProject extends Component {
  render() {
    return (
      <div className="tegeloverzicht__wrapper">
        <section className="tegeloverzicht tegeloverzicht-project">
          {this.props.subchapters.map((subchapter) => (
            <Link
              key={subchapter.number}
              className="subchapter__tile subchapter__tile--project"
              to={subchapter.url}
            >
              <div className="subchapter__img-wrapper">
                <div
                  className={`subchapter__img subchapter__img--project${subchapter.number}`}
                ></div>
              </div>
              <div className="subchapter__title subchapter__title--project">
                <h4>{subchapter.title}</h4>
              </div>
            </Link>
          ))}
        </section>
      </div>
    );
  }
}

export default OverzichtProject;
