import React, { Component } from "react";
import HeaderPage from "../../../components/HeaderPage";
import downloadShape from "../../../assets/icons/prikkel/download_prikkel_file.svg";
import linkShape from "../../../assets/icons/prikkel/download_prikkel_link.svg";
import HeaderPageMobile from "../../../components/HeaderPageMobile";

import vormingsmateriaal1 from "../../../assets/docs/prikkel/vormingsmateriaal/Presentatie duurzame ontwikkeling en educatie voor duurzame ontwikkeling.zip";
import vormingsmateriaal2 from "../../../assets/docs/prikkel/vormingsmateriaal/beginsituatieanalyse van Educatie voor Duurzame Ontwikkeling.zip";
import vormingsmateriaal3 from "../../../assets/docs/prikkel/vormingsmateriaal/Verbinding met educaties.pdf";
import vormingsmateriaal5 from "../../../assets/docs/prikkel/vormingsmateriaal/EDO carrousel.zip";
import vormingsmateriaal6 from "../../../assets/docs/prikkel/vormingsmateriaal/Herken de EDO principes.docx";
import vormingsmateriaal7 from "../../../assets/docs/prikkel/vormingsmateriaal/Ordeningskader ZILL met vindplaatsen EDO principes.zip";

class VormingsmateriaalPrikkel extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = [
      {
        number: 1,
        chapterName: "Een introductie",
        chapterUrl: "/prikkel/introductie",
      },
      {
        number: 2,
        chapterName: "Prikkels in de praktijk",
        chapterUrl: "/prikkel/praktijk",
      },
      {
        number: 3,
        chapterName: "Vormingsmateriaal",
        chapterUrl: "/prikkel/vormingsmateriaal",
      },
    ];
    const assets = [
      {
        title: "Presentatie duurzame ontwikkeling en educatie voor duurzame ontwikkeling",
        type: ".zip",
        description:
          "Interactieve presentatie voor teams die willen kennismaken met de concepten duurzame ontwikkeling en educatie voor duurzame ontwikkeling.",
        shape: downloadShape,
        url: vormingsmateriaal1,
      },
      {
        title: "Beginsituatieanalyse van Educatie voor Duurzame Ontwikkeling",
        type: ".zip",
        description:
          "Beginsituatieanalyse rond educatie voor duurzame ontwikkeling voor teams die zich willen toeleggen op een rijk EDO-aanbod op school.",
        shape: linkShape,
        url: vormingsmateriaal2,
      },
      {
        title: "Verbinding met educaties",
        type: ".pdf",
        description:
          "Verken hoe je binnen EDO kansen creëert om dwarsverbanden tussen verschillende  educaties te leggen.",
        shape: downloadShape,
        url: vormingsmateriaal3,
      },
      {
        title: "Inspiratiegids duurzame ontwikkelingsdoelen voor scholen",
        type: ".url",
        description:
          "Inspiratiegids van 11.11.11 om op school aan de slag te gaan rond de duurzame ontwikkelingsdoelen.",
        shape: downloadShape,
        url: "https://11.be/teachforsdgs",
      },
      {
        title: "EDO carrousel",
        type: ".zip",
        description:
          "Maak kennis met de EDO principes a.d.h.v. een actieve workshop.",
        shape: downloadShape,
        url: vormingsmateriaal5,
      },
      {
        title: "Herken de EDO principes",
        type: ".docx",
        description:
          "Leer de EDO-principes en het concept actiecompetentie kennen door de analyse van enkele praktijkvoorbeelden.",
        shape: downloadShape,
        url: vormingsmateriaal6,
      },
      {
        title: "Ordeningskader ZILL met vindplaatsen EDO principes",
        type: ".zip",
        description:
          "Ga op zoek naar de voornaamste vindplaatsen van ZILL leerplandoelen die betrekking hebben op de EDO-principes.",
        shape: downloadShape,
        url: vormingsmateriaal7,
      },
    ];
    return (
      <div>
        <HeaderPage
          type={"prikkel"}
          chapters={chapters}
          activeChapter={3}
        ></HeaderPage>
        <HeaderPageMobile
          type={"prikkel"}
          chapters={chapters}
          activeChapter={2}
        ></HeaderPageMobile>
        <div className="theoryPage">
          <main>
            <section>
              <h1 className="theoryPage__title">
                Ga zelf aan de slag met het vormingsmateriaal
              </h1>
            </section>
            <div className="materiaal__wrapper">
              {assets.map((asset, i) => (
                <a key={i} className="materiaal__asset" href={asset.url} target="_blank" download>
                  <img src={asset.shape} alt={`${asset.shape}`}></img>
                  <div className="materiaal__info">
                    <h3 className="theoryPage__sub-subtitle">{asset.title}</h3>
                    <p className="small">{asset.description}</p>
                  </div>
                  <small className="prikkel-color">{asset.type}</small>
                </a>
              ))}
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default VormingsmateriaalPrikkel;
