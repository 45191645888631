import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import logoArtevelde from "../assets/icons/algemeen/logo_AHS.svg";
import logoUAntwerpen from "../assets/icons/algemeen/logo_UA.svg";
import logoKULeuven from "../assets/icons/algemeen/logo_KUL.svg";
import logoKOV from "../assets/icons/algemeen/logo_KOV.svg";
import logoPOV from "../assets/icons/algemeen/logo_POV.svg";
import logoFWO from "../assets/icons/algemeen/logo_FWO.svg";

class NavigationBottom extends Component {
  render() {
    return (
      <div id="navigationBottom">
        <section className="menus">
          <ul className="menus__menu--prikkel">
            <li>
              <Link
                to="/prikkel"
                className={`navigationBottom__navLink${
                  this.props.type === "prikkel" ? "--active" : ""
                } prikkel-arrow`}
              >
                EDO <span className="prikkel-color">Prikkel</span>{" "}
              </Link>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/prikkel/introductie">
                Een introductie
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/prikkel/praktijk">
                Prikkels in de praktijk
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="is-active"
                to="/prikkel/vormingsmateriaal"
              >
                Vormingsmateriaal
              </NavLink>
            </li>
          </ul>
          <ul className="menus__menu--project">
            <li>
              <Link
                to="/project"
                className={`navigationBottom__navLink${
                  this.props.type === "project" ? "--active" : ""
                } project-arrow`}
              >
                EDO <span className="project-color">Project</span>
              </Link>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/project/introductie">
                Didactisch ontwerp
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/project/praktijk">
                Projecten in de praktijk
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="is-active"
                to="/project/vormingsmateriaal"
              >
                Vormingsmateriaal
              </NavLink>
            </li>
          </ul>
          <ul className="menus__menu--school">
            <li>
              <Link
                to="/school"
                className={`navigationBottom__navLink${
                  this.props.type === "school" ? "--active" : ""
                } school-arrow`}
              >
                EDO <span className="school-color">School</span>{" "}
              </Link>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/school/introductie">
                Effectief worden
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/school/praktijk">
                Schoolbreed in de praktijk
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="is-active"
                to="/school/vormingsmateriaal"
              >
                Vormingsmateriaal
              </NavLink>
            </li>
          </ul>
          <ul className="menus__menu--main">
            <li>
              <NavLink activeClassName="is-active" to="/" exact>
                Portaal
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/over-valies">
                Over VALIES
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/contact">
                Contact
              </NavLink>
            </li>
          </ul>
        </section>
        <section className="sponsors">
          <a
            href="https://www.uantwerpen.be/nl/"
            alt="Website van de Universiteit Antwerpen."
          >
            <img
              src={logoUAntwerpen}
              alt="Logo van de Universiteit Antwerpen"
              className="sponsor"
            ></img>
          </a>
          <a
            href="https://pov.be/"
            alt="Website van het Provinciaal Onderwijs Vlaanderen."
          >
            <img
              src={logoPOV}
              alt="Logo van het Provinciaal Onderwijs Vlaanderen"
              className="sponsor"
            ></img>
          </a>{" "}
          <a
            href="https://www.arteveldehogeschool.be/"
            alt="Website van de Artevelde Hogeschool."
          >
            <img
              alt="Logo van de Artevelde Hogeschool"
              src={logoArtevelde}
              className="sponsor"
            ></img>
          </a>{" "}
          <a
            href="https://www.kuleuven.be/kuleuven/"
            alt="Website van de KULeuven."
          >
            <img
              alt="Logo van de KULeuven"
              src={logoKULeuven}
              className="sponsor"
            ></img>
          </a>{" "}
          <a
            href="https://www.katholiekonderwijs.vlaanderen/"
            alt="Website van het Katholiek Onderwijs Vlaanderen."
          >
            <img
              alt="Logo van het Katholiek Onderwijs Vlaanderen"
              src={logoKOV}
              className="sponsor"
            ></img>
          </a>{" "}
          <a
            href="https://www.fwo.be/"
            alt="Website van het Fonds voor Wetenschappelijk Onderzoek (FWO)."
          >
            <img
              alt="Logo van het Fonds voor Wetenschappelijk Onderzoek (FWO)"
              src={logoFWO}
              className="sponsor"
            ></img>
          </a>{" "}
        </section>
      </div>
    );
  }
}

export default NavigationBottom;
