import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import iconOnderzoekend from "../../../assets/icons/algemeen/icon_onderzoekend.svg";
import iconSamen from "../../../assets/icons/algemeen/icon_samen.svg";
import iconActiegericht from "../../../assets/icons/algemeen/icon_actiegericht.svg";
import { Waypoint } from "react-waypoint";
import AlleFiches from "../../../assets/docs/prikkel/download_alle fiches.pdf";
import InterviewFiche from "../../../assets/docs/prikkel/download_fiche_interview.pdf";
import PersonaFiche from "../../../assets/docs/prikkel/download_fiche_persona.pdf";
import BackcastingFiche from "../../../assets/docs/prikkel/download_fiche_backcasting.pdf";

import grid from "../../../assets/icons/algemeen/grid.png";

class EDOStarters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubtitle: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const chapters = this.props.chapters;
    const subchapters = [
      {
        title: "Onderzoekend",
        url: "onderzoekend",
        alt: "",
      },
      {
        title: "Samen",
        url: "samen",
        alt: "",
      },
      {
        title: "Actiegericht",
        url: "actiegericht",
        alt: "Overzicht van de edo-principes",
      },
      {
        title:
          "Hoe gaan we hiermee aan de slag in de klas? Een inspirerend Canadees verhaal...",
        url: "canadees-verhaal",
        alt: "Overzicht van de edo-principes",
      },
    ];

    return (
      <div id="edo-starters" className="theoryPage">
        <nav className="navbar navbar-left">
          <a
            className="navbar-toggler navbar-toggler--prikkel collapsed"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Inhoudstafel <i class="icon-chevron-down"></i>
          </a>
          <div className="collapse navbar-collapse" id="navbarNav">
            <Link
              to={chapters[0].url}
              className="navbar-left__item navbar-left__item--prikkel"
            >
              {chapters[0].title}
            </Link>
            <Link
              to={chapters[1].url}
              className="navbar-left__item navbar-left__item--prikkel"
            >
              {chapters[1].title}
            </Link>

            <Link
              to={chapters[2].url}
              className="navbar-left__item navbar-left__item--prikkel "
            >
              {chapters[2].title}
            </Link>

            <Link
              to={chapters[3].url}
              className="navbar-left__item navbar-left__item--prikkel "
            >
              {chapters[3].title}
            </Link>

            <Link
              to={chapters[4].url}
              className="navbar-left__item navbar-left__item--prikkel navbar-left__item--active"
            >
              {chapters[4].title}
            </Link>
            <HashLink
              smooth
              to={`#${subchapters[0].url}`}
              className={`navbar-left__subitem navbar-left__subitem--prikkel ${
                this.state.activeSubtitle === subchapters[0].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[0].title}
            </HashLink>
            <HashLink
              smooth
              to={`#${subchapters[1].url}`}
              className={`navbar-left__subitem navbar-left__subitem--prikkel ${
                this.state.activeSubtitle === subchapters[1].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[1].title}
            </HashLink>
            <HashLink
              smooth
              to={`#${subchapters[2].url}`}
              className={`navbar-left__subitem navbar-left__subitem--prikkel ${
                this.state.activeSubtitle === subchapters[2].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[2].title}
            </HashLink>
          </div>
        </nav>

        <main>
          <section>
            <h1 className="theoryPage__title">{chapters[4].title}</h1>
            <p className="theoryPage__text theoryPage__text--bold">
              Heb je weinig ervaring met educatie voor duurzame ontwikkeling
              (EDO)? Of ben je op zoek naar inspiratie? Je vindt hier concrete
              tips om in je klas kleinschalig te proeven van EDO. EDO gaat over{" "}
              <HashLink
                className="link-inline link-inline--prikkel"
                to="/prikkel/introductie/starters#samen"
              >
                samen
              </HashLink>
              ,{" "}
              <HashLink
                className="link-inline link-inline--prikkel"
                to="/prikkel/introductie/starters#onderzoekend"
              >
                onderzoekend
              </HashLink>{" "}
              en{" "}
              <HashLink
                className="link-inline link-inline--prikkel"
                to="/prikkel/introductie/starters#actiegericht"
              >
                actiegericht
              </HashLink>{" "}
              aan de slag gaan rond maatschappelijke uitdagingen waar we voor
              staan. Je kan op heel wat manieren EDO in je klas betrekken. De
              onderstaande suggesties dienen als een opstapje, een uitgebreidere
              selectie aan werkvormen vind je{" "}
              <a
                className="link-inline link-inline--prikkel"
                href={AlleFiches}
                download
              >
                hier
              </a>
              .
            </p>

            <h2 className="theoryPage__subtitle" id={subchapters[0].url}>
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[0].title,
                  });
                }}
              />
              <span className="theory__title-before theory__title-before--prikkel">
                <img src={iconOnderzoekend} alt="Icoon van onderzoekend"></img>
              </span>
              {subchapters[0].title}
            </h2>
            <p className="theoryPage__text theoryPage__text--bold">
              Met onderzoekend, bedoelen we niet alleen proefjes, maar wel
              onderzoekend en met een open, nieuwsgierige blik naar de wereld
              kijken.
            </p>
          </section>

          <aside>
            <h3 className="theory__sub-subtitle">
              Interview: Wie ben jij? Wat weet en denk je hierover?
            </h3>
            <p className="theoryPage__text">
              Je wil bv. werken rond gezonde en duurzame voeding. Leerlingen
              denken na wie hierbij zoal betrokken is. Ze stellen een lijst
              samen met interessante personen, stellen een interview op en nemen
              dit af. Ze bevragen bv. een veehouder, bio-boer, vegetariër,
              diëtist, iemand van EVA vzw, iemand van de Vlaamse
              Milieumaatschappij, een dokter, enz. Op deze manier verzamelen ze
              niet alleen verschillende meningen, maar ook inhoudelijke kennis
              rond dit thema. Dat verruimt hun blik, én daagt hen uit om deze
              meningen kritisch te onderzoeken. Door alle argumenten af te wegen
              en af te toetsen aan hun eigen waarden en normen vormen de
              leerlingen een eigen mening.{" "}
            </p>{" "}
            <div className="btn-wrapper">
              <a className="btn-dark" href={InterviewFiche} download>
                <span className="btn-text">Deze fiche</span>
                <i className="icon-arrow-down-1"></i>
              </a>
              <a className="btn-light" href={AlleFiches} download>
                <span className="btn-text">Alle fiches</span>
                <i className="icon-arrow-down-1"></i>
              </a>
            </div>
          </aside>

          <section>
            <h2 className="theoryPage__subtitle" id={subchapters[1].url}>
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[1].title,
                  });
                }}
              />
              <span className="theory__title-before theory__title-before--prikkel">
                <img src={iconSamen} alt="icoon van Samen"></img>
              </span>
              {subchapters[1].title} {" "}
            </h2>
            <p className="theoryPage__text theoryPage__text--bold">
              Leerlingen diversiteit laten ervaren, maakt dat ze meer begrip en
              inzicht in het thema krijgen. Maar hoe krijg je deze meningen in
              de klas? Hier zijn alvast enkele suggesties die je kan
              uitproberen.
            </p>
          </section>
          <aside>
            <h3 className="theory__sub-subtitle">
              Persona’s: ik leef me in in iemand anders{" "}
            </h3>
            <p className="theoryPage__text">
              In een klas kunnen de meningen soms gelijklopend zijn. Daarom is
              het soms zinvol leerlingen uit te dagen zich in te leven in iemand
              anders. De leerlingen krijgen in deze werkvorm een korte bio van
              een personage (bv. een gepensioneerde vrouw die het niet breed
              heeft, een anderstalige nieuwkomer, een CEO, enz.) De leerkracht
              leest een stelling voor en vraagt aan de leerlingen om hun
              argumenten naar voor te brengen vanuit hun ‘persona’. Wanneer de
              discussies te hoog zouden oplopen, kan een moderator aangeduid
              worden. Neem aan het einde van het stellingenspel voldoende tijd
              om te reflecteren op het gesprek: Hoe was het om je in te leven in
              andere waarden en meningen? Sta jij daar anders tegenover?
            </p>{" "}
            <div className="btn-wrapper">
              <a className="btn-dark" href={PersonaFiche} download>
                <span className="btn-text">Deze fiche</span>
                <i className="icon-arrow-down-1"></i>
              </a>
              <a className="btn-light" href={AlleFiches} download>
                <span className="btn-text">Alle fiches</span>
                <i className="icon-arrow-down-1"></i>
              </a>
            </div>
          </aside>
          <section>
            <h2 id={subchapters[2].url} className="theoryPage__subtitle">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[2].title,
                  });
                }}
              />
              <span className="theory__title-before theory__title-before--prikkel">
                <img src={iconActiegericht} alt="icoon van Actiegericht"></img>
              </span>
              {subchapters[2].title}{" "}
            </h2>
            <p className="theoryPage__text theoryPage__text--bold">
              Wanneer je een duurzaamheidskwestie hebt onderzocht, dan wil je
              uiteraard aan de slag gaan! Het bedenken van zinvolle handelingen
              is niet eenvoudig: kies je voor een persoonlijke kleine actie, of
              zet je in op een collectieve actie die breed gedagen is? En wat
              zijn haalbare ideeën die op korte termijn realiseerbaar zijn?
            </p>
          </section>
          <aside>
            <h3 className="theory__sub-subtitle">
              Backcasting: ik droom niet alleen over de toekomst, ik maak ze ook{" "}
            </h3>
            <p className="theoryPage__text">
              Hoe ziet jouw ideale wereld er in de toekomst uit? Hoe leven we?
              Hoe ziet de omgeving eruit? Hoe hebben we de duurzaamheidskwestie
              opgelost? Vanuit die gedroomde situatie kunnen we terugdenken: Wat
              is er nodig om die droom te realiseren? Wat kunnen we nu al doen
              om de wereld te veranderen in onze droomwereld? Op die manier kom
              je tot concrete ideeën om mee de wereld van morgen te
              realiseren...{" "}
            </p>{" "}
            <div className="btn-wrapper">
              <a className="btn-dark" href={BackcastingFiche} download>
                <span className="btn-text">Deze fiche</span>
                <i className="icon-arrow-down-1"></i>
              </a>
              <a className="btn-light" href={AlleFiches} download>
                <span className="btn-text">Alle fiches</span>
                <i className="icon-arrow-down-1"></i>
              </a>
            </div>
          </aside>

          <nav className="navbar-bottom">
            <Link
              className="navbar-bottom__link navbar-bottom__back btn-light"
              to={chapters[3].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <i className="icon-chevron-left"></i>
              <span className="btn-text">{chapters[3].title}</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/prikkel/introductie"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <div className="navbar-bottom__link navbar-bottom__next btn-light btn-light--hoverless"></div>
          </nav>
        </main>
      </div>
    );
  }
}

export default EDOStarters;
