import React, { Component } from "react";
import { Route, Switch } from "react-router";

import HeaderPage from "../../../components/HeaderPage";
import HeaderPageMobile from "../../../components/HeaderPageMobile";

import DaaromEDO from "./DaaromEDO";
import EDOPrincipes from "./EDOPrincipes";
import EDOStarters from "./EDOStarters";
import EDOThemas from "./EDOThemas";
import OverzichtPrikkel from "./OverzichtPrikkel";
import SpeeddateMetEDO from "./SpeeddateMetEDO";

class IntroductiePrikkel extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = [
      {
        number: 1,
        chapterName: "Een introductie",
        chapterUrl: "/prikkel/introductie",
      },
      {
        number: 2,
        chapterName: "Prikkels in de praktijk",
        chapterUrl: "/prikkel/praktijk",
      },
      {
        number: 3,
        chapterName: "Vormingsmateriaal",
        chapterUrl: "/prikkel/vormingsmateriaal",
      },
    ];
    const subchapters = [
      {
        number: "1",
        title: "Speeddate met EDO",
        url: "/prikkel/introductie/speeddate-met-edo",
        subchapters: [],
      },
      {
        number: "2",
        title: "Daarom EDO!",
        url: "/prikkel/introductie/daarom-edo",
        subchapters: [
          {
            title: "Educatie richting actiecompetentie",
            hash: "#educatie-richting-actiecompetentie",
          },
          {
            title: "Wat is Duurzame Ontwikkeling",
            hash: "#wat-is-duurzame-ontwikkeling",
          },
          {
            title: "Educatie richting actiecompetentie",
            hash: "#educatie-richting-actiecompetentie",
          },
        ],
      },
      {
        number: "3",
        title: "EDO-principes",
        url: "/prikkel/introductie/principes",
        subchapters: [],
      },
      {
        number: "4",
        title: "EDO-thema's",
        url: "/prikkel/introductie/themas",
        subchapters: [],
      },
      {
        number: "5",
        title: "EDO-starters",
        url: "/prikkel/introductie/starters",
        subchapters: [],
      },
    ];
    return (
      <div>
        <HeaderPage
          type={"prikkel"}
          chapters={chapters}
          activeChapter={1}
        ></HeaderPage>
        <HeaderPageMobile
          type={"prikkel"}
          chapters={chapters}
          activeChapter={0}
        ></HeaderPageMobile>
        <Switch>
          <Route path={["/prikkel/introductie/speeddate-met-edo"]} exact>
            <SpeeddateMetEDO chapters={subchapters} />
          </Route>
          <Route path={["/prikkel/introductie/daarom-edo"]} exact>
            <DaaromEDO chapters={subchapters} />
          </Route>
          <Route path={["/prikkel/introductie/principes"]} exact>
            <EDOPrincipes chapters={subchapters} />
          </Route>
          <Route path={["/prikkel/introductie/themas"]} exact>
            <EDOThemas chapters={subchapters} />
          </Route>
          <Route path={["/prikkel/introductie/starters"]} exact>
            <EDOStarters chapters={subchapters} />
          </Route>
          <Route
            path={["/prikkel/introductie/overzicht", "/prikkel/introductie"]}
          >
            <OverzichtPrikkel subchapters={subchapters} />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default IntroductiePrikkel;
