import React, { Component } from "react";
import { Link } from "react-router-dom";

import Footer from "../components/Footer";
import HeaderHome from "../components/HeaderHome";
import HeaderPageMobile from "../components/HeaderPageMobile";
import NavigationBottom from "../components/NavigationBottom";

import image2 from "../assets/imgs/home/foto33_S_0417.png";
import image1 from "../assets/imgs/home/foto33_S_0440.png";

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id="home" className="page">
        <HeaderHome></HeaderHome>
        <HeaderPageMobile type={"portaal"}></HeaderPageMobile>
        <h1>
          <span>Educatie</span> voor{" "}
          <span>
            Duurzame <br />
            Ontwikkeling
          </span>{" "}
          op School
        </h1>
        <section className="overview">
          <div className="overview--prikkel">
            <h3>
              <span>EDO</span>
              <br />
              Prikkel
            </h3>
            <p overview__>
              Maak kennis met de <br />
              kracht van EDO.
            </p>
            <Link to="/prikkel" className="btn-dark">
              <span className="btn-text">Ik maak kennis</span>
              <i className="icon-chevron-right"></i>
            </Link>
          </div>
          <div className="overview--project">
            {" "}
            <h3>
              <span>EDO</span>
              <br /> Project
            </h3>
            <p overview__>
              Ontwerp EDO-projecten <br />
              voor en met je leerlingen
            </p>
            <Link to="/project" className="btn-dark">
              <span className="btn-text">Ik maak een project</span>
              <i className="icon-chevron-right"></i>
            </Link>
          </div>
          <div className="overview--school">
            <h3>
              <span>EDO</span>
              <br /> School
            </h3>
            <p overview__>
              Bouw samen en <br />
              schoolbreed aan EDO.
            </p>
            <Link to="/school" className="btn-dark">
              <span className="btn-text">Ik maak beleid</span>
              <i className="icon-chevron-right"></i>
            </Link>
          </div>
        </section>
        <section className="information-wrapper">
          <h2 className="information__title">
            Educatie voor Duurzame Ontwikkeling op School, <br></br>wat is dat
            juist?
          </h2>
          <div className="information information--first">
            <p className="information__text">
              Vandaag worden we wereldwijd geconfronteerd met de grenzen van
              onze planeet. De manier waarop we leven heeft een ongeziene impact
              op klimaat, biodiversiteit en ecosystemen, armoede en
              ongelijkheid. Educatie voor Duurzame Ontwikkeling (EDO) wil
              leerlingen voorbereiden op hun rol als actieve burgers die kunnen
              en willen bijdragen aan een betere toekomst.
            </p>

            <img
              src={image1}
              alt="Projectgroep aan het werk"
              className="home-image--base"
            />
          </div>
          <div className="information information--second">
            <p className="information__text">
              Dit praktijkplatform is het resultaat van het SBO-project VALIES,
              we bieden ondersteuning op 3 niveaus:
              <ul>
                <li>
                  Wil je kennis maken met EDO en de onderliggende principes?
                  Start met <Link to="/prikkel">EDO prikkel</Link>.
                </li>
                <li>
                  Heb je al ervaring en wil je ondersteuning bij het vormgeven
                  en begeleiden van EDO-projecten? Start met{" "}
                  <Link to="/project">EDO project</Link>.{" "}
                </li>
                <li>
                  Wil je samen met je schoolteam bouwen aan een implementatie
                  van EDO op school? Start met{" "}
                  <Link to="/school">EDO school</Link>.
                </li>
              </ul>
            </p>
            <img
              src={image1}
              alt="Projectgroep aan het werk"
              className="home-image--extra"
            />
            <img src={image2} alt="Jongeren" className="home-image--base" />
          </div>
          <Link to="/over-valies" className="btn-light information__btn">
            Lees meer over VALIES
          </Link>
        </section>
        <NavigationBottom type={"home"}></NavigationBottom>
        <Footer type={"home"}></Footer>
      </div>
    );
  }
}

export default Home;
