import React, { Component } from "react";
import { Link } from "react-router-dom";
import iconEdoBasis from "../assets/icons/algemeen/logo_EDO.svg";

class Header extends Component {
  render() {
    return (
      <header className="headerHome">
        <Link to="/">
          <img src={iconEdoBasis} alt="Icon van de EDO-site"></img>
        </Link>
        <div className="homemenu">
          <Link className="homemenu__navLink" to="/over-valies">
            Over VALIES
          </Link>
          <Link className="homemenu__navLink btn-light" to="/contact">
            Contact
          </Link>
        </div>
      </header>
    );
  }
}

export default Header;
