import React, { Component } from "react";
import { Link } from "react-router-dom";
import grid from "../../../assets/icons/algemeen/grid.png";

class SpeeddateMetEDO extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = this.props.chapters;
    return (
      <div id="speeddate-met-edo" className="theoryPage">
        <nav className="navbar navbar-left">
          <a
            className="navbar-toggler navbar-toggler--prikkel collapsed"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Inhoudstafel <i class="icon-chevron-down"></i>
          </a>
          <div className="collapse navbar-collapse" id="navbarNav">
            {" "}
            <Link
              to={chapters[0].url}
              className="navbar-left__item navbar-left__item--prikkel  navbar-left__item--active"
            >
              {chapters[0].title}
            </Link>
            <Link
              to={chapters[1].url}
              className="navbar-left__item navbar-left__item--prikkel "
            >
              {chapters[1].title}
            </Link>
            <Link
              to={chapters[2].url}
              className="navbar-left__item navbar-left__item--prikkel "
            >
              {chapters[2].title}
            </Link>
            <Link
              to={chapters[3].url}
              className="navbar-left__item navbar-left__item--prikkel "
            >
              {chapters[3].title}
            </Link>
            <Link
              to={chapters[4].url}
              className="navbar-left__item navbar-left__item--prikkel "
            >
              {chapters[4].title}
            </Link>
          </div>
        </nav>
        <main>
          <section>
            <h1 className="theoryPage__title">Speeddate met EDO</h1>
            <p className="theoryPage__text theoryPage__text--bold">
              Maak kennis met Educatie voor Duurzame Ontwikkeling en de 3
              EDO-principes via onderstaand filmpje.
            </p>
          </section>
          <div className="theory__video-asset">
            <div>
              <iframe
                src="https://player.vimeo.com/video/703015160?h=2f118e14ba"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
          <section></section>
          <nav className="navbar-bottom">
            <div className="navbar-bottom__link navbar-bottom__back btn-light btn-light--hoverless"></div>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/prikkel/introductie"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__next btn-light"
              to={chapters[1].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-text btn-text--nav">
                {chapters[1].title}
              </span>
              <i className="icon-chevron-right icon-chevron-right--nav"></i>
            </Link>
          </nav>
        </main>
      </div>
    );
  }
}

export default SpeeddateMetEDO;
