import React, { Component } from "react";
import { Link } from "react-router-dom";
import iconCollectief from "../../../assets/imgs/school/collectieve-effectiviteit.png";
import iconRelaties from "../../../assets/imgs/school/ondersteunende-relaties.png";
import grid from "../../../assets/icons/algemeen/grid.png";

class PraktijkVoorbeeld1 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = this.props.chapters;
    return (
      <div id="praktijk-prikkel" className="theoryPage">
        <main>
          <section>
            <h1 className="theoryPage__title">
              Basischool DE BEUK gaat aan de slag met EDO{" "}
            </h1>
            <p className="theoryPage__text theoryPage__text--bold">
              De vrije basisschool DE BEUK wil een hecht team vormen met een
              gedeelde EDO – visie. De school wil begrijpen wat EDO is, hoe je
              dit kan toepassen in de praktijk en hoe je EDO kan verbinden met
              de werking op school.
            </p>
          </section>

          <aside>
            <p className="theoryPage__text">
              <b>Hoe werkt de vrije basisschool De Beuk aan:</b>
            </p>

            <h2 className="theoryPage__subtitle">
              <span className="theory__title-before theory__title-before--school">
                <img src={iconCollectief} alt=""></img>
              </span>

              <span
                className="theory__title-emphasis theory__title-emphasis--school"
                style={{ backgroundColor: "#FEEAA5" }}
              >
                Collectieve effectiviteit
              </span>
            </h2>

            <p className="theoryPage__text">
              De volledige school gaat aan de slag met EDO. Samen bereiden de
              leerkrachten een project voor en geven dit ook. Door het project
              voor te bereiden, te geven en hierover te reflecteren
              professionaliseert het team zich. Samen oriënteert het team zich
              en worden er beslissingen genomen om de volgende jaren rond te
              werken.
            </p>

            <div className="theoryPage__school theoryPage__school--middelen">
              <span className="theoryPage_list-title">Vanuit middelen</span>
              <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
                <li>
                  Er wordt ruimte en tijd voorzien om als team leeractiviteiten
                  voor te bereiden en na te denken wat dit voor de school kan
                  betekenen. Succeservaringen worden gedeeld: hoe ben jij
                  hiermee omgegaan? Hoe heb je dat dan begeleid?.
                </li>
                <li>
                  De ‘lessons learned’ worden vastgezet door het kernteam om zo
                  in toekomstige leeractiviteiten mee te nemen.
                </li>
                <li>
                  Er is een online map voorzien waar ‘goede voorbeelden’ met het
                  team worden verzameld. Op termijn wil het team zo een leerlijn
                  ontwikkelen en bewaken.
                </li>
              </ul>
            </div>

            <div className="theoryPage__school theoryPage__school--leiderschap">
              <span className="theoryPage_list-title">
                Vanuit duurzaam leiderschap
              </span>
              <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
                <li>
                  De directie geeft mandaten aan een kernteam waar naast
                  leerkrachten uit de verschillende graden ook een
                  beleidsondersteuner is betrokken.
                </li>
                <li>
                  Door samen te werken in team worden de leerkrachten
                  gemotiveerd. Je merkt dat de motivatie en het zelfvertrouwen
                  om met EDO aan de slag te gaan stap voor stap toeneemt.
                </li>
              </ul>
            </div>

            <h2 className="theoryPage__subtitle">
              <span className="theory__title-before theory__title-before--school">
                <img src={iconRelaties} alt=""></img>
              </span>

              <span
                className="theory__title-emphasis theory__title-emphasis--school"
                style={{ backgroundColor: "#FFB9E1" }}
              >
                Ondersteunende relaties
              </span>
            </h2>

            <p className="theoryPage__text">
              Leerkrachten professionaliseren zich tijdens pedagogische
              studiedagen door samen leeractiviteiten voor te bereiden. Door
              samen te experimenteren ontstaan en heel wat samenwerking (co-
              teaching, graadoverstijgende projecten, informatie doorgeven)
              waardoor leerkrachten elkaar ondersteunen.
            </p>

            <div className="theoryPage__school theoryPage__school--middelen">
              <span className="theoryPage_list-title">Vanuit middelen</span>
              <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
                <li>tijd en ruimte om te experimenteren</li>
              </ul>
            </div>

            <div className="theoryPage__school theoryPage__school--leiderschap">
              <span className="theoryPage_list-title">
                Vanuit duurzaam leiderschap
              </span>
              <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
                <li>
                  leerkrachten nemen intiatief om samenwerkingen uit te werken.
                  Het team stelt zich flexibel op en zoekt naar verbindingen.
                </li>
              </ul>
            </div>
          </aside>

          <section>
            <p className="theoryPage__text">
              De vrije basisschool De Beuk werkt reeds 2 jaar aan het
              implementeren van EDO op school. Een kernteam ontwikkelt
              activiteiten om het volledige team te betrekken. Tijdens
              pedagogische studiedagen wordt het team geprofessionaliseerd,
              bijvoorbeeld door samen het project ‘waar word je warm van op
              school’ voor te bereiden. Dit maakt de theorie van EDO concreet en
              stimuleert samenwerkingen. Samen ontwerpen de leerlingen
              oplossingen om van de school een warmere en duurzamere school te
              maken. Dit implementatietraject zorgt ervoor dat de school:
            </p>

            <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
              <li>de visie van de school herschrijft</li>
              <li>
                op zoek gaat naar een methode voor wereldoriëntatie en deze meer
                te koppelen aan de EDO-principes
              </li>
              <li>
                de speelplaats participatief hervormt tot een krachtige speel-
                en leeromgeving
              </li>
              <li>
                nadenkt over een leerlijn en jaarlijkse projectwerking rond EDO
                op school
              </li>
            </ul>

            <h2 className="theoryPage__subtitle">Speelplaats op stelten</h2>

            <p className="theoryPage__blockquote">
              “Het project vertrekt vanuit de noden aanwezig op school. Zo komt
              het er niet bovenop maar versterkt het project de bestaande
              werking. Tijdens het samenwerken kregen we het gevoel te groeien
              als school. EDO en werken met de EDO-principes bieden een houvast
              om te verbinden en te verdiepen op school.”
            </p>

            <p className="theoryPage__text">
              De projectwerking vertrekt vanuit de beleving van de jongeren en
              wil bijdragen tot het inrichten van de speelplaats als een
              krachtige en duurzame leer- en speelomgeving. Dit is een concreet
              project waar leerlingen en leerkrachten participatief nadenken
              over hoe ze een leeromgeving kunnen vormgeven. Door in te zetten
              op democratische besluitvorming, is het project breed gedragen en
              ontstaan er heel wat nieuwe samenwerkingsmogelijkheden.
            </p>

            <p className="theoryPage__blockquote">
              “Alle leerlingen én leerkrachten trokken foto’s van zaken waar ze
              warm of koud van worden op school of in de buurt. Leerlingen en
              leerkrachten ontwerpen oplossingen die dan ook effectief
              meegenomen worden in de schoolwerking. Zowel leerlingen, als
              leerkrachten en onderhoudspersoneel geven mee vorm aan de
              invulling van de speelplaats en de school.”
            </p>

            <h2 className="theoryPage__subtitle">
              Al doende professionaliseren
            </h2>

            <p className="theoryPage__text">
              Een Kernteam vertrekt de denkoefening over EDO op school vanuit
              een bestaande werkgroep ‘GROEN’. Al gauw legt het kernteam linken
              met andere werkgroepen, zoals zorg- en pestbeleid op school. Het
              ene leidt tot het andere. Door het leggen van verbanden voelt het
              kernteam de nood om de visie en de missie van de school aan te
              passen en meer ‘EDO–proof ‘te maken. Ondertussen gaan enkele
              leerkrachten aan de slag in de eigen lessen en loopt er een graad
              overstijgend project op school (de leerlingen van de derde graad
              bereiden een activiteit voor de leerlingen van het eerste
              leerjaar).
            </p>

            <p className="theoryPage__blockquote">
              “De samenwerking geeft energie en stimuleert reflectie over
              onderwijs + versterkt het eigenaarschap en samenwerkingen.  Het
              leerkrachtenteam wordt geïnspireerd door de participatieve
              projectaanpak en wil verder aan de slag met graad –en domein
              overstijgende projectwerking.”
            </p>

            <p className="theoryPage__text">
              De pedagogische studiedagen worden door het kernteam en de
              pedagogische begeleider voorbereid en gegeven. Tijdens deze
              studiedagen worden er heel wat inzichten en methodieken
              uitgewisseld en ontstaan er nieuwe samenwerkingen. Zo worden
              theoretische concepten reeds ingeoefend en vertaald naar de
              praktijk. Dit zorgt voor heel wat ondersteunende relaties: zowel
              door samenwerkingen met externen en elkaar, als door het beleid op
              school te betrekken bij de ontwikkeling van de leeractiviteiten.
              Samen voorbereiden, uitproberen en reflecteren helpt elkaar te
              inspireren en te ondersteunen.
            </p>

            <p className="theoryPage__blockquote">
              “Samen ontwikkelen van een methode voor wereldoriëntatie zorgt
              ervoor dat we meer EDO gericht kunnen werken en elkaar kunnen
              aanvullen. We leren elkaars expertise en talenten veel beter
              kennen.”
            </p>

            <h2 className="theoryPage__subtitle">
              Besluit
            </h2>

            <p className="theoryPage__text">
                Vaak professionaliseren leerkrachten zich individueel. De moeilijkheid blijft echter de geleerde materie te vertalen naar de eigen lespraktijk én de inhouden te verbreden naar andere leerkrachten. Zeker bij EDO, welke zich niet zomaar laat vertalen in de praktijk, is er nood aan oefening: experimenteren en reflecteren. Door zich als team samen te professionaliseren en dit toe te passen op een concreet project, zet je in op effectieve collectiviteit. Dit zet onder meer aan tot een gedragen EDO – visie, professionalisering en meer ondersteunende relaties op school.  
            </p>
          </section>

          <nav className="navbar-bottom">
            <div className="navbar-bottom__link navbar-bottom__back btn-light btn-light--hoverless"></div>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/school/praktijk"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__next btn-light"
              to="/school/praktijk/voorbeeld2"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-text">{chapters[1].title}</span>
              <i className="icon-chevron-right"></i>
            </Link>
          </nav>
        </main>
      </div>
    );
  }
}

export default PraktijkVoorbeeld1;
