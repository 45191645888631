import React, { Component } from "react";
import HeaderPage from "../../../components/HeaderPage";
import { Route, Switch } from "react-router";
import PraktijkVoorbeeld1 from "./PraktijkVoorbeeld1";
import PraktijkVoorbeeld2 from "./PraktijkVoorbeeld2";
import PraktijkVoorbeeld3 from "./PraktijkVoorbeeld3";
import HeaderPageMobile from "../../../components/HeaderPageMobile";
import { Link } from "react-router-dom";

class PraktijkSchool extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = this.props.chapters;

    const subchapters = [
      {
        number: 1,
        title: "Basischool De Beuk",
        url: "/school/praktijk/voorbeeld1",
        description:
          "Vrije basisschool De Beuk wil een hecht team vormen met een gedeelde EDO – visie. De school wil begrijpen wat EDO is, hoe je dit kan toepassen in de praktijk en hoe je EDO kan verbinden met de werking op school.",
      },
      {
        number: 2,
        title: "VBS Sint Juliaan de Vlindertuin",
        url: "/school/praktijk/voorbeeld2",
        description:
          "Vrije Basisschool Sint Juliaan de Vlindertuin wil een antwoord vinden op de volgende vraag: Hoe kunnen we onze leeractiviteiten en acties rond EDO structureler inbedden in onze werking?",
      }, 
      {
        number: 3,
        title: "Kindsheid Jesu",
        url: "/school/praktijk/voorbeeld3",
        description:
          "Middenschool Kindsheid Jesu in Hasselt heeft al een ruime weg afgelegd in EDO. Jaarlijks worden heel wat activiteiten uitgewerkt. Met het oog op het inbedden van EDO in de schoolwerking, is de nood aan een bredere gedragenheid groot.",
      },
    ];
    return (
      <div>
    
        <HeaderPage
          type={"school"}
          chapters={chapters}
          activeChapter={2}
        ></HeaderPage>
        <HeaderPageMobile
          type={"school"}
          chapters={chapters}
          activeChapter={2}
        ></HeaderPageMobile>
        <Switch>
          
          <Route path={["/school/praktijk/voorbeeld1"]}>
            <PraktijkVoorbeeld1 chapters={subchapters} />
          </Route>
          <Route path={["/school/praktijk/voorbeeld2"]}>
            <PraktijkVoorbeeld2 chapters={subchapters} />
          </Route>
          <Route path={["/school/praktijk/voorbeeld3"]}>
            <PraktijkVoorbeeld3 chapters={subchapters} />
          </Route>
          <Route
            path={["/school/praktijk", "/school/praktijk/overzicht"]}
            exact
            render={() => (
              <div id="praktijk-school" className="theoryPage">
                <main>
                  <section>
                    <h1 className="theoryPage__title">
                      Laat je inspireren door schoolbrede voorbeelden uit de
                      praktijk
                    </h1>
                  </section>
                  <div className="praktijkvoorbeelden__wrapper praktijkvoorbeelden__wrapper--school">
                    {subchapters.map((subchapter, i) => (
                      <div key={i} className="praktijkvoorbeeld__asset">
                        <div
                          className={`praktijkvoorbeeld__img--${subchapter.number}`}
                        ></div>
                        <div>
                          <h3 className="theoryPage__sub-subtitle">
                            {subchapter.title}
                          </h3>
                          <p className="theoryPage__text">
                            {subchapter.description}
                          </p>
                          <Link className="btn-light" to={subchapter.url}>
                            <span className="btn-text">Lees meer</span>
                            <i className="icon-chevron-right"></i>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </main>
              </div>
            )}
          ></Route>
        </Switch>
      </div>
    );
  }
}

export default PraktijkSchool;
