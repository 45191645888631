import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import asset1 from "../../../assets/icons/prikkel/edo-principes.png";
import iconOnderzoekend from "../../../assets/icons/algemeen/icon_onderzoekend.svg";
import iconSamen from "../../../assets/icons/algemeen/icon_samen.svg";
import iconActiegericht from "../../../assets/icons/algemeen/icon_actiegericht.svg";
import { Waypoint } from "react-waypoint";
import grid from "../../../assets/icons/algemeen/grid.png";

class EDOPrincipes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubtitle: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const chapters = this.props.chapters;
    const subchapters = [
      {
        title: "Onderzoekend",
        url: "onderzoekend",
        alt: "",
      },
      {
        title: "Samen",
        url: "samen",
        alt: "",
      },
      {
        title: "Actiegericht",
        url: "actiegericht",
        alt: "Overzicht van de edo-principes",
      },
      {
        title:
          "Hoe gaan we hiermee aan de slag in de klas? Een inspirerend Canadees verhaal...",
        url: "canadees-verhaal",
        alt: "Overzicht van de edo-principes",
      },
    ];

    return (
      <div id="edo-principes" className="theoryPage">
        <nav className="navbar navbar-left">
          <a
            className="navbar-toggler navbar-toggler--prikkel collapsed"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Inhoudstafel <i class="icon-chevron-down"></i>
          </a>
          <div className="collapse navbar-collapse" id="navbarNav">
            <Link
              to={chapters[0].url}
              className="navbar-left__item navbar-left__item--prikkel"
            >
              {chapters[0].title}
            </Link>
            <Link
              to={chapters[1].url}
              className="navbar-left__item navbar-left__item--prikkel"
            >
              {chapters[1].title}
            </Link>

            <Link
              to={chapters[2].url}
              className="navbar-left__item navbar-left__item--prikkel navbar-left__item--active"
            >
              {chapters[2].title}
            </Link>
            <HashLink
              smooth
              to={`#${subchapters[0].url}`}
              className={`navbar-left__subitem navbar-left__subitem--prikkel ${
                this.state.activeSubtitle === subchapters[0].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[0].title}
            </HashLink>
            <HashLink
              smooth
              to={`#${subchapters[1].url}`}
              className={`navbar-left__subitem navbar-left__subitem--prikkel ${
                this.state.activeSubtitle === subchapters[1].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[1].title}
            </HashLink>
            <HashLink
              smooth
              to={`#${subchapters[2].url}`}
              className={`navbar-left__subitem navbar-left__subitem--prikkel ${
                this.state.activeSubtitle === subchapters[2].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[2].title}
            </HashLink>
            <HashLink
              smooth
              to={`#${subchapters[3].url}`}
              className={`navbar-left__subitem navbar-left__subitem--prikkel ${
                this.state.activeSubtitle === subchapters[3].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              Hoe aan de slag in de klas?
            </HashLink>
            <Link
              to={chapters[3].url}
              className="navbar-left__item navbar-left__item--prikkel"
            >
              {chapters[3].title}
            </Link>
            <Link
              to={chapters[4].url}
              className="navbar-left__item navbar-left__item--prikkel"
            >
              {chapters[4].title}
            </Link>
          </div>
        </nav>
        <main>
          <section>
            <h1 className="theoryPage__title">{chapters[2].title}</h1>
            <p className="theoryPage__text theoryPage__text--bold">
              Heb je ooit al iets willen veranderen in jouw buurt, gemeente of
              zelfs de wereld, maar wist je niet goed hoe te beginnen? De
              EDO-principes kunnen een kompas zijn voor hoe je als leerkracht,
              leerling, of als burger, actief aan de slag kan gaan.
            </p>
            <p className="theoryPage__text">
              Hoe werkt het? In het kort: Om slimme oplossingen te bedenken zijn
              <b> kennis</b> en een <b> onderzoekende houding</b> noodzakelijk.
              Je weet dat je samen meer kan bereiken dan alleen, dus breng je
              mensen <b>samen</b> die kunnen helpen. Ze kunnen veel kennis
              hebben over het thema, ze brengen nieuwe standpunten in en ze
              willen actief meewerken aan acties. Zo kan je concrete stappen
              zetten om de verandering te realiseren die je wil zien.{" "}
              <b>Samen, onderzoekend en actiegericht</b> dat is de basis van
              verandering en dus van EDO.{" "}
            </p>
            <p className="theoryPage__text">
              De drie principes zijn onlosmakelijk met elkaar verbonden: Actie
              zonder kennis en onderzoekende houding is geen EDO. Er is immers
              geen reflectie op het doel van de actie en leerlingen zijn slechts
              uitvoerders. Anderzijds, is een onderzoekende houding zonder
              actie, ook geen EDO. Leerlingen leren zo een duurzaamheidskwestie
              te verkennen en onderzoeken, maar leren niet hoe ze samen concreet
              en oplossingsgericht aan de slag kunnen gaan. Samen, en niet
              alleen, aan de slag gaan is de basis van echte verandering. Door
              rekening te houden met verschillende meningen, waarden en normen,
              kan je tot een gedragen oplossing komen en een gedragen oplossing
              heeft meer impact dan wanneer je die in je eentje uitvoert.{" "}
            </p>

            <h2 className="theoryPage__subtitle" id={subchapters[0].url}>
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[0].title,
                  });
                }}
              />
              <span className="theory__title-before theory__title-before--prikkel">
                <img src={iconOnderzoekend} alt=""></img>
              </span>
              {subchapters[0].title}
            </h2>

            <p className="theoryPage__text">
              Duurzaamheidsuitdagingen zijn niet eenvoudig op te lossen. Ze zijn
              op een bepaald moment in de tijd ontstaan en hebben gevolgen nu en
              in de toekomst op zowel mensen, planeet als de economie. Inzicht
              krijgen in die complexe samenhang is de basis om oplossingen te
              zien die kunnen werken.
            </p>

            <h2 className="theoryPage__subtitle" id={subchapters[1].url}>
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[1].title,
                  });
                }}
              />
              <span className="theory__title-before theory__title-before--prikkel">
                <img src={iconSamen} alt=""></img>
              </span>
              {subchapters[1].title} {" "}
            </h2>
            <p className="theoryPage__text">
              Samen aan de slag gaan betekent dat je meer inzicht krijgt in
              verschillende meningen en standpunten. Iedereen kijkt anders naar
              duurzaamheidsuitdagingen. Samen kan je meer dan alleen. Samen heb
              je meer kennis dan alleen. Samen zie je meer oplossingen dan
              alleen. Zo kun je tot oplossingen komen die voor een grote groep
              mensen werken.
            </p>
            <h2 id={subchapters[2].url} className="theoryPage__subtitle">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[2].title,
                  });
                }}
              />
              <span className="theory__title-before theory__title-before--prikkel">
                <img src={iconActiegericht} alt=""></img>
              </span>
              {subchapters[2].title}{" "}
            </h2>
            <p className="theoryPage__text">
              Wanneer een leerling als laatste de klas verlaat en die dooft het
              licht. Is dit dan actie? Of eerder aangeleerd gedrag? Alles hangt
              af van de intentie en de reden van dit gedrag. Wanneer een
              leerling betrokken en vanuit een reëel probleem een bewuste
              handeling bedenkt en stelt, alleen of met anderen, dan is die
              leerling actiegericht.
            </p>
          </section>
          <img
            className="theory__asset"
            src={asset1}
            alt={subchapters[2].alt}
          ></img>

          <section>
            <h2 className="theoryPage__subtitle" id={subchapters[3].url}>
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[3].title,
                  });
                }}
              />
              {subchapters[3].title}
            </h2>
          </section>

          <section>
            <p className="theoryPage__text">
              Meester Dominique wil doorheen het schooljaar een andere aanpak
              uitproberen in zijn klas. Hij wil weten wat bij hen leeft. Waar
              zitten ze mee? Waar willen ze verandering zien? De meningen van de
              leerlingen lopen uiteen: sommige leerlingen willen werken rond het
              afval in de bossen, anderen zien het vandalisme in de buurt als
              het belangrijkste probleem.
            </p>
            <p className="theoryPage__text">
              Nadat alle argumenten uitgewisseld zijn, zorgt Dominique ervoor
              dat de leerlingen tot een democratisch besluit komen. Ze kiezen
              voor vandalisme in de buurt. Eerst willen ze goed zicht krijgen op
              het probleem: Waar doet het zich voor? Hoe staan de andere
              bewoners van de gemeente tegenover vandalisme? Waarom is er
              vandalisme en welke gevolgen heeft dit? Wat is de economische
              kost? Ze interviewen hiervoor buurtbewoners, de burgemeester, de
              politie en het schoolhoofd. Zo proberen ze in te schatten hoe
              groot het probleem precies is.
            </p>
            <p className="theoryPage__text">
              Tijdens de interviews krijgen ze soms tegenstrijdige informatie,
              wat hen verwart en ook boos maakt. Ze merken dat de burgemeester
              dit geen groot probleem vindt, wat hen ontmoedigt. Samen met
              Dominique slagen ze erin om elkaar weer te motiveren. Tijdens de
              interviews en klasdiscussies zoeken ze samen naar oplossingen en
              verzamelen die in een 8-puntenplan.{" "}
            </p>
            <p className="theoryPage__text">
              Tot slot, organiseren ze een meeting op het gemeentehuis waar ze
              hun oplossingen voorstellen aan de burgemeester, familie en
              betrokken bedrijven, zoals het bedrijf dat graffitimuren bouwt.
              Alle aanwezigen zijn onder de indruk van de motivatie en het
              engagement van de leerlingen. De burgemeester en verschillende
              andere aanwezigen beslissen ter plekke welke acties ze mee zullen
              ondersteunen. Een zus van een leerling getuigt dat ze haar zus nog
              nooit zo enthousiast heeft gezien voor een schoolproject. Ook
              meester Dominique is aangedaan door het engagement van niet alleen
              de kinderen, maar alle aanwezigen en getuigt over zijn sprong in
              het onbekende en de moeilijke maar waardevolle momenten tijdens
              het schooljaar.{" "}
            </p>
            <div className="theory__video-asset">
              <div>
                <iframe
                  title="Canadees voorbeeld"
                  src="https://www.nfb.ca/film/hope_builders/trailer/hope_builders_trailer/embed/player/"
                  frameborder="0"
                  allowfullscreen="true"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                ></iframe>
              </div>
            </div>{" "}
            <small className="caption caption--prikkel">
              Bron:{" "}
              <a
                target="_blank"
                rel="norefferer"
                href="http://www.nfb.ca/film/hope_builders/trailer/hope_builders_trailer/"
              >
                http://www.nfb.ca/film/hope_builders/trailer/hope_builders_trailer/
              </a>
            </small>
            <ul className="theoryPage__text theoryPage__ul--prikkel theoryPage__ul">
              <li>
                Zie jij waar meester Dominique aan de drie principes -{" "}
                <b>samen</b>,<b>onderzoekend</b> en <b>actiegericht</b> - werkt?{" "}
              </li>
              <li>
                Zie je mogelijkheden om <b>in je eigen praktijk</b> de drie
                principes te integreren?{" "}
              </li>
            </ul>
          </section>
          <nav className="navbar-bottom">
            <Link
              className="navbar-bottom__link navbar-bottom__back btn-light"
              to={chapters[1].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <i className="icon-chevron-left"></i>
              <span className="btn-text">{chapters[1].title}</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/prikkel/introductie"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__next btn-light"
              to={chapters[3].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-text">{chapters[3].title}</span>
              <i className="icon-chevron-right"></i>
            </Link>
          </nav>
        </main>
      </div>
    );
  }
}

export default EDOPrincipes;
