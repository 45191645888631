import React, { Component } from "react";
import HeaderPage from "../../../components/HeaderPage";
import downloadShape from "../../../assets/icons/project/download_project_file.svg";
import linkShape from "../../../assets/icons/project/download_project_link.svg";
import HeaderPageMobile from "../../../components/HeaderPageMobile";

import vormingsmateriaal2 from "../../../assets/docs/project/vormingsmateriaal/Brainstormen rond een thema vanuit de sdg’s.zip";
import vormingsmateriaal3 from "../../../assets/docs/project/vormingsmateriaal/EDO placemat.zip";
import vormingsmateriaal4 from "../../../assets/docs/project/vormingsmateriaal/Van focus naar EDO-project.zip";
import vormingsmateriaal5 from "../../../assets/docs/project/vormingsmateriaal/Een EDO-project onder de loep.zip";

class VormingsmateriaalProject extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = this.props.chapters;

    const assets = [
      {
        title: "Aanbieders, thema’s en doelgroepen voor EDO in Vlaanderen.",
        type: ".pdf",
        description:
          "De educatieve kaart voor wereldburgers geeft een overzicht van de organisaties actief binnen het platform MOVO (Mondiale Vorming) en maakt u wegwijs in hun expertise. ",
        shape: linkShape,
        url: "https://11.be/sites/default/files/2022-05/Educatieve%20kaart%202022_DEF.pdf",
      },
      {
        title: "Brainstormen rond een thema vanuit de sdg’s",
        type: ".zip",
        description:
          "Brainstormtool om een EDO-thema vanuit verschillende invalshoeken te bekijken en te linken aan de duurzame ontwikkelingsdoelstellingen (SDG’s).",
        shape: downloadShape,
        url: vormingsmateriaal2,
      },
      {
        title: "EDO placemat",
        type: ".zip",
        description:
          "EDO-placemat die één oogopslag informatie biedt over het waartoe, het wat, het hoe en het beleid met betrekking tot EDO in de klas en op school. ",
        shape: downloadShape,
        url: vormingsmateriaal3,
      },
      {
        title: "Van focus naar EDO-project",
        type: ".zip",
        description:
          "Handvaten om vanuit een focus en het inschatten van ervaringskansen de eerste stappen te zetten in het ontwerpen van een EDO-project met het leerplan ZILL.",
        shape: downloadShape,
        url: vormingsmateriaal4,
      },
      {
        title: "Een EDO-project onder de loep",
        type: ".zip",
        description:
          "Neem een EDO-project onder de loep en maak kennis met de doelstellingen, EDO-principes en fasen uit het didactisch concept van een EDO-project.",
        shape: downloadShape,
        url: vormingsmateriaal5,
      },
    ];

    return (
      <div>
        <HeaderPage
          type={"project"}
          chapters={chapters}
          activeChapter={3}
        ></HeaderPage>
        <HeaderPageMobile
          type={"project"}
          chapters={chapters}
          activeChapter={3}
        ></HeaderPageMobile>
        <div className="theoryPage">
          <main>
            <section>
              <h1 className="theoryPage__title">
                Ga zelf aan de slag met het vormingsmateriaal
              </h1>
            </section>
            <div className="materiaal__wrapper">
              {assets.map((asset, i) => (
                <a
                  key={i}
                  className="materiaal__asset"
                  href={asset.url}
                  target="_blank"
                  download
                >
                  <img src={asset.shape} alt={`${asset.shape}`}></img>
                  <div className="materiaal__info">
                    <h3 className="theoryPage__sub-subtitle">{asset.title}</h3>
                    <p className="small">{asset.description}</p>
                  </div>
                  <small className="project-color">{asset.type}</small>
                </a>
              ))}
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default VormingsmateriaalProject;
