import React, { Component } from "react";
import { Link } from "react-router-dom";
import asset1 from "../../../assets/icons/school/edo-effectieve-school@2x.png";
import iconDemocratie from "../../../assets/imgs/school/democratische-besluitvorming.png";
import iconRelaties from "../../../assets/imgs/school/ondersteunende-relaties.png";
import grid from "../../../assets/icons/algemeen/grid.png";

class PraktijkVoorbeeld2 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = this.props.chapters;
    return (
      <div id="praktijk-prikkel" className="theoryPage">
        <main>
          <section>
            <h1 className="theoryPage__title">Kindsheid Jesu in Hasselt</h1>

            <h2 className="theoryPage__subtitle">
              Hoe kan je door los te laten veel winnen?
            </h2>

            <h3 class="theoryPage__sub-subtitle">Wie?</h3>

            <p className="theoryPage__text">
              De middenschool van Kindsheid Jesu in Hasselt biedt in de 1ste
              graad een brede algemene vorming aan met verkennende projecten die
              aansluiten bij de mogelijke studiekeuzes in het 3de jaar
              (maatschappij en welzijn, STEM, klassieke talen en cultuur, sport,
              kunst en creatie). De school heeft al een ruime weg afgelegd in
              Educatie voor Duurzame Ontwikkeling. Jaarlijks worden heel wat
              activiteiten uitgewerkt. Een kleine, enthousiaste groep van
              leraren draagt de projecten. Met het oog op het inbedden van EDO
              in de schoolwerking, is de nood aan een bredere gedragenheid
              groot.
            </p>

            <p className="theoryPage__text theoryPage__text--bold">
              Hanne en Koen, de twee trekkers van EDO op school, willen tijdens
              het EDO-traject meer en meer in handen leggen van collega-leraren,
              maar ook van leerlingen. Op die manier willen ze het leerproces
              van leraren en leerlingen voorop zetten en hen zelf initiatieven
              te laten nemen. Als trekkers denken ze na over hun rol op school.
            </p>
          </section>

          <img
            className="theory__asset"
            src={asset1}
            alt={chapters[0].alt}
          ></img>

          <aside>
            <p className="theoryPage__text">
              <b>Hoe werkt Kindsheid Jesu aan:</b>
            </p>

            <h2 className="theoryPage__subtitle">
              <span className="theory__title-before theory__title-before--school">
                <img src={iconDemocratie} alt=""></img>
              </span>

              <span
                className="theory__title-emphasis theory__title-emphasis--school"
                style={{ backgroundColor: "#FFCA89" }}
              >
                Democratische besluitvorming
              </span>
            </h2>

            <p className="theoryPage__text">
              Wat op schoolniveau wordt ondernomen op vlak van EDO wordt niet
              meer enkel door een klein kernteam beslist dat anderen louter
              informeert, maar elke leraar wordt gestimuleerd om initiatief te
              nemen. Iedereen wordt actief betrokken. Het kernteam houdt bewust
              de boot af zodat anderen ruimte voelen om iets te ondernemen.
            </p>

            <div className="theoryPage__school theoryPage__school--middelen">
              <span className="theoryPage_list-title">Vanuit middelen</span>
              <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
                <li>
                  De ervaring en expertise van het kernteam wordt ingezet. In
                  plaats van ideeën bedenken en uitwerken, nemen ze meer en meer
                  de rol op van begeleider en facilitator. De andere leraren
                  daarentegen krijgen ruimte om mee op de kar te springen en
                  iets te ondernemen.
                </li>
              </ul>
            </div>

            <div className="theoryPage__school theoryPage__school--leiderschap">
              <span className="theoryPage_list-title">
                Vanuit duurzaam leiderschap
              </span>
              <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
                <li>
                  Vanuit leiderschap: doordat het leiderschap niet uitsluitend
                  bij een kleine groep zit, is EDO een blijver in Kindsheid
                  Jesu. Het hangt niet enkel af van enkele enthousiaste leraren,
                  maar is ingebed in het hele schoolleven en bij een groot
                  aantal leraren.
                </li>
              </ul>
            </div>
          </aside>

          <section>
            <p className="theoryPage__text">
              Een school die werk wil maken van EDO, heeft nood aan trekkers.
              Enkele jaren geleden namen in Kindsheid Jesu een paar leraren het
              voortouw. Volledig overtuigd van de nood aan meer bewustwording
              rond milieuthema’s organiseerden ze tal van initiatieven: van een
              ‘Green team’ bestaande uit leerlingen en leraren die wekelijks
              samenkomen, tot een dikketruiendag en milieuweek. Er werd
              geüpcycled en gewandeld voor goede doelen. Samenwerkingen werden
              opgezet met mooimakers, MOS en vele anderen.
            </p>

            <p className="theoryPage__text">
              Tijdens het VALIES-traject stelde het enthousiaste team een aantal
              zaken vast:
            </p>

            <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
              <li>De kijk op EDO beperkt zich tot het thema ‘afval’.</li>
              <li>
                Een kleine groep van enthousiaste leraren trekt alle
                activiteiten.
              </li>
              <li>
                Deze kleine groep informeert leerlingen en leraren voortdurend.
                Ze zijn alomtegenwoordig en vragen iedereen op school om
                engagement.
              </li>
              <li>
                Leerlingen worden succesvol warm gemaakt voor de ideeën,
                waardoor veel gerealiseerd kon worden.
              </li>
            </ul>

            <p className="theoryPage__text">
              Stap voor stap veranderde het team een aantal zaken.
            </p>

            <h2 className="theoryPage__subtitle">Een bredere kijk op EDO</h2>

            <p className="theoryPage__text">
              Ondanks de successen besefte het team dat ‘afval’ een beperkte
              invulling is van EDO. Misschien mede daardoor dat vooral
              leerlingen met grote affiniteit voor dit thema te engageren zijn
              om hun schouders te zetten onder grootse projecten.
            </p>

            <p className="theoryPage__text">
              Het team beslist om deel te nemen aan de ‘GoodPlanet Challenges’.
              Dit geeft de leerlingen gevarieerde uitdagingen die inzetten op
              verschillende thema’s:
            </p>

            <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
              <li>Eet lokaal</li>
              <li>Zero afval</li>
              <li>Dikketruiendag</li>
              <li>Iedereen waterdrager</li>
              <li>Buitenlesdag</li>
            </ul>

            <p className="theoryPage__text">
              Op deze manier voelen meer leerlingen en leraren aansluiting bij
              de initiatieven. Maar toch: velen hebben het gevoel een druppel op
              een hete plaat te zijn, ondanks de grote inspanningen die ze
              leveren. Dragen deze acties werkelijk bij tot een mooiere planeet?
              Lukt het met ons toch nog beperkte groepje?
            </p>

            <p className="theoryPage__text">
              Tot nu ‘invulbundel zonder bundel’. Denkwerk is gebeurd. Alleen
              gemotiveerden doen mee.
            </p>

            <h2 className="theoryPage__subtitle">Leerlingen aan het werk</h2>

            <p className="theoryPage__text">
              De GoodPlanet Challenges werken om een bredere kijk op EDO te
              krijgen. Maar in de uitwerking op school blijkt dat het denkwerk
              gebeurd is vooraleer de grote groep leerlingen en leraren
              betrokken worden. Diegenen die al overtuigd zijn van het belang
              van de ‘challenges’ doen het werk, de anderen ondergaan. Het
              verschil met een voorgekauwde invulbundel is niet groot, alleen
              dat er geen echte bundel bij komt kijken.
            </p>

            <p className="theoryPage__text">
              De aanpak wordt over een andere boeg gegooid: het team beslist om
              leerlingen niet te informeren, maar te laten participeren. In
              plaats van hen te vertellen wat ze moeten doen, worden ze zelf aan
              het denken gezet. Onder impuls van de 3 EDO-principes
              ‘onderzoekend leren’, ‘samen leren’ en ‘actiegericht leren’
              krijgen de leerlingen in de klas steeds meer autonomie.
            </p>

            <p className="theoryPage__text">Enkele voorbeelden:</p>

            <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
              <li>
                Het laadpunt (voor gsm’s) is kapot gegaan. De leraar zet een
                stap naar achter en laat leerlingen dit vaststellen, in plaats
                van zelf meteen zijn gereedschapskist boven te halen. Leerlingen
                stellen dit zelf vast, bedenken zelf een concept, ontwerpen en
                repareren het laadpunt. Het initiatief wordt werkelijk iets voor
                en door leerlingen.
              </li>
              <li>
                De leraar start een idee met leraren uit Israël en Brazilië dat
                de volgende vraag centraal stelt: ‘Hoe voelen leerlingen zich
                tijdens de lockdown?’. Na een kort gesprek creëert de leraar
                ruimte tijdens de les ‘mens en maatschappij’ voor leerlingen die
                hierrond iets willen visualiseren en delen op sociale media
                (#1yearlockeddown). De andere leerlingen die hier niet mee aan
                de slag gaan, krijgen andere opdrachten.
              </li>
              <li>
                Bij het begin van het schooljaar lijkt het de leraar fijn om het
                lokaal in te richten. In plaats van zelf aan de slag te gaan of
                alle leerlingen de opdracht te geven, voorziet hij oude stoffen,
                touwen en scharen. Leerlingen die willen mogen ermee aan de
                slag. Ze maken vlaggenlijnen van afvalmateriaal en gaan zelf op
                zoek naar gratis, tweedehands spullen om de klas gezellig te
                maken.
              </li>
            </ul>

            <p className="theoryPage__text">
              De leraar kauwt dus niet meer voor. Hij geeft autonomie aan
              leerlingen waarin ‘zelf aan de slag gaan’ en ‘fouten maken’
              voorwaarden zijn om te leren. Leerlingen voelen dat hun inbreng
              écht gewaardeerd wordt en dat ze een forum krijgen om zelfstandig
              iets uit te werken.
            </p>

            <h2 className="theoryPage__subtitle">Leraren aan het leren</h2>

            <p className="theoryPage__text">
              Maar ze zijn er nog niet. Alles wat gebeurt rond EDO mag niet
              beperkt blijven tot de eigen klas. De ambitie is om ook collega’s
              te inspireren en tot leren te laten komen. De leerlingenaanpak
              blijkt ook belangrijk bij leraren. Collega’s niet vertellen wat ze
              moeten doen, niet louter informeren maar ruimte geven om te
              participeren.
            </p>

            <p className="theoryPage__text">
              Zo werden er door (o.a. corona-)omstandigheden een tijd geen
              opruimacties georganiseerd op de speelplaats. Het effect bleef
              niet lang uit. De speelplaats was vuil, dat merkte ook collega’s
              op. Spontaan gingen de gesprekken over de speelplaats, en dat hier
              dringend iets aan gedaan moest worden! In plaats van in actie te
              schieten, luistert het kernteam en stelt vragen: waar dacht je
              aan? Wat zou je kunnen doen? Wat is mogelijk? Van zodra collega’s
              het idee opperen om met ‘grijpers’ aan de slag te gaan en
              leerlingen uit te nodigen om mee te helpen, wijst het kernteam de
              weg naar het nodige materiaal.
            </p>

            <p className="theoryPage__text">
              In plaats van zelf, preventief, de handen uit de mouwen te steken,
              creëert het kernteam ruimte voor initiatief van collega’s. Hun rol
              verschuift van initiatiefneer naar begeleider, coach, facilitator
              en zelfs verbindingsfiguur. Ze wijzen de weg, stellen vragen,
              moedigen aan, maar doen vooral geen voorstellen als daar niet om
              gevraagd wordt. Door hun jarenlange inzet en expertise weten
              collega’s dat ze altijd aanspreekbaar zijn om te ondersteunen en
              mee na te denken. Maar het initiatief laten ze aan anderen om ook
              bij hen passie te laten groeien.
            </p>

            <p className="theoryPage__text">
              Op die manier is het niet het kernteam dat voortdurend aansluiting
              zoekt tussen EDO en vakken, maar zijn het nu de collega’s die
              vragen om een Engelse tekst of oefening gelinkt aan duurzame
              ontwikkeling om te gebruiken in hun les.
            </p>

            <p className="theoryPage__text">
              Niet alleen leerlingen krijgen dus autonomie om te werken rond
              EDO, maar ook leraren wordt niet voorgekauwd hoe te werken rond
              EDO. Ook al is het kernteam bijvoorbeeld overtuigd van de
              meerwaarde om leerlingen zelf te laten zoeken naar oorzaken en
              gevolgen van een probleem om dan zelf een eigen relatiecirkel te
              maken, krijgt elke leraar de kans om dat stap voor stap aan te
              pakken. Als dat betekent dat zij tijdens hun eerste EDO-lessen
              meer de touwtjes in handen nemen dan het kernteam doet, dan is dat
              zo. Wel worden ze uitgedaagd om na te denken over hun aanpak en
              wat wel en niet werkt bij leerlingen.
            </p>

            <h2 className="theoryPage__subtitle">Besluit</h2>

            <p className="theoryPage__text">
            Soms los je een probleem dus beter op, door het een probleem te laten zijn. Ruimte laten voor anderen om iets op te merken en te ondernemen bevordert de democratische besluitvorming op school. Er wordt niet meer steeds naar dezelfde mensen gewezen als het gaat om EDO. Het krijgt daarentegen veel meer bottom-up vorm. Het eindresultaat ligt daardoor niet vast, maar de aandacht gaat dan ook vooral naar het proces dat het team (en de leerlingen) samen doorlopen. 
            </p>
          </section>

          <nav className="navbar-bottom">
            <Link
              className="navbar-bottom__link navbar-bottom__back btn-light"
              to="/school/praktijk/voorbeeld1"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <i className="icon-chevron-left"></i>
              <span className="btn-text">{chapters[1].title}</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/school/praktijk"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <div className="navbar-bottom__link navbar-bottom__next btn-light btn-light--hoverless"></div>
          </nav>
        </main>
      </div>
    );
  }
}

export default PraktijkVoorbeeld2;
