import React, { Component } from "react";
import { Link } from "react-router-dom";
import grid from "../../../assets/icons/algemeen/grid.png";
import praktijkvoorbeeld4Hero from "../../../assets/imgs/prikkel/praktijkvoorbeelden/foto11_pexels-brady-knoll-6108074.jpg";
import praktijkvoorbeeld4Schema from "../../../assets/imgs/prikkel/praktijkvoorbeelden/edo-principes.svg";

class PraktijkVoorbeeld4 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = this.props.chapters;
    return (
      <div id="praktijk-prikkel" className="theoryPage">
        <main>
        <section>
              <h1 className="theoryPage__title">Met de klas op schoolreis!</h1>
              <p className="theoryPage__text theoryPage__text--bold">
              De grote vakantie komt eraan. In de klas wordt reeds druk uitgewisseld waar de reisbestemming dit jaar heengaat. Marco gaat op bezoek bij familie in Marokko en Francesca op safari in Kenia. Bilal gaat op sportkamp in de Ardennen en Samantha trekt naar Duitsland.
              </p>
          </section>

          <img
            src={praktijkvoorbeeld4Hero}
            alt=""
            className="theoryPage__asset"
          />

          <section>
            <h2 className="theoryPage__subtitle">Linken met verschillende vakken </h2>

            <p className="theoryPage__text">
              Gaan we allemaal op reis? Is dit altijd zo geweest? Waarom gaan we op reis? En wat is de impact van op reis gaan? De leerkracht ziet enerzijds linken met aardrijkskunde, mobiliteit en voetafdruk, anderzijds is het ook interessant om reizen vanuit een historisch perspectief te benaderen en stil te staan bij het waarom we op reis gaan. De leerkracht vraagt zich af welk verschil we kunnen maken op school. Dit grijpt ze aan om samen met de leerlingen te verkennen hoe de volgende schoolreis kan georganiseerd worden. 
            </p>

            <h2 className="theoryPage__subtitle">Aan de slag!</h2>

            <p className="theoryPage__text">
              De leerlingen starten de leeractiviteit door alle aspecten van reizen in kaart te brengen: mobiliteit, lokale economie, impact op de omgeving, de verschillende functies van reizen (ontspanning, avontuur, informatief, cultuur). De leerlingen zoeken info over hoe je duurzaam kan reizen. Samen ontwikkelen ze een programma om volgend jaar op schoolreis te gaan. Ook de bestemming wordt samen bepaald. Er wordt een informatiebrochure gemaakt waarom deze schoolreis een duurzame reis is en waar je zoal rekening mee kan houden wanneer je duurzaam op reis wil gaan. 
            </p>
          </section>

          <aside className="theoryPage__image-text">
            <h2 class="theoryPage__title">Hoezo actiecompetent?</h2>
            <img
              src={praktijkvoorbeeld4Schema}
              alt=""
              className="theoryPage__asset"
            />
            <p class="theoryPage__text">Herken je actiecompetentie en de 3 EDO-principes in dit praktijkvoorbeeld? Heb je ondersteuning nodig? Ga aan de slag met het  <Link to="/prikkel/vormingsmateriaal">vormingsmateriaal</Link>. Wil je meer weten over de EDO-principes? Klik <Link to="/prikkel/introductie/principes">hier</Link>. </p>
          </aside>

          <nav className="navbar-bottom">
            <Link
              className="navbar-bottom__link navbar-bottom__back btn-light"
              to={chapters[2].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <i className="icon-chevron-left"></i>
              <span className="btn-text">{chapters[2].title}</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/prikkel/praktijk"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <div className="navbar-bottom__link navbar-bottom__next btn-light btn-light--hoverless"></div>
          </nav>
        </main>
      </div>
    );
  }
}

export default PraktijkVoorbeeld4;
