import { Component } from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import OverValies from "./pages/OverValies";
import PrikkelHome from "./pages/prikkel/PrikkelHome";
import ProjectHome from "./pages/project/ProjectHome";
import SchoolHome from "./pages/school/SchoolHome";

class App extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="App">
        <HashRouter>
          <Switch>
            <Route path={["/prikkel"]}>
              <PrikkelHome />
            </Route>
            <Route path={["/project"]}>
              <ProjectHome />
            </Route>
            <Route path={["/school"]}>
              <SchoolHome />
            </Route>
            <Route path={["/over-valies"]}>
              <OverValies></OverValies>
            </Route>
            <Route path={["/contact"]}>
              <Contact></Contact>
            </Route>
            <Route path={["/"]}>
              <Home></Home>
            </Route>
          </Switch>
        </HashRouter>
      </div>
    );
  }
}

export default App;
