import React, { Component } from "react";
import { Link } from "react-router-dom";

import Footer from "../components/Footer";
import HeaderHome from "../components/HeaderHome";
import HeaderPageMobile from "../components/HeaderPageMobile";
import NavigationBottom from "../components/NavigationBottom";

class Contact extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id="contact" className="theoryPage">
        <HeaderHome></HeaderHome>
        <HeaderPageMobile type={"portaal"}></HeaderPageMobile>
        <main>
            <section>
                <h1 className="theoryPage__title theoryPage__title--center">Contact</h1>

                <p className="theoryPage__text">
                    Heb je vragen over EDO? Wil je meer informatie over het onderzoeksproject VALIES? 
                </p>
                
                <p className="theoryPage__text">
                    Neem contact op met <a href="mailto:jelle.boevedepauw@uantwerpen.be">Jelle Boeve-de Pauw</a>, coördinator van het VALIES project. 
                </p>
            </section>
        </main>

        <NavigationBottom type={"home"}></NavigationBottom>
        <Footer type={"home"}></Footer>
      </div>
    );
  }
}

export default Contact;
