import React, { Component } from "react";
import { Link } from "react-router-dom";
import asset1 from "../../../assets/imgs/prikkel/introductie/daarom-EDO.svg";
import asset2 from "../../../assets/icons/prikkel/duurzame-ontwikkeling.png";
import asset3 from "../../../assets/icons/prikkel/duurzame-ontwikkelingsdoelstellingen.svg";
import { HashLink } from "react-router-hash-link";
import { Waypoint } from "react-waypoint";
import grid from "../../../assets/icons/algemeen/grid.png";
import { sdgImages } from "../../../assets/icons/algemeen/sdg/sdgImports";

class DaaromEDO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubtitle: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const chapters = this.props.chapters;
    const subchapters = [
      {
        title: "Wat verstaan we onder actiecompetenties en EDO",
        url: "#actiecompetentie",
        alt: "Schema van wat actiecompetentie betekent.",
      },
      {
        title: "Wat is Duurzame Ontwikkeling",
        url: "#duurzame-ontwikkeling",
        alt: "Schema van wat Duurzame Ontwikkeling is.",
      },
      {
        title: "Agenda 2030! Kompas richting duurzame toekomst",
        url: "#agenda-2030",
        alt: "Iconen van de Duurzame Ontwikkelingsdoelen.",
      },
    ];

    return (
      <div id="daarom-edo" className="theoryPage">
        <nav className="navbar navbar-left">
          <a
            className="navbar-toggler navbar-toggler--prikkel collapsed"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Inhoudstafel <i class="icon-chevron-down"></i>
          </a>
          <div className="collapse navbar-collapse" id="navbarNav">
            {" "}
            <Link
              to={chapters[0].url}
              className="navbar-left__item navbar-left__item--prikkel"
            >
              {chapters[0].title}
            </Link>
            <Link
              to={chapters[1].url}
              className="navbar-left__item  navbar-left__item--prikkel navbar-left__item--active"
            >
              {chapters[1].title}
            </Link>
            <HashLink
              smooth
              to={subchapters[0].url}
              className={`navbar-left__subitem navbar-left__subitem--prikkel ${
                this.state.activeSubtitle === subchapters[0].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[0].title}
            </HashLink>
            <HashLink
              smooth
              to={subchapters[1].url}
              className={`navbar-left__subitem navbar-left__subitem--prikkel ${
                this.state.activeSubtitle === subchapters[1].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[1].title}
            </HashLink>
            <HashLink
              smooth
              to={subchapters[2].url}
              className={`navbar-left__subitem navbar-left__subitem--prikkel ${
                this.state.activeSubtitle === subchapters[2].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[2].title}
            </HashLink>
            <Link
              to={chapters[2].url}
              className="navbar-left__item navbar-left__item--prikkel"
            >
              {chapters[2].title}
            </Link>
            <Link
              to={chapters[3].url}
              className="navbar-left__item navbar-left__item--prikkel"
            >
              {chapters[3].title}
            </Link>
            <Link
              to={chapters[4].url}
              className="navbar-left__item navbar-left__item--prikkel"
            >
              {chapters[4].title}
            </Link>
          </div>
        </nav>
        <main>
          <section>
            <h1 className="theoryPage__title">Daarom EDO!</h1>
            <p className="theoryPage__text theoryPage__text--bold">
              Vandaag worden we wereldwijd geconfronteerd met de grenzen van
              onze planeet. De manier waarop we leven heeft een ongeziene impact
              op klimaat, biodiversiteit en ecosystemen, armoede en
              ongelijkheid. Dergelijke complexe problemen los je niet in 1, 2, 3
              op. Dit vraagt om gezamenlijke inspanningen om{" "}
              <span className="theory__text-emphasis theory__text-emphasis--prikkel">
                duurzame ontwikkeling
              </span>{" "}
              te realiseren. Ook onze leerlingen kunnen hiertoe leren bijdragen.
              Maar worden ze wel ‘actiecompetent’ opgeleid om met de problemen
              van vandaag en morgen om te gaan? EDO wil hen ondersteunen om hun
              actiecompetentie te ontwikkelen.
            </p>

            <h2 className="theoryPage__subtitle" id="actiecompetentie">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[0].title,
                  });
                }}
              />
              <span className="theory__title-before theory__title-before--prikkel">
                <span className="theory__text-emphasis theory__text-emphasis--prikkel">
                  E
                </span>
                DO
              </span>
              {subchapters[0].title}
            </h2>
          </section>

          <aside className="theoryPage__image-text">
            <h2 class="theoryPage__title">
              Kim is actiecompetent rond duurzaamheid.
            </h2>
            <img
              className="theory__asset"
              src={asset1}
              alt={subchapters[0].alt}
            ></img>
            <p class="theoryPage__text">
              EDO wil verder gaan dan alleen denken over een leefbare wereld,
              EDO wil dat leerlingen <b>actiecompetent</b> worden. Dat houdt in
              dat ze:
            </p>
            <ul className="theoryPage__text theoryPage__ul--prikkel theoryPage__ul">
              <li>
                <b>kennis</b> opdoen en creëren over een duurzaamheidsprobleem
                en eigen en maatschappelijke waarden, normen en oplossingen
                verkennen;
              </li>
              <li>
                <b>willen</b> bijdragen aan een duurzame wereld;
              </li>
              <li>
                <b>vertrouwen hebben in het eigen kunnen</b> om bij te dragen
                aan een oplossing voor het complexe probleem (zowel individueel
                als samen met anderen);
              </li>
              <li>
                <b>vertrouwen (en hoop) hebben in de impact</b> van hun actie.
              </li>
            </ul>
          </aside>

          <section>
            <p className="theoryPage__text">
              Een fijne wereld om te leven voor alle mensen en de natuur op onze
              planeet, niet alleen vandaag, maar ook morgen en alle dagen erna…
              daar streven we naar met duurzame ontwikkeling. Educatie voor
              Duurzame Ontwikkeling wil leerlingen{" "}
              <span className="theory__text-emphasis--bold">
                leren denken over
              </span>{" "}
              en{" "}
              <span className="theory__text-emphasis--bold">
                bijdragen aan zo’n wereld
              </span>
              .
            </p>
            <ul className="theoryPage__text theoryPage__ul--prikkel theoryPage__ul">
              <li>
                Het leren denken over een leefbare wereld vraagt dat je begrijpt
                hoe duurzaamheidskwesties in elkaar zitten. Naast kennis rond de
                kwestie op te zoeken en kritisch te verkennen (fake news,
                wetenschappelijk onderbouwd, enz.) kan je ook zelf op onderzoek
                gaan en nieuwe kennis creëren (onderzoekende vaardigheden). 
              </li>
              <li>
                Wanneer we op zoek gaan naar oplossingen die werken, is het
                belangrijk te weten hoe andere mensen hierover denken. Wat is
                hun standpunt? Welke waarden en normen hebben zij?{" "}
              </li>
              <li>
                En kunnen we op basis hiervan samen werkende acties opzetten die
                mee bijdragen aan een oplossing?{" "}
              </li>
            </ul>
            <h3 className="theoryPage__sub-subtitle">
              EDO doet me denken aan andere educaties. Klopt dit?{" "}
            </h3>
            <p className="theoryPage__text">
              Inderdaad! Er zijn heel wat verbanden te leggen met andere
              educaties zoals burger&shy;schapseducatie, vredeseducatie, milieu-
              en natuureducatie, mensenrechten&shy;educatie, enz. Het verschil
              zit vaak in de inhoudelijke focus. Meer weten?{" "}
            </p>
            <h2 className="theoryPage__subtitle" id="duurzame-ontwikkeling">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[1].title,
                  });
                }}
              />
              <span className="theory__title-before theory__title-before--prikkel">
                {" "}
                E
                <span className="theory__text-emphasis theory__text-emphasis--prikkel">
                  DO
                </span>
              </span>
              {subchapters[1].title} {" "}
            </h2>
            <p className="theoryPage__text">
              Duurzame Ontwikkeling betekent een ontwikkeling van een leefbare
              wereld, nu en in de toekomst, voor onszelf, anderen en de natuur
              op onze planeet. Of zoals een Afrikaans afgevaardigde (naam
              onbekend) het ooit uitdrukte: ‘Genoeg voor altijd voor iedereen’.{" "}
            </p>
            <p className="theoryPage__text">
              Wil je weten of iets (bv. een actie of product) bijdraagt aan
              duurzame ontwikkeling, overloop dan de onderstaande 6 vragen.
              Indien je 6 keer ja kan antwoorden, dan draag je bij aan duurzame
              ontwikkeling.
            </p>
            <img
              className="theory__asset"
              src={asset2}
              alt={subchapters[1].alt}
              style={{ maxWidth: "300px" }}
            ></img>
            <ul className="theoryPage__text theoryPage__ul--prikkel theoryPage__ul">
              <li>
                Is het ecologisch verantwoord?{" "}
                <span className="theory__text-emphasis--bold">(planet)</span>
              </li>
              <li>
                Is het sociaal rechtvaardig?{" "}
                <span className="theory__text-emphasis--bold">(people)</span>
              </li>{" "}
              <li>
                Draagt het bij aan de economische welvaart op lange termijn?{" "}
                <span className="theory__text-emphasis--bold">
                  (prosperity)
                </span>
              </li>{" "}
              <li>
                Zijn de gevolgen voor toekomstige generaties in acht genomen?{" "}
                <span className="theory__text-emphasis--bold">(tijd)</span>
              </li>{" "}
              <li>
                Zijn de gevolgen voor iedereen op aarde in acht genomen?{" "}
                <span className="theory__text-emphasis--bold">(ruimte)</span>
              </li>{" "}
              <li>
                Is het democratisch gedragen?{" "}
                <span className="theory__text-emphasis--bold">
                  (participatie)
                </span>{" "}
              </li>
            </ul>
            <p className="theoryPage__text">
              Daarbij vertrekken we vanuit het principe van “sterke”
              duurzaamheid waarbij de economie bijdraagt aan sociale
              rechtvaardigheid en een gezond leefmilieu (en niet omgekeerd).{" "}
            </p>
            <h2 id="agenda-2030" className="theoryPage__subtitle">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[2].title,
                  });
                }}
              />
              {subchapters[2].title}{" "}
            </h2>
            <p className="theoryPage__text">
              Planet, people en prosperity (3P’s) klinkt goed, maar voor veel
              mensen blijft dit toch nog wat vaag. Wat houdt dit nu concreet in?
              De VN stelden een concrete Agenda op voor 2030, de 17 Duurzame
              Ontwikkelingsdoelstellingen, waarbij 17 duurzaamheids&shy;thema’s
              worden benoemd. De VN voegden nog een vierde en vijfde P
              toe: Peace en Partnerships:{" "}
            </p>
            <ul className="theoryPage__text theoryPage__ul--prikkel theoryPage__ul">
              <li>
                <span className="theory__text-emphasis--bold">Peace</span>:
                zonder vrede kunnen we duurzame ontwikkeling niet realiseren.
              </li>
              <li>
                <span className="theory__text-emphasis--bold">
                  Partnerships
                </span>
                : we kunnen duurzaamheid enkel realiseren wanneer we met zijn
                allen samenwerken en bijdragen: regeringen, bedrijven,
                gemeentebesturen, burgers en ja ook scholen, leerkrachten en
                leerlingen!
              </li>
            </ul>
            <div className="sdg-wrapper">
              {sdgImages.map((sdg, i) => (
                <img
                  className="sdg-img"
                  key={i}
                  alt={`SDG nummer ${i + 1}`}
                  src={sdg}
                ></img>
              ))}
            </div>
            {/* <img
              className="theory__asset"
              src={asset3}
              alt={subchapters[2].alt}
            ></img>*/}
          </section>
          <nav className="navbar-bottom">
            <Link
              className="navbar-bottom__link navbar-bottom__back btn-light"
              to={chapters[0].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <i className="icon-chevron-left"></i>
              <span className="btn-text">{chapters[0].title}</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/prikkel/introductie"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__next btn-light"
              to={chapters[2].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-text">{chapters[2].title}</span>
              <i className="icon-chevron-right"></i>
            </Link>
          </nav>
        </main>
      </div>
    );
  }
}

export default DaaromEDO;
