import React, { Component } from "react";
import { Link } from "react-router-dom";

import Footer from "../components/Footer";
import HeaderHome from "../components/HeaderHome";
import HeaderPageMobile from "../components/HeaderPageMobile";
import NavigationBottom from "../components/NavigationBottom";
import Partners from "../assets/imgs/over-valies.svg";

class OverValies extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id="over-valies" className="theoryPage">
        <HeaderHome></HeaderHome>
        <HeaderPageMobile type={"portaal"}></HeaderPageMobile>
        <main>
          <section>
            <h1 className="theoryPage__title theoryPage__title--center">
              Over VALIES
            </h1>
            <p className="theoryPage__text theoryPage__text--bold">
              Deze website is het resultaat van vijf jaar onderzoek,
              ontwikkeling en samenwerking. In het kader van het project VALIES
              (Valorizing integrated and action-oriented education for
              sustainable development) hebben we samen met meer dan 50 scholen
              uit heel Vlaanderen en heel wat partnerorganisaties onderzocht hoe
              EDO effectief in de praktijk gebracht kan worden. Dat deden we met
              de financiële steun van het FWO.
            </p>

            <p className="theoryPage__text">
              Speciale dank aan Basisschool De Bolster, Taborschool Bellem en
              Vrije Basisschool Langemark voor het bijdragen aan of aanleveren
              van fotomateriaal.
            </p>

            <p className="theoryPage__text">
              Meer informatie over het VALIES-project is{" "}
              <a
                href="https://www.uantwerpen.be/nl/onderzoeksgroep/edubron/onderzoek/recente-projecten/valies/"
                target="_blank"
              >
                hier
              </a>{" "}
              te vinden.
            </p>

            <p className="theoryPage__text">
              Enkele van de relevante wetenschappelijke publicaties die we
              schreven op basis van ons onderzoek:
            </p>

            <ul className="theoryPage__text theoryPage__ul">
              <li>
                Verhelst, D., Vanhoof, J., & Van Petegem, P. (2022).
                <a
                  href="https://hdl.handle.net/10067/1839800151162165141"
                  target="_blank"
                >
                  Development and validation of the education for sustainable
                  development school organisation questionnaire. Environmental
                  Education Research, 28(2), 241-259.
                </a>
              </li>
              <li>
                Sass, W., Boeve-de Pauw, J., De Maeyer, S., &amp; Van Petegem,
                P. (2021).{" "}
                <a
                  href="https://hdl.handle.net/10067/1753730151162165141"
                  target="_blank"
                >
                  Development and validation of an instrument for measuring
                  action competence in sustainable development within early
                  adolescents : the action competence in sustainable development
                  questionnaire (ACiSD-Q). Environmental Education Research,
                  27(9), 1284-1304.
                </a>
              </li>
              <li>
                Sass, W., Claes, E., Boeve-de Pauw, J., De Maeyer, S.,
                Schelfhout, W., Van Petegem, P., &amp; Isac, M. (2021).{" "}
                <a
                  href="https://hdl.handle.net/10067/1809560151162165141"
                  target="_blank"
                >
                  Measuring professional action competence in education for
                  sustainable development (PACesd). Environmental Education
                  Research. Online first.
                </a>
              </li>
              <li>
                Sinakou, E., Donche, V., Boeve-de Pauw, J., &amp; Van Petegem,
                P. (2021).{" "}
                <a
                  href="https://hdl.handle.net/10067/1788250151162165141"
                  target="_blank"
                >
                  Development and validation of a questionnaire on teachers'
                  instructional beliefs and practices in education for
                  sustainable development. Environmental Education Research,
                  27(9), 1305-1328.
                </a>
              </li>

              <li>
                Verhelst, D., Vanhoof, J., &amp; Van Petegem, P. (2021).{" "}
                <a
                  href="https://hdl.handle.net/10067/1745630151162165141"
                  target="_blank"
                >
                  School effectiveness for education for sustainable development
                  (ESD) : what characterizes an ESD-effective school
                  organization? Educational Management Administration and
                  Leadership. Online first.
                </a>
              </li>
              <li>
                Verhelst, D., Vanhoof, J., Boeve-de Pauw, J., &amp; Van Petegem,
                P. (2020).{" "}
                <a
                  href="https://hdl.handle.net/10067/1718160151162165141"
                  target="_blank"
                >
                  Building a conceptual framework for an ESD-effective school
                  organization. The Journal of Environmental Education, 51(6),
                  400-415.
                </a>
              </li>
              <li>
                Sass, W., Boeve-de Pauw, J., Olsson, D., Gericke, N., De Maeyer,
                S., &amp; Van Petegem, P. (2020).{" "}
                <a
                  href="https://hdl.handle.net/10067/1692030151162165141"
                  target="_blank"
                >
                  Redefining action competence : the case of sustainable
                  development. The Journal of Environmental Education, 51(4),
                  292-305.
                </a>
              </li>
              <li>
                Sinakou, E., Donche, V., Boeve-de Pauw, J., &amp; Van Petegem,
                P. (2019).{" "}
                <a
                  href="https://hdl.handle.net/10067/1635260151162165141"
                  target="_blank"
                >
                  Designing powerful learning environments in education for
                  sustainable development : a conceptual framework.
                  Sustainability, 11(21), 1-23.
                </a>
              </li>
            </ul>

            <p className="theoryPage__text">
              Het project VALIES werd gesteund door OVSG, het
              Gemeenschapsonderwijs!, 11.11.11, het Autonoom Gemeentebedrijf
              Stedelijk Onderwijs Antwerpen, AHOVOKS, de AP Hogeschool, Djapo,
              de Gezinsbond, GoodPlanet Belgium, Kleur Bekennen, MOS Vlaanderen,
              het NME/EDO platform van de Provincie Antwerpen, de PXL
              hogeschool, Studio Globo, Vlajo, en de Vereniging van de Vlaamse
              Provincies.
            </p>
          </section>

          <img className="theory__asset" src={Partners} alt="Over VALIES"></img>
        </main>

        <NavigationBottom type={"home"}></NavigationBottom>
        <Footer type={"home"}></Footer>
      </div>
    );
  }
}

export default OverValies;
