import sdg1 from "./SDG-icon-NL-01.svg";
import sdg2 from "./SDG-icon-NL-02.svg";
import sdg3 from "./SDG-icon-NL-03.svg";
import sdg4 from "./SDG-icon-NL-04.svg";
import sdg5 from "./SDG-icon-NL-05.svg";
import sdg6 from "./SDG-icon-NL-06.svg";
import sdg7 from "./SDG-icon-NL-07.svg";
import sdg8 from "./SDG-icon-NL-08.svg";
import sdg9 from "./SDG-icon-NL-09.svg";
import sdg10 from "./SDG-icon-NL-10.svg";
import sdg11 from "./SDG-icon-NL-11.svg";
import sdg12 from "./SDG-icon-NL-12.svg";
import sdg13 from "./SDG-icon-NL-13.svg";
import sdg14 from "./SDG-icon-NL-14.svg";
import sdg15 from "./SDG-icon-NL-15.svg";
import sdg16 from "./SDG-icon-NL-16.svg";
import sdg17 from "./SDG-icon-NL-17.svg";

export const sdgImages = [
  sdg1,
  sdg2,
  sdg3,
  sdg4,
  sdg5,
  sdg6,
  sdg7,
  sdg8,
  sdg9,
  sdg10,
  sdg11,
  sdg12,
  sdg13,
  sdg14,
  sdg15,
  sdg16,
  sdg17,
];
