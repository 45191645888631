import React, { Component } from "react";
import { Link } from "react-router-dom";

class OverzichtPrikkel extends Component {
  render() {
    return (
      <div className="tegeloverzicht__wrapper">
        <section className="tegeloverzicht tegeloverzicht-prikkel">
          {this.props.subchapters.map((subchapter) => (
            <Link
              key={subchapter.number}
              className="subchapter__tile subchapter__tile--prikkel"
              to={subchapter.url}
            >
              <div className="subchapter__img-wrapper">
                <div
                  className={`subchapter__img subchapter__img--prikkel${subchapter.number}`}
                ></div>
              </div>
              <div className="subchapter__title subchapter__title--prikkel">
                <h4>{subchapter.title}</h4>
              </div>
            </Link>
          ))}
        </section>
      </div>
    );
  }
}

export default OverzichtPrikkel;
