import React, { Component } from "react";
import { Link } from "react-router-dom";
import grid from "../../../assets/icons/algemeen/grid.png";
import praktijkvoorbeeld3Hero from "../../../assets/imgs/prikkel/praktijkvoorbeelden/foto10_pexels-tima-miroshnichenko-7009473.jpg";
import praktijkvoorbeeld3Schema from "../../../assets/imgs/prikkel/praktijkvoorbeelden/edo-principes.svg";

class PraktijkVoorbeeld3 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = this.props.chapters;
    return (
      <div id="praktijk-prikkel" className="theoryPage">
        <main>
          <section>
            <h1 className="theoryPage__title">Mag je kiezen waar je woont?</h1>
            <p className="theoryPage__text theoryPage__text--bold">
              In de klas merkt leerkracht Els dat de nieuwsberichten rond
              vluchtelingen discussies losweken. Sommige leerlingen reageren
              empathisch, anderen zijn bezorgd over de impact op onze
              maatschappij. Vorige jaren vertrok Els vanuit het werkboek, maar
              dit jaar wil ze het over een andere boeg gooien. Ze wil vooral
              werken aan begrip, empathie en met de persoonlijke ervaringen van
              leerlingen met een migratieachtergrond zoals Aïsha en Zachary.
            </p>
          </section>

          <img
            src={praktijkvoorbeeld3Hero}
            alt=""
            className="theoryPage__asset"
          />

          <section>
            <h2 className="theoryPage__subtitle">
              Filosoferen: standpunten onderzoeken
            </h2>

            <p className="theoryPage__text">
              Els start met een filosofisch gesprek. Ze bereidt de leerlingen
              voor door enkele basisregels af te spreken. Naast de algemene
              regels voor een klasgesprek (respect, luisteren, elkaar laten
              uitpraten) benadrukt ze de bijkomende aandachtspunten: wees
              nieuwsgierig naar de gedachten en redenen van de anderen, wie een
              mening inbrengt moet bereid zijn uit te leggen waarom hij/zij dat
              vindt. Ze geeft aan dat ze het wil hebben over migratie en gaat
              samen met de leerlingen op zoek naar een filosofische vraag die
              hen interesseert. Ze besluiten dat ze het willen hebben over de
              vraag: ‘Mag je kiezen waar je woont?’. Door gerichte vraagstelling
              houdt Els de leerlingen bij het thema en zoekt ze naar logische
              argumenten. Ze stelt bijvragen zoals: ‘Vind je dat iedereen het
              recht heeft om een andere woonplek te kiezen? Wat zijn redenen
              waarom iemand niet wil wonen waar hij/zij geboren is?’ Bij het
              afronden van het gesprek kijkt ze of er conclusies getrokken
              kunnen worden. Ze gaat ook na welke vragen de leerlingen verder
              willen onderzoeken.
            </p>

            <h2 className="theoryPage__subtitle">
              Verzamelen van verhalen. Wat weet de expert?
            </h2>

            <p className="theoryPage__text">
              Tijdens het filosofisch gesprek komen verschillende redenen aan
              bod waarom mensen willen vertrekken uit hun geboorteland en wat ze
              hopen te vinden in een ander land. Ze brengt deze samen met de
              leerlingen in kaart in een mindmap. Om verder inzicht te krijgen
              laat ze leerlingen in groepjes verhalen en getuigenissen
              verzamelen van mensen in hun omgeving met een
              migratie-achtergrond. Zo vullen ze hun mindmap aan. Ze wil echter
              niet dat het blijft bij het verzamelen van verhalen, daarom
              spreken ze af met een migratie-expert van de stadsdienst. De
              leerlingen bereiden het interview voor door hun vragen te
              bundelen. Na het interview verwerken ze hun inzichten in de
              mindmap. Tot slot, verwerken ze hun leerervaring in een
              persoonlijk verslag: Wat deed het onderzoek met mij? Denk ik nog
              hetzelfde over migratie? Wat is er (niet) veranderd? Welke vragen
              heb ik nog?
            </p>
          </section>

          <aside className="theoryPage__image-text">
            <h2 class="theoryPage__title">Hoezo actiecompetent?</h2>
            <img
              src={praktijkvoorbeeld3Schema}
              alt=""
              className="theoryPage__asset"
            />
            <p class="theoryPage__text">
              Herken je actiecompetentie en de 3 EDO-principes in dit
              praktijkvoorbeeld? Heb je ondersteuning nodig? Ga aan de slag met
              het <Link to="/prikkel/vormingsmateriaal">vormingsmateriaal</Link>
              . Wil je meer weten over de EDO-principes? Klik{" "}
              <Link to="/prikkel/introductie/principes">hier</Link>.{" "}
            </p>
          </aside>

          <nav className="navbar-bottom">
            <Link
              className="navbar-bottom__link navbar-bottom__back btn-light"
              to={chapters[1].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <i className="icon-chevron-left"></i>
              <span className="btn-text">{chapters[1].title}</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/prikkel/praktijk"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__next btn-light"
              to={chapters[3].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-text">{chapters[3].title}</span>
              <i className="icon-chevron-right"></i>
            </Link>
          </nav>
        </main>
      </div>
    );
  }
}

export default PraktijkVoorbeeld3;
