import React, { Component } from "react";
import { Link } from "react-router-dom";
import grid from "../../../assets/icons/algemeen/grid.png";
import praktijkvoorbeeld2Hero from "../../../assets/imgs/prikkel/praktijkvoorbeelden/foto9_pexels-ivan-samkov-5947556.jpg";
import praktijkvoorbeeld2Schema from "../../../assets/imgs/prikkel/praktijkvoorbeelden/edo-principes.svg";

class PraktijkVoorbeeld2 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = this.props.chapters;
    return (
      <div id="praktijk-prikkel" className="theoryPage">
        <main>
          <section>
            <h1 className="theoryPage__title">Black Friday in de klas</h1>
            <p className="theoryPage__text theoryPage__text--bold">
              Een school beslist, gekoppeld aan het jaarthema ‘het kan met wat
              minder’, deel te nemen aan de #MissieMinder-actiedag van MOS. In
              de klas van leerkracht Frank focussen ze zich op het kopen van
              kledij, meer bepaald de wereldwijde impact van de fast fashion
              industrie.
            </p>
          </section>

          <img
            src={praktijkvoorbeeld2Hero}
            alt=""
            className="theoryPage__asset"
          />

          <section>
            <h2 className="theoryPage__subtitle">Uiteenlopende meningen</h2>

            <p className="theoryPage__text">
              Tijdens de middagpauze ontstaat een verhitte discussie tussen
              leerlingen. Joyce vindt het erg dat er vele kinderen betrokken
              zijn bij de productie van goedkope kledij, maar ze vindt vooral
              dat de overheden in die landen maar strenger moeten optreden.
              Zolang de internationale regels hierrond niet veranderen, voelt ze
              zich niet schuldig wanneer ze in de Primark gaat shoppen. Brahim
              is hier niet mee akkoord, wij als consument hebben wel een
              verantwoordelijkheid op te nemen! Als iedereen zo reageert zal er
              nooit iets veranderen. Jan zegt dat hij hier niet mee bezig is en
              hij vindt het maar niets dat ze door de school verplicht worden om
              hierover na te denken.
            </p>

            <h2 className="theoryPage__subtitle">Inpikken op wat leeft</h2>

            <p className="theoryPage__text">
              De leerkracht vindt dat hij deze spanningen in de klasgroep niet
              kan negeren en beslist om na de middagpauze hier ruimte voor te
              geven. Eerst onderzoeken ze klassikaal welke verschillende
              meningen en emoties er leven in de klas. Om ook plaats te geven
              voor uiteenlopende standpunten spelen ze daarna een stellingenspel
              waarbij de leerlingen verplicht worden om zich in te leven in een
              ander standpunt (bv. een jongere die in armoede leeft, een
              kinderrechtenactivist, een politieker, een verkoper van de
              Primark, een producent van kledij, …). Ze besluiten dat in onze
              maatschappij verschillende standpunten leven, gelinkt aan diverse
              belangen of verschillende manieren om met deze problematiek om te
              gaan.
            </p>

            <h2 className="theoryPage__subtitle">Aan de slag!</h2>

            <p className="theoryPage__text">
              Tijdens de nabespreking geven leerlingen aan dat door het gebrek
              aan duidelijke informatie ze zich vaak geremd voelen om andere
              (meer duurzame) keuzes te maken. De tegenstrijdige berichten in de
              media zorgen ervoor dat ze niet altijd goed weten welke keuzes ze
              moeten maken. De leerkracht beslist samen met de leerlingen om een
              duurzame koopwijzer voor kledij te ontwerpen. Met een eerste
              ontwerp in de hand gaan ze op bezoek in enkele kledingwinkels, ze
              verkennen hoe de koopwijzer hen ondersteunt bij het winkelen en
              welke zaken nog moeten bijgestuurd worden om hun ontwerp verder te
              optimaliseren.
            </p>
          </section>

          <aside className="theoryPage__image-text">
            <h2 class="theoryPage__title">Hoezo actiecompetent?</h2>
            <img
              src={praktijkvoorbeeld2Schema}
              alt=""
              className="theoryPage__asset"
            />
            <p class="theoryPage__text">
              Herken je actiecompetentie en de 3 EDO-principes in dit
              praktijkvoorbeeld? Heb je ondersteuning nodig? Ga aan de slag met
              het <Link to="/prikkel/vormingsmateriaal">vormingsmateriaal</Link>
              . Wil je meer weten over de EDO-principes? Klik{" "}
              <Link to="/prikkel/introductie/principes">hier</Link>.{" "}
            </p>
          </aside>

          <nav className="navbar-bottom">
            <Link
              className="navbar-bottom__link navbar-bottom__back btn-light"
              to={chapters[0].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <i className="icon-chevron-left"></i>
              <span className="btn-text">{chapters[0].title}</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/prikkel/praktijk"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__next btn-light"
              to={chapters[2].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-text">{chapters[2].title}</span>
              <i className="icon-chevron-right"></i>
            </Link>
          </nav>
        </main>
      </div>
    );
  }
}

export default PraktijkVoorbeeld2;
