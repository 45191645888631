import React, { Component } from "react";
import { Link } from "react-router-dom";
import iconEdoBasis from "../assets/icons/algemeen/logo_EDO.svg";
import iconEdoPrikkel from "../assets/icons/prikkel/logo_EDO_prikkel.svg";
import iconEdoProject from "../assets/icons/project/logo_EDO_project.svg";
import iconEdoSchool from "../assets/icons/school/logo_EDO_school.svg";

class Header extends Component {
  render() {
    return (
      <header className="headerPage">
        <section className={`main-menu main-menu--${this.props.type}`}>
          <div className="main-menu--left">
            <Link to="/" className="main-menu__navLink">
              &#8249; naar portaal
            </Link>
          </div>
          <div className="main-menu--right">
            <Link
              to="/prikkel"
              className={`main-menu__navLink main-menu__navLink--${
                this.props.type === "prikkel" ? "active" : ""
              }`}
            >
              Prikkel
            </Link>
            <Link
              to="/project"
              className={`main-menu__navLink main-menu__navLink--${
                this.props.type === "project" ? "active" : ""
              }`}
            >
              Project
            </Link>
            <Link
              to="/school"
              className={`main-menu__navLink main-menu__navLink--${
                this.props.type === "school" ? "active" : ""
              }`}
            >
              School
            </Link>
          </div>
        </section>

        <section className="page-menu">
          <div className="page-menu--left">
            {" "}
            <Link
              to={
                this.props.type === "prikkel"
                  ? "/prikkel"
                  : this.props.type === "project"
                  ? "/project"
                  : this.props.type === "school"
                  ? "/school"
                  : "/"
              }
            >
              <img
                alt="Icon van de EDO-subsite"
                src={
                  this.props.type === "prikkel"
                    ? iconEdoPrikkel
                    : this.props.type === "project"
                    ? iconEdoProject
                    : this.props.type === "school"
                    ? iconEdoSchool
                    : iconEdoBasis
                }
              ></img>
            </Link>
          </div>

          <div className="page-menu--right">
            {this.props.chapters.map((chapter) => (
              <Link
                key={chapter.id}
                className={`page-menu__navLink ${
                  this.props.type
                }-color--active ${
                  chapter.number === this.props.activeChapter
                    ? this.props.type + "-color--selected"
                    : ""
                }`}
                to={chapter.chapterUrl}
              >
                {chapter.chapterName}
              </Link>
            ))}
          </div>
        </section>
      </header>
    );
  }
}

export default Header;
