import React, { Component } from "react";
import { Link } from "react-router-dom";
import grid from "../../../assets/icons/algemeen/grid.png";
import iconOnderzoekend from "../../../assets/icons/algemeen/icon_onderzoekend.svg";
import iconSamen from "../../../assets/icons/algemeen/icon_samen.svg";
import iconActiegericht from "../../../assets/icons/algemeen/icon_actiegericht.svg";

import doelenlijst from "../../../assets/docs/project/praktijkvoorbeelden/download_EDO project ‘voor elkaar’_doelenlijst.pdf";

class PraktijkVoorbeeld1 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = this.props.chapters;
    return (
      <div id="praktijk-prikkel" className="theoryPage">
        <main>
          <section>
            <h1 className="theoryPage__title">EDO project 'voor elkaar'</h1>
            <p className="theoryPage__text theoryPage__text--bold">
              PTS campus Mechelen is een secundaire school met wetenschappelijke
              en technische opleidingen binnen de domeinen STEM en Land- en
              tuinbouw. Zowel in de A- als in de B-stroom focust PTS campus
              Mechelen zich de voorbije 2 jaar op de integratie van EDO aan de
              hand van projectwerking.
            </p>
            <p className="theoryPage__text">
              Leerlingen van de 1<sup>ste</sup> graad B-stroom gaan aan de slag
              met de vraag ‘wat kunnen we voor elkaar doen?’. Hoe we met elkaar
              omgaan en welke impact we hebben op elkaar wordt gekoppeld aan de
              duurzame ontwikkelingsdoelstellingen: ‘geen armoede’ (SDG 1),
              ‘geen honger’ (SDG 2) en ‘goede gezondheid en welzijn’ (SDG 3).
            </p>
            <p className="theoryPage__text">
              Leerlingen van het 1<sup>ste</sup> en 2<sup>de</sup> jaar werken 3
              dagen lang samen aan dit EDO-project, ze gaan hiervoor in kleine
              groepjes van maximum 8 leerlingen aan de slag.
            </p>
          </section>

          <aside>
            <h2 className="theoryPage__subtitle">
              In dit EDO-project wordt gewerkt aan ...{" "}
            </h2>
            <h2 className="theoryPage__subtitle theoryPage__subtitle--marked theoryPage__subtitle--marked-onderzoekend">
              <span className="theory__title-before">
                <img
                  src={iconOnderzoekend}
                  alt="Icoon van onderzoekend leren"
                ></img>
              </span>
              Onderzoekend leren
            </h2>
            <ul className="theoryPage__text theoryPage__ul theoryPage__ul--onderzoekend">
              <li>
                Leerlingen koppelen concrete casussen (lokaal &amp; wereldwijd)
                aan grotere duurzaamheidskwesties (SDG’s 1, 2 & 3)
              </li>
              <li>
                Leerlingen verkennen oorzaken, gevolgen en diverse betrokkenen
                van duurzaamheidskwesties gelinkt aan de SDG’s (armoede,
                hongersnood, gezondheidsproblematieken) en verbinden die met
                elkaar.
              </li>
            </ul>

            <h2 className="theoryPage__subtitle theoryPage__subtitle--marked theoryPage__subtitle--marked-samen">
              <span className="theory__title-before">
                <img src={iconSamen} alt="Icoon van samen leren"></img>
              </span>
              Samen leren
            </h2>
            <ul className="theoryPage__text theoryPage__ul theoryPage__ul--samen">
              <li>
                Leerlingen verkennen uiteenlopende meningen en waarden gelinkt
                aan de verschillende duurzaamheidskwesties.
              </li>
              <li>
                Leerlingen verkennen hoe ze zelf betrokken zijn met de
                verschillende duurzaamheidskwesties en wat dit met hen doet.
              </li>
            </ul>

            <h2 className="theoryPage__subtitle theoryPage__subtitle--marked theoryPage__subtitle--marked-actiegericht">
              <span className="theory__title-before">
                <img
                  src={iconActiegericht}
                  alt="Icoon van actiegericht leren"
                ></img>
              </span>
              Actiegericht leren
            </h2>
            <ul className="theoryPage__text theoryPage__ul theoryPage__ul--actiegericht">
              Leerlingen bedenken creatieve oplossingen en toekomstgerichte
              handelingen die ze nadien uittesten:
              <li>Leerlingen wisselen ideeën voor acties met elkaar uit.</li>
              <li>Leerlingen testen hun acties uit.</li>
            </ul>

            <div className="btn-wrapper">
              <a className="btn-light" href={doelenlijst} download>
                <span className="btn-text">Download algemene doelenlijst</span>
              </a>
            </div>
          </aside>

          <section>
            <h2 className="theoryPage__subtitle">Didactisch ontwerp</h2>

            <h3 class="theoryPage__sub-subtitle">
              Uitdaging: smaakmaker als opstart
            </h3>

            <p className="theoryPage__text">
              Als smaakmaker wordt de film ‘The Boy Who Harnessed the Wind’
              bekeken waarin de 17 SDG’s aan bod komen. Daarnaast lezen ze de
              strip ‘De Planeet en de 17 doelen’ die nog meer achtergrond geeft
              over de verschillende SDG’s. De leerlingen worden gevraagd om de
              SDG’s te selecteren die hen het meeste aanspreken. Het thema van
              het EDO-project wordt samen met de leerlingen bepaald op basis van
              hun keuze (SDG 1, 2 &amp; 3).
            </p>

            <p className="theoryPage__blockquote">
              “Door te vertrekken vanuit de SDG’s was het project minder
              vrijblijvend. Iedereen nam zijn verantwoordelijkheid op, zowel de
              leerlingen als de leerkrachten zagen de meerwaarde in van het
              project. Dit zorgde voor een groot draagvlak!”
            </p>

            <h3 class="theoryPage__sub-subtitle">
              Verkennen vanuit 3 gekozen SDG’s{" "}
            </h3>

            <p className="theoryPage__text">
              Het EDO project loopt over 3 dagen, waarbij telkens 1 SDG centraal
              staat. De leerlingen maken thuis{" "}
              <b>een voorbereidende opdracht</b>. Door een variatie aan
              werkvormen verkennen de leerlingen zelfstandig de SDG’s, oa. via:
            </p>

            <ul className="theoryPage__text theoryPage__ul">
              <li>
                het bekijken van een getuigenis van een leeftijdsgenoot in
                armoede (SDG 1){" "}
              </li>
              <li>
                het lezen van een artikel rond de oorzaken van ondervoeding (SDG
                2),{" "}
              </li>
              <li>
                een reflectieoefening wat armoede voor hen betekent en wat een
                mogelijke oplossing is (SDG 1){" "}
              </li>
              <li>een zelftest hoe goed ze in hun vel zitten (SDG 3)</li>
              <li>
                het verzamelen van materiaal dat werd meegenomen naar school:
                een prent of verpakking van een voedingsproduct en een
                ‘tienuurtje’ naar keuze (SDG 3).
              </li>
            </ul>

            <p className="theoryPage__text">
              Aan de hand van <b>verdiepende activiteiten</b> worden de
              duurzaamheidskwesties rond de 3 SDG’s verder verkend op school:
            </p>

            <ul className="theoryPage__text theoryPage__ul">
              <li>
                de meegebrachte verpakkingen en ‘tienuurtjes’ worden in de
                groepjes vergeleken en besproken: welk eten heb je mee? Wat valt
                er op? Welke gelijkenissen/verschillen zie je? Waarom maakte je
                een bepaalde keuze?
              </li>
              <li>
                het concept van de voedingsdriehoek wordt verkend aan de hand
                van een creatieve opdracht: ontwerp je ideale ontbijt, verken
                welke voedingsproducten aanwezig zijn (SDG 2).
              </li>
              <li>
                via een stellingenspel worden verschillende meningen en
                standpunten rond mentale gezondheid verkend (SDG 3).{" "}
              </li>

              <li>
                een kringgesprek over pesten focust op eigen ervaringen: wanneer
                spreken we over pesten? Ben je ooit gepest geweest? Heb je zelf
                ooit iemand gepest? Heb je al eens meegemaakt dat er iemand
                gepest werd? Hoe heb je toen gereageerd? Hoe kan je pesten
                voorkomen? Hoe vind jij dat pesters gestraft moeten worden?, …{" "}
              </li>
            </ul>

            <p className="theoryPage__blockquote">
              “Het werken met kleine groepjes bevorderde de communicatie tussen
              leerlingen, door bewust leerlingen uit het 1ste en 2de jaar te
              mixen creëerden we een grotere diversiteit in de groepjes.
              Leerlingen wisselden meningen, standpunten en ideeën uit met
              leerlingen uit andere klassen. Op deze manier leerden ze niet
              alleen elkaar beter kennen, maar soms ook hun eigen mening of
              standpunt bijstellen.”
            </p>

            <h3 class="theoryPage__sub-subtitle">
              Terugblik: een gezamenlijke afsluiter
            </h3>

            <p className="theoryPage__text">
              Het EDO-project eindigt met een gezamenlijke slotdag waarbij de
              resultaten van het EDO-project worden gedeeld.
            </p>

            <ul className="theoryPage__text theoryPage__ul">
              <li>
                De leerlingen van de kleuter- en lagere scholen genieten mee van
                de soep die door de leerlingen is gemaakt.
              </li>
              <li>
                De wenskaarten voor alleenstaande buurtbewoners worden bezorgd.
              </li>
              <li>
                Het zelfgemaakte filmpje voor de campagne tegen hongersnood
                wordt gelanceerd.
              </li>
            </ul>

            <p className="theoryPage__text">
              De leerlingen genieten van de slotdag en nemen de tijd om terug te
              blikken op het doorlopen proces, wat ze hebben geleerd en hoe ze
              hebben samengewerkt om hun acties tot een goed einde te brengen.
            </p>

            <h3 class="theoryPage__sub-subtitle">
              Reflectie aanpak leerkrachten{" "}
            </h3>

            <p className="theoryPage__text">
              De leerkrachten blikken tevreden terug op het EDO-project, voor
              het eerst probeerden ze een andere aanpak en dit heeft zijn
              vruchten afgeworpen. Met het kernteam hebben ze veel tijd gestoken
              in het op punt stellen van een werkbundel voor de collega’s, dit
              zorgde voor veel bruikbare materialen en een goede houvast. Het
              zorgde meteen voor veel enthousiasme en draagvlak, ook bij de
              vakleraren betrokken in dit project. Op deze manier kunnen
              collega’s rustig groeien in het verkennen van de EDO-principes en
              het begeleiden van zo’n project zonder onzekerheden over de
              inhouden & aanpak.
            </p>

            <p className="theoryPage__blockquote">
              “Door het project expliciet te koppelen aan verschillende
              sleutelcompetenties, en te vertrekken vanuit de duurzame
              ontwikkelingsdoelstellingen zag iedereen ook de meerwaarde in van
              dit project. Het is in ieder geval een aanpak die we meenemen naar
              toekomstige EDO-projecten. We hopen dat hierdoor de EDO-principes
              nog meer ingeburgerd geraken in het hele team, en het
              zelfvertrouwen en de goesting voor EDO verder zal groeien!”
            </p>

            <h3 class="theoryPage__sub-subtitle">Good to know</h3>

            <ul className="theoryPage__text theoryPage__ul">
              <li>
                Meer over het ‘spelbord over kansarmoede’ van Studio Globo:{" "}
                <a
                  href="https://www.studioglobo.be/aanbod/schoolweg-spelbord-over-kansarmoede"
                  target="_blank"
                >
                  https://www.studioglobo.be/aanbod/schoolweg-spelbord-over-kansarmoede
                </a>
              </li>
              <li>
                Aan de slag met SDG’s op school:{" "}
                <a href="https://sdgsatschool.be/nl" target="_blank">
                  https://sdgsatschool.be/nl
                </a>
              </li>
              <li>
                SDG strip ‘De planeet en de 17 doelen’:{" "}
                <a
                  href="http://www.helmond-sanmarcos.nl/WP/wp-content/uploads/2017/10/comicGlobalGoals.pdf"
                  target="_blank"
                >
                  http://www.helmond-sanmarcos.nl/WP/wp-content/uploads/2017/10/comicGlobalGoals.pdf
                </a>
              </li>
              <li>
                Film ‘The boy who harnessed the wind’:{" "}
                <a
                  href="https://www.moviemeter.nl/film/1118601"
                  target="_blank"
                >
                  https://www.moviemeter.nl/film/1118601
                </a>
              </li>
            </ul>
          </section>

          <nav className="navbar-bottom">
            <div className="navbar-bottom__link navbar-bottom__back btn-light btn-light--hoverless"></div>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/project/praktijk"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__next btn-light"
              to={chapters[1].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-text">{chapters[1].title}</span>
              <i className="icon-chevron-right"></i>
            </Link>
          </nav>
        </main>
      </div>
    );
  }
}

export default PraktijkVoorbeeld1;
