import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className={`footer footer--${this.props.type}`}>
        <a href="www.oetang.be" className="footer__link">
          Oetang.be
        </a>
      </footer>
    );
  }
}

export default Footer;
