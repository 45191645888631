import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import HeaderPage from "../../components/HeaderPage";
import HeaderPageMobile from "../../components/HeaderPageMobile";
import NavigationBottom from "../../components/NavigationBottom";
import IntroductieProject from "./introductie/IntroductieProject";
import PraktijkProject from "./praktijk/PraktijkProject";
import VormingsmateriaalProject from "./vormingsmateriaal/VormingsmateriaalProject";
import ProjectShape from "../../assets/icons/project/hero-image_project.svg";
import { HashLink } from "react-router-hash-link";

class ProjectHome extends Component {
  render() {
    const chapters = [
      {
        number: 1,
        chapterName: "Didactisch ontwerp",
        chapterUrl: "/project/introductie",
      },
      {
        number: 2,
        chapterName: "Projecten in de praktijk",
        chapterUrl: "/project/praktijk",
      },
      {
        number: 3,
        chapterName: "Vormingsmateriaal",
        chapterUrl: "/project/vormingsmateriaal",
      },
    ];

    return (
      <div>
        <Switch>
          <Route
            path={["/project"]}
            exact
            render={() => (
              <div>
                <HeaderPage
                  type={"project"}
                  chapters={chapters}
                  activeChapter={0}
                ></HeaderPage>
                <HeaderPageMobile
                  type={"project"}
                  chapters={chapters}
                  activeChapter={0}
                ></HeaderPageMobile>
                <main id="project-home">
                  <div className="overview-top">
                    <div className="overview-top__info">
                      <h2 className="overview-top__title">
                        Ontwerp krachtige EDO-projecten voor en met je
                        leerlingen
                      </h2>
                      <HashLink className="btn-dark" to="#verken-project">
                        <span className="btn-text">Verken</span>
                        <i className="icon-chevron-right"></i>
                      </HashLink>{" "}
                    </div>
                    <img
                      className="overview-top__shape overview-top__shape--prikkel"
                      src={ProjectShape}
                      alt="Project vorm"
                    ></img>
                  </div>
                  <section className="overview-chapter1" id="verken-project">
                    <div className="overview-chapter__info">
                      <h4 className="overview-chapter__subtitle project-color">
                        Project
                      </h4>
                      <h2 className="overview-chapter__title">
                        {chapters[0].chapterName}
                      </h2>
                      <p className="overview-chapter__description">
                         Actiecompetentie van leerlingen realiseer je niet in enkele lesjes, maar vraagt een doordachte aanpak. Verken stapsgewijs hoe je diepgaande EDO-ontwerpen kan ontwerpen voor en me je leerlingen. 
                      </p>
                      <Link className="btn-light" to="/project/introductie">
                        <span className="btn-text">Leer ontwerpen</span>
                        <i className="icon-chevron-right"></i>
                      </Link>{" "}
                    </div>
                    <div className="overview-chapter__img overview-chapter__img--1"></div>
                  </section>
                  <section className="overview-chapter2 overview-chapter--mirrored">
                    <div className="overview-chapter__info">
                      <h4 className="overview-chapter__subtitle project-color">
                        Project
                      </h4>
                      <h2 className="overview-chapter__title">
                        {chapters[1].chapterName}
                      </h2>
                      <p className="overview-chapter__description">
                      Laat je inspireren door prikkelende voorbeelden uit de praktijk. Krijg zicht hoe je de EDO-projecten kan vormgeven op basis van het didactisch concept. 
                      </p>
                      <Link className="btn-light" to="/project/praktijk">
                        <span className="btn-text">
                          Lees de praktijkvoorbeelden
                        </span>
                        <i className="icon-chevron-right"></i>
                      </Link>{" "}
                    </div>
                    <div className="overview-chapter__img overview-chapter__img--2"></div>
                  </section>
                  <section className="overview-chapter3">
                    <div className="overview-chapter__info">
                      <h4 className="overview-chapter__subtitle project-color">
                        Project
                      </h4>
                      <h2 className="overview-chapter__title">
                        {chapters[2].chapterName}
                      </h2>
                      <p className="overview-chapter__description">
                      Ga zelf aan de slag met materiaal om EDO-projecten vorm te geven, vertrouwd te worden met het didactisch concept of anderen te inspireren. 
                      </p>
                      <Link
                        className="btn-light"
                        to="/project/vormingsmateriaal"
                      >
                        <span className="btn-text">Download materiaal</span>
                        <i className="icon-chevron-right"></i>
                      </Link>{" "}
                    </div>
                    <div className="overview-chapter__img overview-chapter__img--3"></div>
                  </section>
                </main>
              </div>
            )}
          ></Route>
          <Route path={["/project/introductie"]}>
            <IntroductieProject chapters={chapters}></IntroductieProject>
          </Route>
          <Route path={["/project/praktijk"]}>
            <PraktijkProject chapters={chapters}></PraktijkProject>
          </Route>
          <Route path={["/project/vormingsmateriaal"]}>
            <VormingsmateriaalProject
              chapters={chapters}
            ></VormingsmateriaalProject>
          </Route>
        </Switch>
        <NavigationBottom type={"project"}></NavigationBottom>
        <Footer type={"project"}></Footer>
      </div>
    );
  }
}

export default ProjectHome;
