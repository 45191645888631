import React, { Component } from "react";
import { Link } from "react-router-dom";

import { HashLink } from "react-router-hash-link";
import { Waypoint } from "react-waypoint";
import grid from "../../../assets/icons/algemeen/grid.png";
import edoprojectSchema from "../../../assets/imgs/project/introductie/edo-project.png";

import foto14 from "../../../assets/imgs/project/introductie/foto14_S_0386.jpg";

class HoezoEdoProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubtitle: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const chapters = this.props.chapters;
    const subchapters = chapters[0].subchapters;

    return (
      <div id="hoezo-edo-project" className="theoryPage">
        <nav className="navbar navbar-left">
          <a
            className="navbar-toggler navbar-toggler--project collapsed"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Inhoudstafel <i class="icon-chevron-down"></i>
          </a>
          <div className="collapse navbar-collapse" id="navbarNav">
            {" "}
            <Link
              to={chapters[0].url}
              className="navbar-left__item navbar-left__item--project navbar-left__item--active"
            >
              {chapters[0].title}
            </Link>
            <HashLink
              smooth
              to={subchapters[0].url}
              className={`navbar-left__subitem navbar-left__subitem--project ${
                this.state.activeSubtitle === subchapters[0].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[0].title}
            </HashLink>
            <HashLink
              smooth
              to={subchapters[1].url}
              className={`navbar-left__subitem navbar-left__subitem--project ${
                this.state.activeSubtitle === subchapters[1].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[1].title}
            </HashLink>
            <HashLink
              smooth
              to={subchapters[2].url}
              className={`navbar-left__subitem navbar-left__subitem--project ${
                this.state.activeSubtitle === subchapters[2].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[2].title}
            </HashLink>
            <Link
              to={chapters[1].url}
              className="navbar-left__item navbar-left__item--project"
            >
              {chapters[1].title}
            </Link>
            <Link
              to={chapters[2].url}
              className="navbar-left__item navbar-left__item--project"
            >
              {chapters[2].title}
            </Link>
            <Link
              to={chapters[3].url}
              className="navbar-left__item navbar-left__item--project"
            >
              {chapters[3].title}
            </Link>
            <Link
              to={chapters[4].url}
              className="navbar-left__item navbar-left__item--project"
            >
              {chapters[4].title}
            </Link>
            <Link
              to={chapters[5].url}
              className="navbar-left__item navbar-left__item--project"
            >
              {chapters[5].title}
            </Link>
          </div>
        </nav>
        <main>
          <section>
            <h1 className="theoryPage__title">{chapters[0].title}</h1>

            <h2
              className="theoryPage__subtitle"
              id={subchapters[0].url.replace("#", "")}
            >
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[0].title,
                  });
                }}
              />
              {subchapters[0].title}
            </h2>

            <p className="theoryPage__text">
              Het ondersteunen en vormgeven van EDO leeractiviteiten vraagt een
              doordachte en diepgaande aanpak. Wanneer je leerlingen wil laten
              groeien in hun actiecompetentie, is het belangrijk om
              onderzoekend, samen en actiegericht aan de slag te gaan. Het vergt
              een gefaseerde benadering die het best aansluit bij
              projectwerking, om zo de EDO-principes ‘onderzoekend – samen –
              actiegericht leren’ met elkaar te verbinden. Dit kan je enkel
              realiseren via een langer leertraject waarbij je leerlingen de
              kans geeft om relevante kennis te vinden (of te creëren), en hen
              ruimte geeft om zelf acties te bedenken en uit te testen. Zo kan
              onderwijs niet alleen kennis en inzichten bij leerlingen over deze
              thema’s verhogen, maar leerlingen ook motiveren en vertrouwen
              geven, om op een constructieve manier met complexe problemen om te
              gaan.
            </p>
          </section>

          <img src={edoprojectSchema} alt="" className="theoryPage__asset" />

          <section>
            <h2
              className="theoryPage__subtitle"
              id={subchapters[1].url.replace("#", "")}
            >
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[1].title,
                  });
                }}
              />
              {subchapters[1].title} {" "}
            </h2>

            <p className="theoryPage__text">Projectwerk creëert kansen om…</p>

            <ul className="theoryPage__text theoryPage__ul--project theoryPage__ul">
              <li>kennis op te bouwen en met elkaar te verbinden.</li>

              <li>acties te bedenken en uit te testen.</li>

              <li>
                inzichten met elkaar te delen over hoe je met complexe
                maatschappelijke problemen kan omgaan.
              </li>

              <li>
                aan te sluiten bij vragen en noden van de leerlingen, de school,
                de omgeving, maatschappij … Dit zorgt voor een grotere
                betrokkenheid van de leerlingen en authenticiteit van de
                leersituatie.
              </li>

              <li>
                verantwoordelijkheid, ruimte en autonomie te geven aan
                leerlingen om zelf oplossingen voor duurzaamheidskwesties te
                bedenken en uit te testen. Dit draagt bij aan het ontwikkelen
                van actiecompetente leerlingen.
              </li>

              <li>
                in kleinere groep te werken, waarbij leerlingen niet alleen
                elkaar kunnen ondersteunen, maar ook andere meningen of
                inzichten leren kennen. Daarnaast werk je ook aan diverse
                vaardigheden, zoals dialoogvaardigheden, oplossingsgericht
                denken, …
              </li>

              <li>verschillende vakken en domeinen met elkaar te verbinden.</li>
            </ul>

            <h2
              className="theoryPage__subtitle"
              id={subchapters[2].url.replace("#", "")}
            >
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[2].title,
                  });
                }}
              />
              {subchapters[2].title}{" "}
            </h2>

            <aside>
              <h3 className="theoryPage__sub-subtitle">
                ‘Lang leve een propere buurt!’{" "}
              </h3>

              <p className="theoryPage__text">
                Een lagere school organiseert met haar leerlingen een
                opruimactiviteit van zwerfvuil in de buurt. Gepakt met
                opruimtangen en vuilniszakken wordt het buurtparkje helemaal
                opgeruimd.
              </p>

              <p className="theoryPage__text">
                Bovenstaand voorbeeld is vast wel herkenbaar. Het is een mooie
                activiteit waarbij je kinderen het inzicht kan bijbrengen dat we
                samen het verschil kunnen maken. Echter blijft het vaak een
                symbolische actie, waarbij een onderzoekende houding ontbreekt.
              </p>

              <p className="theoryPage__text">
                Er zijn heel wat opportuniteiten om deze activiteit uit te
                breiden tot een volwaardige EDO leeractiviteit. Wat is
                zwerfvuil? Hoe komt het hier? Waarom is er afval? Wat gooien we
                soms zelf op de grond? Wie zou dit doen en waarom? Is afval op
                de grond slecht voor het milieu? Is afval altijd lelijk? Hoe kan
                je afval voorkomen? … Enkele vragen die kunnen aanzetten tot
                verdiepend leren. Een dergelijke opruimactiviteit kan bv. dienen
                om vragen bij de leerlingen op te roepen als aanzet voor een
                langer leertraject. Evengoed kunnen leerlingen deze activiteit
                zelf organiseren, en bedenken hoe ze kunnen bijdragen aan een
                propere buurt. Door hiervoor ruimte te voorzien, kunnen
                leerlingen zelf zaken uitproberen en hierover reflecteren. Op
                deze manier staan ze meer stil bij de eigen impact die ze kunnen
                hebben.
              </p>
            </aside>
          </section>

          <img
            className="theory__asset"
            src={foto14}
            alt={subchapters[2].alt}
          ></img>

          <nav className="navbar-bottom">
            <div className="navbar-bottom__link navbar-bottom__back btn-light btn-light--hoverless"></div>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/project/introductie"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__next btn-light"
              to={chapters[1].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-text">{chapters[1].title}</span>
              <i className="icon-chevron-right"></i>
            </Link>
          </nav>
        </main>
      </div>
    );
  }
}

export default HoezoEdoProject;
