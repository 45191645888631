import React, { Component } from "react";
import { Link } from "react-router-dom";
import grid from "../../../assets/icons/algemeen/grid.png";
import praktijkvoorbeeld1Hero from "../../../assets/imgs/prikkel/praktijkvoorbeelden/foto8_pexels-marta-ortigosa-3480494.jpg";
import praktijkvoorbeeld1Schema from "../../../assets/imgs/prikkel/praktijkvoorbeelden/edo-principes.svg";

class PraktijkVoorbeeld1 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = this.props.chapters;
    return (
      <div id="praktijk-prikkel" className="theoryPage">
        <main>
          <section>
            <h1 className="theoryPage__title">Plastic op je bord</h1>
            <p className="theoryPage__text theoryPage__text--bold">
              Myrthe is aangedaan. Gisteren zag ze op Karrewiet een filmpje over
              een aangespoelde walvis. Na onderzoek bleek de walvis 22 kg
              plastic in de maag te hebben: vuilniszakken, visnetten, lijnen en
              buizen. Hoe kan dit, vraagt ze zich af? Ook Yussef had gehoord van
              een koe die stierf door het eten van een blikje dat verborgen lag
              in het gras. De kinderen begrijpen het niet: hoe kan dit? Van waar
              komen al dat plastic en die blikjes?
            </p>
          </section>
          <img
            src={praktijkvoorbeeld1Hero}
            alt=""
            className="theoryPage__asset"
          />
          <section>
            <h2 className="theoryPage__subtitle">
              Onderzoek en systeemdenken in de klas{" "}
            </h2>
            <p className="theoryPage__text">
              Leerkracht Maarten begrijpt de verontwaardiging van de kinderen en
              besluit ruimte te creëren om samen met de kinderen de oorzaken,
              gevolgen en mogelijke oplossingen te onderzoeken: hoe komt het dat
              sommige materialen zo’n schade aanrichten? Vroeger was dit toch
              niet zo? Mensen gebruikten toen ook verpakkingsmiddelen.
            </p>
            <p className="theoryPage__text">
              De leerlingen onderzoeken welke materialen lang blijven bestaan en
              welke niet. Ze zoeken dit op in bronnen en zetten enkele
              (langdurige) composteerexperimenten op. Ze lezen dat materialen
              zoals plastic en blikjes tientallen jaren in de natuur blijven.
              Deze breken niet of traag af en dan nog verdwijnen ze soms niet
              helemaal en blijven ze als microplastics in de natuur of de
              voedselketen. Deze materialen hebben ook voordelen: ze zijn licht
              en waterdicht, dus ideaal om dranken in te bewaren. De leerlingen
              wegen voor- en nadelen van de verschillende materialen af. Maarten
              stelt voor om dit overzichtelijk voor te stellen in een
              pijlenschema.
            </p>

            <h2 className="theoryPage__subtitle">Jouw mening is de mijne</h2>
            <p className="theoryPage__text">
              Samen met de leerlingen wordt er gediscussieerd over het waarom
              van eenmalige verpakkingen. Lisa vindt het wel handig om op reis
              in het treinstation frisdrank te kunnen kopen.{" "}
              <b>
                <i>“Het is toch belangrijk om voldoende te drinken.”</i>
              </b>{" "}
              Ze smijt ook altijd haar afval in de vuilnisbak, dus dat is dan
              toch geen probleem.{" "}
              <b>
                <i>“Ja maar”</i>
              </b>
              , reageert Yussef,{" "}
              <b>
                <i>
                  “jij doet dat wel, maar we weten toch nooit zeker dat iedereen
                  dat zal doen. Ik vind het belangrijk dat dat nooit kan. Ik
                  vind dat alle verpakkingsmateriaal eetbaar zou moeten zijn
                  zodat dieren niet sterven wanneer ze dit per ongeluk opeten”
                </i>
              </b>{" "}
              De kinderen gaan in discussie over wat kan en wat zij belangrijk
              vinden. Op sommige vlakken vinden ze elkaar. Niemand wil immers
              dat er dieren sterven aan zwerfvuil, maar hoe dit te bereiken daar
              zijn ze het niet over eens. De leerkracht stelt voor om het
              pijlenschema dat ze eerder maakten er eens bij te nemen: misschien
              zijn er verschillende oplossingen te bedenken: zowel bij de
              oorzaken als bij de gevolgen. De leerlingen gaan aan de slag en
              bedenken uiteenlopende oplossingen. Maarten laat hen ook nadenken
              over andere manieren van actie ondernemen waar ze nog niet aan
              dachten: wie zouden ze kunnen aanspreken om mee de verandering te
              realiseren? De kinderen hebben er wel zin in omdat ze weten dat ze
              mee het verschil kunnen maken…
            </p>
          </section>

          <aside className="theoryPage__image-text">
            <h2 class="theoryPage__title">Hoezo actiecompetent?</h2>
            <img
              src={praktijkvoorbeeld1Schema}
              alt=""
              className="theoryPage__asset"
            />
            <p class="theoryPage__text">
              Herken je actiecompetentie en de 3 EDO-principes in dit
              praktijkvoorbeeld? Heb je ondersteuning nodig? Ga aan de slag met
              het <Link to="/prikkel/vormingsmateriaal">vormingsmateriaal</Link>
              . Wil je meer weten over de EDO-principes? Klik{" "}
              <Link to="/prikkel/introductie/principes">hier</Link>.{" "}
            </p>
          </aside>

          <nav className="navbar-bottom">
            <div className="navbar-bottom__link navbar-bottom__back btn-light btn-light--hoverless"></div>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/prikkel/praktijk"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__next btn-light"
              to={chapters[1].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-text">{chapters[1].title}</span>
              <i className="icon-chevron-right"></i>
            </Link>
          </nav>
        </main>
      </div>
    );
  }
}

export default PraktijkVoorbeeld1;
