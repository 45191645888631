import React, { Component } from "react";
import { Link } from "react-router-dom";
import asset1 from "../../../assets/icons/school/edo-effectieve-school@2x.png";
import iconVisie from "../../../assets/imgs/school/gedeelde-visie.png";
import iconRelaties from "../../../assets/imgs/school/ondersteunende-relaties.png";
import grid from "../../../assets/icons/algemeen/grid.png";
import praktijkvoorbeeld31 from "../../../assets/imgs/school/praktijkvoorbeelden/Oudergem1.png";
import praktijkvoorbeeld32 from "../../../assets/imgs/school/praktijkvoorbeelden/Oudergem2.png";
import praktijkvoorbeeld33 from "../../../assets/imgs/school/praktijkvoorbeelden/Oudergem3.png";

class PraktijkVoorbeeld3 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = this.props.chapters;
    return (
      <div id="praktijk-prikkel" className="theoryPage">
        <main>
          <section>
            <h1 className="theoryPage__title">
              VBS Sint Juliaan de Vlindertuin te Oudergem{" "}
            </h1>

            <h2 className="theoryPage__subtitle">
              Hoe gaan leren in groep en leren op eigen tempo hand in hand?
            </h2>

            <p className="theoryPage__text">
              Vrije Basisschool Sint Juliaan de Vlindertuin is een basisschool
              in hartje Brussel. De diversiteit onder leerlingen is groot. Bij
              de start van het EDO-traject staat het team voor de uitdaging om
              het nieuwe leerplan ‘Zin in Leren! Zin in Leven!’ te
              implementeren.
            </p>

            <p className="theoryPage__text theoryPage__text--bold">
              De school wil tijdens dit EDO-traject een antwoord vinden op de
              volgende vraag: Hoe kunnen we onze leeractiviteiten en acties rond
              EDO structureler inbedden in onze werking? Hiervoor zal het team
              samen lessen ontwikkelen om reguliere W.O.-thema’s uit te werken
              vanuit een duurzame invalshoek.
            </p>
          </section>

          <img
            className="theory__asset"
            src={asset1}
            alt={chapters[0].alt}
          ></img>

          <aside>
            <p className="theoryPage__text">
              <b>Hoe werkt VBS Sint-Juliaan de Vlindertuin aan:</b>
            </p>

            <h2 className="theoryPage__subtitle">
              <span className="theory__title-before theory__title-before--school">
                <img src={iconVisie} alt=""></img>
              </span>

              <span
                className="theory__title-emphasis theory__title-emphasis--school"
                style={{ backgroundColor: "#C7DCDD" }}
              >
                Gedeelde visie
              </span>
            </h2>

            <p className="theoryPage__text">
              Het EDO-traject start met het stellen van de ‘waaromvraag’: Waarom
              willen we op onze school werk maken van Educatie voor Duurzame
              Ontwikkeling? Wat willen we bereiken met onze leerlingen, maar ook
              met ons lerarenteam?
            </p>

            <div className="theoryPage__school theoryPage__school--middelen">
              <span className="theoryPage_list-title">Vanuit middelen</span>
              <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
                <li>
                  Het schoolteam zet EDO steeds op de agenda tijdens
                  teamvergaderingen. Het kernteam dat de externe studiedagen
                  volgt, krijgt ruimte om de nieuwe kennis en inzichten te delen
                  tijdens overleg. Maar ook worden hele teamvergaderingen
                  vrijgemaakt om te werken aan de visie.
                </li>
              </ul>
            </div>

            <div className="theoryPage__school theoryPage__school--leiderschap">
              <span className="theoryPage_list-title">
                Vanuit duurzaam leiderschap
              </span>
              <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
                <li>
                  De directie heeft een duidelijke visie voor ogen waarbij ze
                  wil inzetten op structurele acties. Maar tijdens overleg
                  draagt elk teamlid bij aan de visie.
                </li>
              </ul>
            </div>

            <h2 className="theoryPage__subtitle">
              <span className="theory__title-before theory__title-before--school">
                <img src={iconRelaties} alt=""></img>
              </span>

              <span
                className="theory__title-emphasis theory__title-emphasis--school"
                style={{ backgroundColor: "#FFB9E1" }}
              >
                Ondersteunende relaties
              </span>
            </h2>

            <p className="theoryPage__text">
              De leraren in Sint Juliaan De Vlindertuin ondersteunen elkaar,
              soms letterlijk door samen voor de klas te staan. Daarnaast worden
              relaties met externe partners benut om de schoolwerking te
              ondersteunen.
            </p>

            <div className="theoryPage__school theoryPage__school--middelen">
              <span className="theoryPage_list-title">Vanuit middelen</span>
              <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
                <li>
                  Vrije uren en middagen worden benut om leeractiviteiten voor
                  te bereiden en te bespreken. Leraren stemmen hun uurrooster op
                  elkaar af om samen les te kunnen geven en zo te leren van
                  elkaar.
                </li>
              </ul>
            </div>

            <div className="theoryPage__school theoryPage__school--leiderschap">
              <span className="theoryPage_list-title">
                Vanuit duurzaam leiderschap
              </span>
              <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
                <li>
                  Elk teamlid is mee verantwoordelijk voor zijn eigen leren. Ze
                  nemen leiderschap op in het aangeven van hun noden en het
                  creëren van kansen om samen te leren.
                </li>
              </ul>
            </div>
          </aside>

          <section>
            <h2 className="theoryPage__subtitle">Leren in groep</h2>

            <p className="theoryPage__text">
              De leraren van VBS Sint Juliaan De Vlindertuin worden gekenmerkt
              door gedrevenheid en passie: de directie legt enthousiast uit waar
              het schoolteam naar toe wil op vlak EDO. Haar passie is
              ongetwijfeld een hefboom voor de gedeelde visie van het team. Door
              het EDO-traject te starten met de ‘waaromvraag’ ontstaat er
              gaandeweg een gedeelde visie die steeds verfijnd en
              geconcretiseerd wordt. Het team formuleert antwoorden op deze
              vragen:
            </p>

            <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
              <li>Wat is de gewenste verandering?</li>
              <li>Welke doelen streven we na voor leerlingen en leraren?</li>
            </ul>

            <p className="theoryPage__text">
              Zo wijzen de neuzen in dezelfde richting:
            </p>

            <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
              <li>EDO beklijvend maken</li>
              <li>
                acties meer en doordacht inbedden in de klas, geen eenmalige,
                losstaande acties meer
              </li>
              <li>acties door de leerlingen</li>
              <li>met als doel leerlingen bewust en kritisch te maken</li>
            </ul>

            <p className="theoryPage__text">
              Onderweg stelt het team regelmatig de vragen ‘Wat doen we al
              goed?’ en ‘Hoe komt dit?’ om na te gaan of wat ze doen ook
              effectief is. De tussentijdse feedbackrapporten zijn hierbij een
              belangrijke input. Voluit gaan voor initiatieven die blijven en
              beklijven, is dus het motto. Deze sterke start zorgt ervoor dat
              het team klaar is om samen en doelgericht te leren over EDO.
            </p>

            <img
              className="theory__asset"
              src={praktijkvoorbeeld31}
              alt={chapters[0].alt}
            ></img>

            <h2 className="theoryPage__subtitle">
              Leiderschap met oog op de toekomst
            </h2>

            <p className="theoryPage__text">
              Elk teamlid neemt leiderschap op: in het team, in zijn klas, in
              overleg met parallelcollega’s, tijdens pedagogische studiedagen, …
              Niet alleen de directie staat in de schijnwerpers om te vertellen
              over het ‘hoe’ en het ‘wat’. Pedagogische studiedagen worden
              voorbereid door zowel leden van het kernteam, de directie als de
              pedagogisch begeleider. Ieder heeft een rol tijdens de
              werksessies. In de klas krijgt elke leraar autonomie om zijn
              thema’s uit te werken, maar de directie en leraren staan wel klaar
              voor elkaar om hierin te ondersteunen indien nodig. De
              ondersteunende relaties tussen de leraren zijn dus een belangrijk
              element in dit EDO-traject.
            </p>

            <p className="theoryPage__text">
              Daarnaast betekent duurzaam leiderschap voor Sint Juliaan De
              Vlindertuin ook dat ze niet meer kiezen voor eenmalige acties. Ze
              schrappen in aantal initiatieven en kiezen – met input van de
              leerlingen – jaarlijks voor één goed doel dat uitgebreid wordt
              uitgewerkt. Zo streeft men naar diepgaand leren, voor de lange
              termijn, zowel voor de leerlingen als voor de leraren zelf.
            </p>

            <h2 className="theoryPage__subtitle">Leren op eigen tempo</h2>

            <p className="theoryPage__text">
              De leraren van Sint Juliaan De Vlindertuin ontwikkelen en leren op
              hun eigen tempo. Valkuilen die ze tegenkomen, bespreken ze met
              elkaar. Leraren met bezorgdheden omtrent taakbelasting en het
              missen van de nodige expertise, worden gehoord en in de meest
              letterlijke betekenis van het woord bijgestaan.
            </p>

            <p className="theoryPage__text">
              De leraren van de 2<sup>de</sup> en 3<sup>de</sup> graad zijn
              bijvoorbeeld snel vertrouwd met de EDO-principes en werken al snel
              diepgaand het thema ‘afval’ uit. De leraren van de 1<sup>ste</sup>{" "}
              graad observeren en wachten even af, maar zijn dan zelf vragende
              partij voor extra coaching. Samen brainstormen we over thema’s die
              geknipt zijn om EDO aan op te hangen in de 1<sup>ste</sup> graad,
              zoals het thema ‘armoede’.
            </p>

            <img
              className="theory__asset"
              src={praktijkvoorbeeld32}
              alt={chapters[0].alt}
            ></img>

            <p className="theoryPage__text">
              De kleuteronderwijzers experimenteren met werkvormen en linken hun
              thema’s vrijuit aan EDO. Tijdens coachingsmomenten met de
              kleuteronderwijzers zetten we jaarlijks terugkerende thema’s vast
              in een mindmap. Deze thema’s worden uitgewerkt vanuit EDO, zoals
              het thema ‘boerderij’.
            </p>

            <img
              className="theory__asset"
              src={praktijkvoorbeeld33}
              alt={chapters[0].alt}
            ></img>

            <p className="theoryPage__text">
              De leraren van de verschillende klassen delen hun thema’s met
              elkaar, zodat er geen overlap is maar een thematische leerlijn
              ontstaat van kleuter tot 3de graad. De thema’s werken ze
              doelgericht uit vanuit ZiLL. Zonder samenwerking lukt dit
              EDO-traject dus niet.
            </p>

            <p className="theoryPage__text">
              Om dit gezamenlijk leerproces mogelijk te maken, wordt heel bewust
              tijd gecreëerd. Overleg om af te stemmen en bij te leren van
              elkaar krijgt voorrang op de dagdagelijkse routine. Speeltijden
              duren langer als het belangrijkste agendapunt nog niet werd
              afgerond, een klas wordt overgenomen door een collega of leraren
              staan samen voor de klas om te leren van elkaars talenten.
            </p>

            <p className="theoryPage__text">
              De diversiteit in Sint Juliaan de Vlindertuin is groot bij de
              leraren, net zoals bij de leerlingen. Leraren hebben verschillende
              expertise en interesses maar maken allemaal deel uit van een
              lerend team.
            </p>

            <h2 className="theoryPage__subtitle">Besluit: Samen op eigen tempo</h2>

            <p className="theoryPage__text">
              In deze school ontstaat gaandeweg een gedeelde visie in het team,
              die alle vrijheid in zich had om elk teamlid zijn eigen weg te
              laten zoeken. Leren op eigen tempo en samen leren gaan dus hand in
              hand. De{" "}
              <span className="theory__text-emphasis theory__text-emphasis--school">
                ondersteunende relaties
              </span>{" "}
              die aanwezig zijn in de school, helpen hierbij. Niet alleen
              leraren helpen elkaar onderling. Ook zet de directie partners uit
              diverse organisaties rond de tafel in het lerarenlokaal en zoekt
              samen met hen naar gedeelde opportuniteiten. En rond die tafel kan
              iedereen spreken vanuit zichzelf, maar in het volste vertrouwen
              van respect.
            </p>
          </section>

          <nav className="navbar-bottom">
            <Link
              className="navbar-bottom__link navbar-bottom__back btn-light"
              to="/school/praktijk/voorbeeld1"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <i className="icon-chevron-left"></i>
              <span className="btn-text">{chapters[0].title}</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/school/praktijk"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__next btn-light"
              to="/school/praktijk/voorbeeld3"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-text">{chapters[2].title}</span>
              <i className="icon-chevron-right"></i>
            </Link>
          </nav>
        </main>
      </div>
    );
  }
}

export default PraktijkVoorbeeld3;
