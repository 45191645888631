import React, { Component } from "react";
import { Link } from "react-router-dom";
import asset1 from "../../../assets/imgs/project/introductie/didactische_concepten.svg";
import { HashLink } from "react-router-hash-link";
import { Waypoint } from "react-waypoint";
import grid from "../../../assets/icons/algemeen/grid.png";

import methodiek0 from "../../../assets/docs/project/download_andere methodieken.pdf";
import methodiek1 from "../../../assets/docs/project/download_methodiek_omgevingsfotos.pdf";
import methodiek2 from "../../../assets/docs/project/download_methodiek_terug en vooruitdenken.pdf";
import methodiek3 from "../../../assets/docs/project/download_methodiek_extreme meningen.pdf";
import methodiek4 from "../../../assets/docs/project/download_methodiek_backcasting.pdf";
import methodiek5 from "../../../assets/docs/project/download_methodiek_creatief denken.pdf";
import methodiek6 from "../../../assets/docs/project/download_methodiek_bevraging.pdf";
import methodiek7 from "../../../assets/docs/project/download_methodiek_posterbeurs.pdf";

class ProjectUitwerken extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubtitle: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const chapters = this.props.chapters;
    const subchapters = [
      {
        title: "Uitdaging",
        url: "#uitdaging",
        alt: "Uitdaging",
      },
      {
        title: "Verkennen",
        url: "#verkennen",
        alt: "Verkennen",
      },
      {
        title: "Bijdragen",
        url: "#bijdragen",
        alt: "Bijdragen",
      },
      {
        title: "Terugblik",
        url: "#terugblik",
        alt: "Terugblik",
      },
    ];

    return (
      <div id="didactische-concepten" className="theoryPage">
        <nav className="navbar navbar-left">
          <a
            className="navbar-toggler navbar-toggler--project collapsed"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Inhoudstafel <i class="icon-chevron-down"></i>
          </a>
          <div className="collapse navbar-collapse" id="navbarNav">
            {" "}
            <Link
              to={chapters[0].url}
              className="navbar-left__item navbar-left__item--project "
            >
              {chapters[0].title}
            </Link>
            <Link
              to={chapters[1].url}
              className="navbar-left__item navbar-left__item--project "
            >
              {chapters[1].title}
            </Link>
            <Link
              to={chapters[2].url}
              className="navbar-left__item navbar-left__item--project "
            >
              {chapters[2].title}
            </Link>
            <Link
              to={chapters[3].url}
              className="navbar-left__item navbar-left__item--project navbar-left__item--active"
            >
              {chapters[3].title}
            </Link>
            <HashLink
              smooth
              to={subchapters[0].url}
              className={`navbar-left__subitem navbar-left__subitem--project ${
                this.state.activeSubtitle === subchapters[0].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[0].title}
            </HashLink>
            <HashLink
              smooth
              to={subchapters[1].url}
              className={`navbar-left__subitem navbar-left__subitem--project ${
                this.state.activeSubtitle === subchapters[1].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[1].title}
            </HashLink>
            <HashLink
              smooth
              to={subchapters[2].url}
              className={`navbar-left__subitem navbar-left__subitem--project ${
                this.state.activeSubtitle === subchapters[2].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[2].title}
            </HashLink>
            <HashLink
              smooth
              to={subchapters[3].url}
              className={`navbar-left__subitem navbar-left__subitem--project ${
                this.state.activeSubtitle === subchapters[3].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[3].title}
            </HashLink>
            <Link
              to={chapters[4].url}
              className="navbar-left__item navbar-left__item--project"
            >
              {chapters[4].title}
            </Link>
            <Link
              to={chapters[5].url}
              className="navbar-left__item navbar-left__item--project"
            >
              {chapters[5].title}
            </Link>
          </div>
        </nav>
        <main>
          <section>
            <h1 className="theoryPage__title">{chapters[3].title}</h1>

            <p class="theoryPage__text theoryPage__text--bold">
              Een EDO-project duurt uiteraard langer dan 1 lesuurtje. Met deze informatie kan je stapsgewijs een EDO-project voorbereiden. We geven concrete inspiratie en verwijzen door naar methodieken om onderstaand didactisch concept in de eigen klaspraktijk te brengen.  
            </p>
          </section>

          <img
            className="theory__asset"
            src={asset1}
            alt={chapters[1].alt}
          ></img>

          <section>
            <h2 className="theoryPage__subtitle" id="uitdaging">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[0].title,
                  });
                }}
              />
              {subchapters[0].title}
            </h2>

            <p class="theoryPage__text">
              Een EDO-project vertrekt vanuit een uitdaging. Dit is een concrete situatie (maatschappelijke kwestie of een duurzaamheidsprobleem) die de leerlingen herkennen in de eigen leefwereld en die hen uitdaagt tot verder onderzoek. Het is dus belangrijk om zicht te krijgen op welke vragen en thema's er bij de leerlingen leven. Door hierop in te spelen, geef je leerlingen de kans om de inhouden en het verloop van een EDO-project mee vorm te geven.  
            </p>

            <p class="theoryPage__text">
              Tip: Je kan hiervoor gebruik maken van een <b>smaakmaker</b> of een <b>prikkel</b> die aanzet tot reacties en die vragen oproept bij de leerlingen. Op deze manier krijg je inzicht in hoe het thema leeft bij leerlingen, wat hun aandacht trekt en waar ze interesse in hebben. Dit zorgt voor een eerste afbakening van het brede thema, en kan leiden tot verder te onderzoeken vragen. 
            </p>
          </section>

          <aside>
            <h3 className="theory__sub-subtitle theory__sub-subtitle--center">Methodiek: omgevingsfoto's</h3>
            <p className="theoryPage__text">
              In het kader van een project rond 'duurzame mobiliteit' maken de leerlingen een buurtwandeling waarbij ze foto's maken van mobiliteitsproblemen in de schoolomgeving. Ze stellen zich vragen over het aantal kinderen dat met de wagen naar school wordt gebracht. Een klein deel van de ouders beschikt over een elektrische wagen, maar is dat wel beter voor onze planeet? Een interessante onderzoeksvraag die vertrekt vanuit de leerlingen en waarmee jij en je leerlingen in het project van start gaan. 
            </p>
            <div className="btn-wrapper btn-wrapper--project">
              <a className="btn-dark" href={methodiek1} download>
                <span className="btn-text">Download deze methodiekfiche</span>
                <i className="icon-arrow-down-1"></i>
              </a>
              <a className="btn-dark" href={methodiek0} download>
                <span className="btn-text">Download andere methodieken</span>
                <i className="icon-arrow-down-1"></i>
              </a>
            </div>
          </aside>

          <section>
            <h2 className="theoryPage__subtitle" id="verkennen">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[1].title,
                  });
                }}
              />
              {subchapters[1].title}{" "}
            </h2>

            <h3 class="theoryPage__sub-subtitle">Inleven in de uitdaging</h3>

            <h4>Waar gaat het over?</h4>

            <p className="theoryPage__text">
              In een EDO-project leven leerlingen zich in, zodat ze goed begrijpen waarover het duurzaamheidsprobleem gaat. Hierbij gaan ze de uitdaging vanuit <b>verschillende invalshoeken</b> benaderen en het thema in een groter geheel plaatsen (systeemdenken). 
            </p>
          </section>
         
          <aside>
            <h3 className="theory__sub-subtitle theory__sub-subtitle--center">Methodiek: Terug- en vooruitdenken (systeemdenken)</h3>
            <p className="theoryPage__text">
              In de klas verkennen de leerlingen het mobiliteitsprobleem vanuit verschillende invalshoeken. Via de methodiek 'terug- en vooruitdenken' trachten ze het onderliggende systeem in beeld te brengen door de oorzaken en gevolgen van het duurzaamheidsprobleem met elkaar te verbinden. Deze methodiek ondersteunt hen om vanuit een helikopterzicht het probleem beter te begrijpen en inzicht te krijgen in de complexe relaties tussen oorzaken en gevolgen. Ze zien in dat er niet enkel ecologische gevolgen zijn (Planet: er worden heel wat grondstoffen gebruikt), maar ook op sociaal vlak (People: de productie van auto's in lageloonlanden) en dat dit veroorzaakt wordt door economische oorzaken (Profit: bedrijven willen maximale winst maken).
            </p>
            <div className="btn-wrapper btn-wrapper--project">
              <a className="btn-dark" href={methodiek2} download>
                <span className="btn-text">Download deze methodiekfiche</span>
                <i className="icon-arrow-down-1"></i>
              </a>
              <a className="btn-dark" href={methodiek0} download>
                <span className="btn-text">Download andere methodieken</span>
                <i className="icon-arrow-down-1"></i>
              </a>
            </div>
          </aside>

          <section>
            <h4>Elk zijn mening!</h4>

            <p className="theoryPage__text">
              Duurzaamheidsproblemen roepen heel wat verschillende meningen op. Elke persoon zal immers vanuit een ander perspectief of waardenkader naar het probleem kijken. Het is daarom belangrijk om te onderzoeken hoe dit leeft bij jezelf en bij anderen. Door verschillende perspectieven aan bod te laten komen, worden leerlingen uitgedaagd om hun eigen mening in vraag te stellen.
            </p>
          </section>

          <aside>
            <h3 className="theory__sub-subtitle theory__sub-subtitle--center">Methodiek: extreme meningen verzamelen</h3>
            <p className="theoryPage__text">
              De leerlingen doen een buurtonderzoek om de verschillende meningen over het mobiliteitsprobleem in kaart te brengen. In kleine groepjes gaan ze op pad en interviewen verschillende personen: een bejaard koppel dat al 30 jaar naast de school woont, een toevallige voorbijganger, enkele ouders die dagelijks met de wagen naar school komen, … Ze verwerken de interviews in een 'persona-fiche' waarbij de meningen, noden, wensen van de verschillende personen in kaart worden gebracht. 
            </p>
            <div className="btn-wrapper btn-wrapper--project">
              <a className="btn-dark" href={methodiek3} download>
                <span className="btn-text">Download deze methodiekfiche</span>
                <i className="icon-arrow-down-1"></i>
              </a>
              <a className="btn-dark" href={methodiek0} download>
                <span className="btn-text">Download andere methodieken</span>
                <i className="icon-arrow-down-1"></i>
              </a>
            </div>
          </aside>

          <section>
            <h3 class="theoryPage__sub-subtitle">Probleem goed begrijpen</h3>

            <p className="theoryPage__text">
              Na het verkennen van een duurzaamheidsprobleem (vanuit verschillende invalshoeken, betrokkenen, meningen, …), gaan leerlingen onderzoeken in welke mate ze zelf impact hebben op het duurzaamheidsprobleem. Het is immers niet mogelijk om zomaar een complex duurzaamheidsprobleem zoals bv. klimaatverandering op te lossen. Wel kunnen leerlingen onderzoeken hoe ze zelf iets kunnen bijdragen (bv. door anders met mobiliteit om te gaan). Ze gaan dus een haalbare nood afbakenen, iets waar ze daadwerkelijke hun schouders onder kunnen zetten. 
            </p>
          </section>

          <aside>
            <h3 className="theory__sub-subtitle theory__sub-subtitle--center">Methodiek: Van droom naar concrete actie (backcasting)</h3>
            <p className="theoryPage__text">
              Halverwege het EDO-project blikt de leerkracht samen met de leerlingen even terug op wat ze de voorbije periode hebben gedaan. Ze overschouwen het uitgebreid schema (systeem) dat ze samen opgebouwd hebben. Er wordt aan de leerlingen gevraagd om een droom te formuleren, gelinkt aan het duurzaamheidsprobleem. Waar dromen ze van op vlak van duurzame mobiliteit? Ze proberen deze droom zo concreet mogelijk te formuleren (Hoe ziet die ‘droomwereld’ er in de toekomst uit? Hoe leven ze? …). Vanuit die gedroomde situatie denken de leerlingen terug: Wat is er nodig om die droom te realiseren? Wat kunnen ze nu al doen om de wereld te veranderen in deze droomwereld? Op die manier gaan leerlingen prioriteiten of concrete noden gaan bepalen die kunnen leiden tot concrete ideeën of acties. 
            </p>
            <div className="btn-wrapper btn-wrapper--project">
              <a className="btn-dark" href={methodiek4} download>
                <span className="btn-text">Download deze methodiekfiche</span>
                <i className="icon-arrow-down-1"></i>
              </a>
              <a className="btn-dark" href={methodiek0} download>
                <span className="btn-text">Download andere methodieken</span>
                <i className="icon-arrow-down-1"></i>
              </a>
            </div>
          </aside>

          <section>
            <h2 className="theoryPage__subtitle" id="bijdragen">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[2].title,
                  });
                }}
              />
              {subchapters[2].title}{" "}
            </h2>

            <h3 class="theoryPage__sub-subtitle">Actie bedenken en uitwerken</h3>

            <p className="theoryPage__text">
              In deze fase gaan leerlingen creatief aan de slag en ontwerpen ze acties die een duurzame toekomst mogelijk maken.  Er worden uiteenlopende ideeën bedacht, gecombineerd en omgetoverd tot een haalbare actie die ze effectief kunnen uitvoeren.  
            </p>
          </section>

          <aside>
            <h3 className="theory__sub-subtitle theory__sub-subtitle--center">Methodiek: Creatief denken – out of the box</h3>
            <p className="theoryPage__text">
              Op basis van de geformuleerde noden gaan de leerlingen over tot het bedenken en uitwerken van concrete acties. Aan de hand van deze methodiek worden ze gestimuleerd om ‘out of the box’ te denken en zo tot diverse acties te komen. Elke leerling krijgt hiervoor een blad dat in 4 gelijke vakken verdeeld is. Per vakje krijgen ze 1 minuut om een idee te tekenen of uit te werken. Deze manier van creatief denken betekent dat de leerlingen heel diverse ideeën gaan bedenken. Ook ‘zotte’ ideeën zijn hier op hun plaats. In een vervolgfase worden deze ideeën geanalyseerd en gecombineerd in functie van haalbaarheid.  
            </p>
            <div className="btn-wrapper btn-wrapper--project">
              <a className="btn-dark" href={methodiek5} download>
                <span className="btn-text">Download deze methodiekfiche</span>
                <i className="icon-arrow-down-1"></i>
              </a>
              <a className="btn-dark" href={methodiek0} download>
                <span className="btn-text">Download andere methodieken</span>
                <i className="icon-arrow-down-1"></i>
              </a>
            </div>
          </aside>

          <section>
            <h3 class="theoryPage__sub-subtitle">Actie uittesten en bijsturen</h3>

            <p className="theoryPage__text">
              Het is belangrijk dat leerlingen niet alleen acties bedenken, maar ook onderzoeken wat de haalbaarheid of de impact ervan is. Je stimuleert hen om erop uit te trekken en hun acties effectief uit te testen. Door hier samen over te reflecteren, kunnen leerlingen hun acties evalueren en verder bijsturen. 
            </p>
          </section>

          <aside>
            <h3 className="theory__sub-subtitle theory__sub-subtitle--center">Methodiek: Feedback verzamelen a.d.h.v. een bevraging</h3>
            <p className="theoryPage__text">
              In het kader van de mobiliteitsweek werken de leerlingen verschillende acties uit om medeleerlingen te laten nadenken over duurzame mobiliteit en alternatieve manieren om zich naar school te verplaatsen (met de fiets, openbaar vervoer, carpoolen, …). Aan het einde van de week houden ze een mondelinge bevraging bij medeleerlingen, leerkrachten en ouders om feedback te verzamelen over hun acties. Ze bespreken de uitkomst van deze bevraging in de klas: hoe hebben de verschillende betrokkenen de acties ervaren? Wat werd er als sterk ervaren? Welke suggesties en verbeterpunten zijn er? Aan de hand hiervan kunnen ze hun toekomstige acties verder bijsturen. 
            </p>
            <div className="btn-wrapper btn-wrapper--project">
              <a className="btn-dark" href={methodiek6} download>
                <span className="btn-text">Download deze methodiekfiche</span>
                <i className="icon-arrow-down-1"></i>
              </a>
              <a className="btn-dark" href={methodiek0} download>
                <span className="btn-text">Download andere methodieken</span>
                <i className="icon-arrow-down-1"></i>
              </a>
            </div>
          </aside>

          <section>
            <h2 className="theoryPage__subtitle" id="terugblik">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[3].title,
                  });
                }}
              />
              {subchapters[3].title}{" "}
            </h2>

            <p className="theoryPage__text">
              Op het einde van het EDO-project voorzie je voldoende tijd om samen met de leerlingen terug te blikken op de verschillende stappen die je hebt doorlopen. Ze kunnen hierbij hun ervaringen en eigen acties delen met anderen. Leerlingen krijgen appreciatie voor wat ze verwezenlijkt hebben en reflecteren over hun leerproces. Leerlingen formuleren wat ze meenemen uit hun onderzoek en hoe ze dit in de toekomst willen toepassen. Het EDO-leren wordt hier a.h.w. vastgezet. Zo krijgen de leerlingen zicht op hun leren en kun je samen de doelen evalueren.  
            </p>
          </section>

          <aside>
            <h3 className="theory__sub-subtitle theory__sub-subtitle--center">Methodiek: Posterbeurs</h3>
            <p className="theoryPage__text">
              De leerlingen sluiten het EDO-project af met een posterbeurs. Niet alleen medeleerlingen, maar ook ouders, buurtbewoners en leerkrachten van nabijgelegen scholen worden uitgenodigd. In kleine groepjes maken ze een poster over verschillende aspecten van het EDO-project: wat is de impact van mobiliteit op onze planeet, op de mensen, onze economie, …; wat zijn voorbeelden van duurzame mobiliteit; welke acties hebben ze bedacht en uitgevoerd; een overzicht van de resultaten van de bevraging van de mobiliteitsweek. Tijdens de posterbeurs krijgt elk groepje de kans om hun inzichten te delen, ze vertellen ook wat bij hen is blijven hangen na het EDO-project en hoe ze dit meenemen in toekomstige projecten. 
            </p>
            <div className="btn-wrapper btn-wrapper--project">
              <a className="btn-dark" href={methodiek7} download>
                <span className="btn-text">Download deze methodiekfiche</span>
                <i className="icon-arrow-down-1"></i>
              </a>
              <a className="btn-dark" href={methodiek0} download>
                <span className="btn-text">Download andere methodieken</span>
                <i className="icon-arrow-down-1"></i>
              </a>
            </div>
          </aside>

          <nav className="navbar-bottom">
            <Link
              className="navbar-bottom__link navbar-bottom__back btn-light"
              to={chapters[2].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <i className="icon-chevron-left"></i>
              <span className="btn-text">{chapters[2].title}</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/project/introductie"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__next btn-light"
              to={chapters[4].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-text">{chapters[4].title}</span>
              <i className="icon-chevron-right"></i>
            </Link>
          </nav>
        </main>
      </div>
    );
  }
}

export default ProjectUitwerken;
