import React, { Component } from "react";
import { Link } from "react-router-dom";

import { HashLink } from "react-router-hash-link";
import { Waypoint } from "react-waypoint";
import grid from "../../../assets/icons/algemeen/grid.png";

class Terugblikken extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubtitle: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const chapters = this.props.chapters;
    const subchapters = [
      {
        title: "Terugblik op het EDO-project",
        url: "#terugblik",
        alt: "Terugblik op het EDO-project",
      },
    ];

    return (
      <div id="didactische-concepten" className="theoryPage">
        <nav className="navbar navbar-left">
          <a
            className="navbar-toggler navbar-toggler--project collapsed"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Inhoudstafel <i class="icon-chevron-down"></i>
          </a>
          <div className="collapse navbar-collapse" id="navbarNav">
            {" "}
            <Link
              to={chapters[0].url}
              className="navbar-left__item navbar-left__item--project "
            >
              {chapters[0].title}
            </Link>
            <Link
              to={chapters[1].url}
              className="navbar-left__item navbar-left__item--project "
            >
              {chapters[1].title}
            </Link>
            <Link
              to={chapters[2].url}
              className="navbar-left__item navbar-left__item--project "
            >
              {chapters[2].title}
            </Link>
            <Link
              to={chapters[3].url}
              className="navbar-left__item navbar-left__item--project "
            >
              {chapters[3].title}
            </Link>
            <Link
              to={chapters[4].url}
              className="navbar-left__item navbar-left__item--project "
            >
              {chapters[4].title}
            </Link>
            <Link
              to={chapters[5].url}
              className="navbar-left__item navbar-left__item--project navbar-left__item--active"
            >
              {chapters[5].title}
            </Link>
          </div>
        </nav>
        <main>
          <section>
            <h1 className="theoryPage__title">{chapters[5].title}</h1>

            <p class="theoryPage__text">
                EDO is een complex leerproces dat oefening en bijsturing vraagt voor zowel jezelf als leerkracht, als voor de leerlingen. Neem voldoende tijd om de aanpak en het verloop van het EDO-project te evalueren. Sta even stil bij de uitwerking van het EDO-project (implementatie van het didactische concept), welke doelen je al dan niet hebt geëvalueerd, waar je tegenaan bent gelopen bij de begeleiding en hoe de samenwerking met collega’s en eventueel externen is verlopen.   
            </p>

            <p class="theoryPage__text">
                Bereid deze reflectie voor a.d.h.v. onderstaande vragen en deel ze met collega's, die liefst ook betrokken waren in het EDO-project. Eventueel kan je dit ook aanbrengen op een teamoverleg, op deze manier kan je anderen inspireren of ondersteunen bij het ontwikkelen van nieuwe EDO-projecten. 
            </p>
          </section>

          <aside class="theoryPage__table theoryPage__table--project">
              <div>
                <h3 className="theory__sub-subtitle">Terugblik op de didactische aanpak</h3>
                <ul className="theoryPage__text theoryPage__ul">
                    <li>Hoe heb je het proces aangepakt?</li>
                    <li>Bekijk de fasering van het didactisch concept: wat zou je behouden of wat zou je anders aanpakken? </li> 
                    <li>Hoe hebben de leerlingen het EDO-project ervaren? Hoe evalueren zij het EDO-project, wat vinden zij dat ze hieruit geleerd hebben? </li>
                    <li>Welke ondersteuning kan je helpen?</li>
                    <li>Hoe sluit het aan bij andere vakken?</li>
                </ul>
              </div>

              <div>
                <h3 className="theory__sub-subtitle">Terugblik op de doelenselectie en focus</h3>
                <ul className="theoryPage__text theoryPage__ul">
                    <li>Welke doelen heb je vooropgesteld en welke heb je geëvalueerd?</li>
                    <li>Welke werkvormen heb je hiervoor gebruikt?</li> 
                    <li>Hoe heb je leerlingen inzicht gegeven in hun eigen leerproces?</li>
                    <li>Hoe past wat dit project binnen de schoolvisie en/of leerlijn?</li>
                    <li>Formuleer voor jezelf het antwoord op de vraag waarom je met dit project hebt gewerkt aan actiecompetentie.</li>
                    <li>Wat heeft het EDO-project teweeg gebracht? Is er iets veranderd in de klas, op school, in de buurt of veraf?</li>
                </ul>
              </div>

              <div>
                <h3 className="theory__sub-subtitle">Terugblik op de begeleiding</h3>
                <ul className="theoryPage__text theoryPage__ul">
                    <li>Welke rol nam je op als begeleider? Was je eerder moderator, mede-onderzoeker, motivator of … ?</li>
                    <li>Wat blijft je bij als begeleider van een EDO-project? Wat zou je anders aanpakken?</li> 
                    <li>Wat heeft je veranderd als leerkracht?</li>
                    <li>Hoe verliep de samenwerking met de leerlingen?</li>
                </ul>
              </div>

              <div>
                <h3 className="theory__sub-subtitle">Terugblik op de samenwerking</h3>
                <ul className="theoryPage__text theoryPage__ul">
                    <li>Hoe verliep de samenwerkingen met andere leerkrachten en externen?</li>
                    <li>Welke nieuwe externe partners heb je leren kennen?</li> 
                    <li>In welke mate heb je ouders betrokken? Hebben leerlingen thuis verteld over het EDO-project?</li>
                    <li>Wat liep goed? Wat liep minder goed in de samenwerking? Hoe kan je dit een volgende keer anders aanpakken?</li>
                </ul>
              </div>
            
          </aside>

          <section>
          </section>
          <nav className="navbar-bottom">
            <Link
              className="navbar-bottom__link navbar-bottom__back btn-light"
              to={chapters[4].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <i className="icon-chevron-left"></i>
              <span className="btn-text">{chapters[4].title}</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/project/introductie"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>

            <div className="navbar-bottom__link navbar-bottom__next btn-light btn-light--hoverless"></div>
          </nav>
        </main>
      </div>
    );
  }
}

export default Terugblikken;
