import React, { Component } from "react";
import { Link } from "react-router-dom";
import asset1 from "../../../assets/imgs/project/introductie/didactische_concepten.svg";
import { HashLink } from "react-router-hash-link";
import { Waypoint } from "react-waypoint";
import grid from "../../../assets/icons/algemeen/grid.png";


class DidactischeConcepten extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubtitle: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const chapters = this.props.chapters;
    const subchapters = [
      {
        title: "Uitdaging",
        url: "#uitdaging",
        alt: "",
      },
      {
        title: "Verkennen",
        url: "#verkennen",
        alt: "",
      },
      {
        title: "Bijdragen",
        url: "#bijdragen",
        alt: "",
      },
      {
        title: "Terugblik",
        url: "#terugblik",
        alt: "",
      },
    ];

    return (
      <div id="didactische-concepten" className="theoryPage">
        <nav className="navbar navbar-left">
          <a
            className="navbar-toggler navbar-toggler--project collapsed"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Inhoudstafel <i class="icon-chevron-down"></i>
          </a>
          <div className="collapse navbar-collapse" id="navbarNav">
            {" "}
            <Link
              to={chapters[0].url}
              className="navbar-left__item navbar-left__item--project "
            >
              {chapters[0].title}
            </Link>
            <Link
              to={chapters[1].url}
              className="navbar-left__item navbar-left__item--project navbar-left__item--active"
            >
              {chapters[1].title}
            </Link>
            <HashLink
              smooth
              to={subchapters[0].url}
              className={`navbar-left__subitem navbar-left__subitem--project ${
                this.state.activeSubtitle === subchapters[0].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[0].title}
            </HashLink>
            <HashLink
              smooth
              to={subchapters[1].url}
              className={`navbar-left__subitem navbar-left__subitem--project ${
                this.state.activeSubtitle === subchapters[1].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[1].title}
            </HashLink>
            <HashLink
              smooth
              to={subchapters[2].url}
              className={`navbar-left__subitem navbar-left__subitem--project ${
                this.state.activeSubtitle === subchapters[2].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[2].title}
            </HashLink>
            <HashLink
              smooth
              to={subchapters[3].url}
              className={`navbar-left__subitem navbar-left__subitem--project ${
                this.state.activeSubtitle === subchapters[3].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[3].title}
            </HashLink>
            <Link
              to={chapters[2].url}
              className="navbar-left__item navbar-left__item--project"
            >
              {chapters[2].title}
            </Link>
            <Link
              to={chapters[3].url}
              className="navbar-left__item navbar-left__item--project"
            >
              {chapters[3].title}
            </Link>
            <Link
              to={chapters[4].url}
              className="navbar-left__item navbar-left__item--project"
            >
              {chapters[4].title}
            </Link>
            <Link
              to={chapters[5].url}
              className="navbar-left__item navbar-left__item--project"
            >
              {chapters[5].title}
            </Link>
          </div>
        </nav>
        <main>
          <section>
            <h1 className="theoryPage__title">{chapters[1].title}</h1>

            <p className="theoryPage__text theoryPage__text--bold">
              Om leerlingen te laten groeien in hun actiecompetentie vereist een EDO-project een gefaseerde en doordachte aanpak. In de figuur hieronder kan een voorbeeld van een didactisch concept vinden. Deze aanpak is gebaseerd op ‘design thinking’, een geschikte methode om aan de slag te gaan met complexe uitdagingen. Door een uitdaging te verkennen, kan je onderzoeken hoe je kan bijdragen aan een duurzame wereld.  
            </p>
          </section>

          <img
            className="theory__asset"
            src={asset1}
            alt={chapters[1].alt}
          ></img>

          <section>
            <h2 className="theoryPage__subtitle" id="uitdaging">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[0].title,
                  });
                }}
              />
              {subchapters[0].title}
            </h2>

            <p className="theoryPage__text">
              Een EDO-project vertrekt vanuit een uitdaging, dit is een maatschappelijke kwestie of een duurzaamheidsprobleem die gelinkt is aan de leefwereld van de leerlingen. Als leerkracht ben je alert voor wat er leeft in de klas, op school, in de buurt, in de samenleving. Dit zijn aanknopingspunten om een EDO-project op te starten. 
            </p>

            <h2 className="theoryPage__subtitle" id="verkennen">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[1].title,
                  });
                }}
              />
              {subchapters[1].title} {" "}
            </h2>

            <p className="theoryPage__text">
              In een EDO-project leven leerlingen zich in, zodat ze goed begrijpen waarover het duurzaamheidsprobleem gaat. Hierbij gaan ze de uitdaging in zijn complexiteit verkennen door linken te leggen met diverse invalhoeken. Ze onderzoeken hoe dit leeft bij zichzelf en anderen. 
            </p>

            <h3 className="theoryPage__sub-subtitle">
              Inleven in de uitdaging
            </h3>

            <h4>
              Waar gaat het over?
            </h4>

            <p className="theoryPage__text">
              Duurzaamheidskwesties zijn complexe problemen die je niet zomaar kan oplossen. Leerlingen nemen de tijd om de uitdaging vanuit <b>verschillende invalshoeken</b> te benaderen: ‘Waar gaat het over?', ‘Wat zijn oorzaken en gevolgen?’, ‘Wie of wat is er betrokken bij dit duurzaamheidsprobleem (mensen, dieren, het leefmilieu, …)?’, …  
            </p>

            <p className="theoryPage__text">
              Leerlingen gaan <b>systeemdenken</b>. Ze plaatsen het thema in een groter geheel, waarbij ze zoveel mogelijk elementen in kaart proberen te brengen. 
            </p>

            <h4>
              Elk zijn mening!  
            </h4>

            <p className="theoryPage__text">
              Leerlingen stellen zich de vraag wat hun plaats is en die van anderen binnen het systeem: ‘Ben je consument?’, ‘Ken je iemand die betrokken is bij de productie?’, ‘Welke keuzes maken jij en je ouders?’, … Dit roept verschillende <b>waarden en emoties</b> op. Leerlingen verwoorden wat deze inzichten met ze doen. 
            </p>

            <p className="theoryPage__text">
              Duurzaamheidsproblemen roepen heel wat verschillende meningen op. Elke persoon zal immers vanuit een ander perspectief of waardenkader naar het probleem kijken: <i>“Ik heb hier geen tijd voor!”, “Er moet iets gebeuren!”, “Het kan mij niet schelen!”</i>.  Door <b>verschillende perspectieven</b> aan bod te laten komen leren de leerlingen niet alleen op een respectvolle manier in dialoog te gaan met anderen, maar worden ze ook uitgedaagd om hun eigen mening in vraag te stellen.  
            </p>

            <h3 className="theoryPage__sub-subtitle">
              Probleem goed begrijpen
            </h3>

            <p className="theoryPage__text">
              De leerlingen hebben inmiddels al heel wat informatie verzameld, zoals: ‘Waar gaat het eigenlijk over?’, ‘Wat doet het met mij?’, ‘Wat zijn de verschillende meningen hierover?’. Dit biedt een veelheid aan informatie waarin je soms jezelf kan verliezen of het bos door de bomen niet meer ziet. Dit leidt tot reacties als: <i>“Hoe kan ik hier nu iets aan veranderen? Al die verschillende mensen en meningen die hiermee te maken hebben, we kunnen hier zelf toch niets aan doen?”</i> of <i>“Het is aan de politiek/overheid om er iets aan te doen!”</i>.  
            </p>

            <p className="theoryPage__text">
              Het is in deze fase belangrijk dat je leerlingen inzichten geeft in hoe ze zelf impact hebben op het duurzaamheidsprobleem, door bv. een link te leggen met de eigen leefwereld. Op basis hiervan kunnen ze een haalbare nood formuleren waar ze daadwerkelijk hun schouders kunnen onder zetten. 
            </p>

            <h2 className="theoryPage__subtitle" id="bijdragen">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[2].title,
                  });
                }}
              />
              {subchapters[2].title}{" "}
            </h2>

            <p className="theoryPage__text">
              Aan de slag! In deze fase ontwerpen leerlingen acties die een duurzame toekomst mogelijk maken.  Er worden meerdere ideeën bedacht, gecombineerd en omgetoverd tot een haalbare actie die je effectief kan uitvoeren. Is de actie haalbaar en heeft ze een impact op het systeem? Dan dien je ze natuurlijk uit te testen! Mislukt de actie? Dan maken we het ontwerp gewoon beter. 
            </p>

            <h3 className="theoryPage__sub-subtitle">
              Actie bedenken en uitwerken 
            </h3>

            <p className="theoryPage__text">
              Leerlingen gaan creatief aan de slag. Ze onderzoeken welke acties reeds plaatsvinden, en bedenken zelf verschillende mogelijkheden. Een actie is een handeling die je kan uittesten in de eigen omgeving. Acties hoeven niet altijd allesomvattend te zijn. Ook kleine handelingen of gedragsaanpassingen kunnen bijdragen aan een duurzamere wereld, denk bv. aan een gesprek met je buur, een methode om thuis of op school minder water te gebruiken, een bewustwordingscampagne voor medeleerlingen, een lokale koopwijzer, een brief aan de burgemeester, …  
            </p>

            <p className="theoryPage__text">
              Leerlingen bedenken niet enkel acties, maar onderzoeken eveneens welk effect deze heeft in de eigen leefwereld. Leerlingen trekken erop uit en testen of hun actie effectief werkt. Heeft het de gewenste impact? Wat brengt het teweeg? Hoe wordt er gereageerd? Door observaties, reacties en ervaringen uit te wisselen evalueren de leerlingen hun actie en sturen deze verder bij. 
            </p>

            <h2 className="theoryPage__subtitle" id="terugblik">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[3].title,
                  });
                }}
              />
              {subchapters[3].title}{" "}
            </h2>

            <p className="theoryPage__text">
              Wat hebben we geleerd? Wat nemen we mee uit het EDO-project ?  
            </p>

            <p className="theoryPage__text">
              Terugblikken in een EDO- project betekent dat je de eigen acties deelt met anderen. Leerlingen oefenen in het communiceren over veranderingsprocessen aan de hand van een concrete actie: Welke impact heeft het duurzaamheidsprobleemprobleem op de leerling, de school, de buurt of de wereld? Hoe hebben ze hun actie bedacht en uitgetest? Welke keuzes hebben ze gemaakt? Kortom tijd voor vieren, delen en reflecteren! 
            </p>

            <p className="theoryPage__text">
              Daarnaast krijgen leerlingen ook inzicht in hun talenten en hoe ze deze hebben ingezet. Door in groep te leren en samen te werken krijgen leerlingen inzicht in hoe ze elkaar ondersteunen: zwaktes van de ene worden gecompenseerd door de sterktes van de ander en omgekeerd. Kortom, ze ondervinden dat als ze in groep sterk staan, ze heel wat kunnen realiseren. Dit doet het vertrouwen in de eigen capaciteiten groeien. 
            </p>

          </section>
          <nav className="navbar-bottom">
            <Link
              className="navbar-bottom__link navbar-bottom__back btn-light"
              to={chapters[0].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <i className="icon-chevron-left"></i>
              <span className="btn-text">{chapters[0].title}</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/project/introductie"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__next btn-light"
              to={chapters[2].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-text">{chapters[2].title}</span>
              <i className="icon-chevron-right"></i>
            </Link>
          </nav>
        </main>
      </div>
    );
  }
}

export default DidactischeConcepten;
