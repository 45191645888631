import React, { Component } from "react";
import { Route, Switch } from "react-router";

import HeaderPage from "../../../components/HeaderPage";
import HeaderPageMobile from "../../../components/HeaderPageMobile";

import OverzichtProject from "./OverzichtProject";
import HoezoEdoProject from "./HoezoEdoProject";
import DidactischeConcepten from "./DidactischeConcepten";
import ProjectOpstarten from "./ProjectOpstarten";
import ProjectUitwerken from "./ProjectUitwerken";
import ProjectBegeleiden from "./ProjectBegeleiden";
import Terugblikken from "./Terugblikken";

class IntroductieProject extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = this.props.chapters;

    const subchapters = [
      {
        number: 1,
        title: "Hoezo een EDO-project?",
        url: "/project/introductie/hoezo-edo-project",
        subchapters: [
          {
            title: "EDO vraagt een geïntegreerde aanpak",
            url: "#edo-vraagt-een-geintegreerde-aanpak",
          },
          {
            title: "De meerwaarde van projectwerk",
            url: "#meerwaarde-van-projectwerk",
          },
          {
            title: "Een voorbeeld met ... extra kansen!",
            url: "#voorbeelden-met-extra-kansen",
          },
        ],
      },
      {
        number: 2,
        title: "Didactische concepten",
        url: "/project/introductie/didactische-concepten",
        subchapters: [
          {
            title: "titel 1",
            url: "#titel1",
          },
          {
            title: "titel 2",
            url: "#titel2",
          },
          {
            title: "titel 3",
            url: "#titel3",
          },
        ],
      },
      {
        number: 3,
        title: "Project opstarten",
        url: "/project/introductie/project-opstarten",
        subchapters: [
          {
            title: "titel 1",
            url: "#titel1",
          },
          {
            title: "titel 2",
            url: "#titel2",
          },
          {
            title: "titel 3",
            url: "#titel3",
          },
        ],
      },
      {
        number: 4,
        title: "Project uitwerken",
        url: "/project/introductie/project-uitwerken",
        subchapters: [
          {
            title: "titel 1",
            url: "#titel1",
          },
          {
            title: "titel 2",
            url: "#titel2",
          },
          {
            title: "titel 3",
            url: "#titel3",
          },
        ],
      },
      {
        number: 5,
        title: "Project begeleiden",
        url: "/project/introductie/project-begeleiden",
        subchapters: [
          {
            title: "titel 1",
            url: "#titel1",
          },
          {
            title: "titel 2",
            url: "#titel2",
          },
          {
            title: "titel 3",
            url: "#titel3",
          },
        ],
      },
      {
        number: 6,
        title: "Terugblikken",
        url: "/project/introductie/terugblikken",
        subchapters: [
          {
            title: "titel 1",
            url: "#titel1",
          },
          {
            title: "titel 2",
            url: "#titel2",
          },
          {
            title: "titel 3",
            url: "#titel3",
          },
        ],
      },
    ];
    return (
      <div>
        <HeaderPage
          type={"project"}
          chapters={chapters}
          activeChapter={1}
        ></HeaderPage>
        <HeaderPageMobile
          type={"project"}
          chapters={chapters}
          activeChapter={1}
        ></HeaderPageMobile>
        <Switch>
          <Route path={["/project/introductie/hoezo-edo-project"]} exact>
            <HoezoEdoProject chapters={subchapters} />
          </Route>
          <Route path={["/project/introductie/didactische-concepten"]} exact>
            <DidactischeConcepten chapters={subchapters} />
          </Route>
          <Route path={["/project/introductie/project-opstarten"]} exact>
            <ProjectOpstarten chapters={subchapters} />
          </Route>
          <Route path={["/project/introductie/project-uitwerken"]} exact>
            <ProjectUitwerken chapters={subchapters} />
          </Route>
          <Route path={["/project/introductie/project-begeleiden"]} exact>
            <ProjectBegeleiden chapters={subchapters} />
          </Route>
          <Route path={["/project/introductie/terugblikken"]} exact>
            <Terugblikken chapters={subchapters} />
          </Route>
          <Route
            path={["/project/introductie/overzicht", "/project/introductie"]}
          >
            <OverzichtProject subchapters={subchapters} />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default IntroductieProject;
