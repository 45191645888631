import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import HeaderPage from "../../components/HeaderPage";
import NavigationBottom from "../../components/NavigationBottom";
import IntroductiePrikkel from "./introductie/IntroductiePrikkel";
import PraktijkPrikkel from "./praktijk/PraktijkPrikkel";
import VormingsmateriaalPrikkel from "./vormingsmateriaal/VormingsmateriaalPrikkel";
import PrikkelShape from "../../assets/icons/prikkel/hero-image_prikkel.svg";
import HeaderPageMobile from "../../components/HeaderPageMobile";
import { HashLink } from "react-router-hash-link";

class PrikkelHome extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = [
      {
        number: 1,
        chapterName: "Een introductie",
        chapterUrl: "/prikkel/introductie",
      },
      {
        number: 2,
        chapterName: "Prikkels in de praktijk",
        chapterUrl: "/prikkel/praktijk",
      },
      {
        number: 3,
        chapterName: "Vormingsmateriaal",
        chapterUrl: "/prikkel/vormingsmateriaal",
      },
    ];

    return (
      <div>
        <Switch>
          <Route
            path={["/prikkel"]}
            exact
            render={() => (
              <div>
                <HeaderPage
                  type={"prikkel"}
                  chapters={chapters}
                  activeChapter={0}
                ></HeaderPage>
                <HeaderPageMobile
                  type={"prikkel"}
                  chapters={chapters}
                  activeChapter={0}
                ></HeaderPageMobile>
                <main id="prikkel-home">
                  <div className="overview-top">
                    <div className="overview-top__info">
                      <h2 className="overview-top__title">
                        Maak kennis met de kracht van Educatie voor Duurzame
                        Ontwikkeling
                      </h2>
                      <HashLink className="btn-dark" to="#verken-prikkel">
                        <span className="btn-text">Verken</span>
                        <i className="icon-chevron-right"></i>
                      </HashLink>{" "}
                    </div>
                    <img
                      className="overview-top__shape overview-top__shape--prikkel"
                      src={PrikkelShape}
                      alt="Prikkel vorm"
                    ></img>
                  </div>
                  <section className="overview-chapter1" id="verken-prikkel">
                    <div className="overview-chapter__info">
                      <h4 className="overview-chapter__subtitle prikkel-color">
                        Prikkel
                      </h4>
                      <h2 className="overview-chapter__title">
                        {chapters[0].chapterName}
                      </h2>
                      <p className="overview-chapter__description">
                        Heb je nood aan een duidelijke afbakening van EDO? Ben
                        je op zoek naar EDO thema’s die je met leerlingen kan
                        verkennen? Wil je in je klas kleinschalig proeven van
                        EDO?
                      </p>
                      <Link className="btn-light" to="/prikkel/introductie">
                        <span className="btn-text">Maak kennis met EDO</span>
                        <i className="icon-chevron-right"></i>
                      </Link>{" "}
                    </div>
                    <div className="overview-chapter__img overview-chapter__img--1"></div>
                  </section>
                  <section className="overview-chapter2 overview-chapter--mirrored">
                    <div className="overview-chapter__info">
                      <h4 className="overview-chapter__subtitle prikkel-color">
                        Prikkel
                      </h4>
                      <h2 className="overview-chapter__title">
                        {chapters[1].chapterName}
                      </h2>
                      <p className="overview-chapter__description">
                        Laat je inspireren door prikkelende voorbeelden uit de
                        praktijk. Krijg zicht op hoe je de EDO-principes in de
                        praktijk brengt en hiermee aan actiecompetentie van
                        leerlingen werkt.
                      </p>
                      <Link className="btn-light" to="/prikkel/praktijk">
                        <span className="btn-text">Lees de verhalen</span>
                        <i className="icon-chevron-right"></i>
                      </Link>{" "}
                    </div>
                    <div className="overview-chapter__img overview-chapter__img--2"></div>
                  </section>
                  <section className="overview-chapter3">
                    <div className="overview-chapter__info">
                      <h4 className="overview-chapter__subtitle prikkel-color">
                        Prikkel
                      </h4>
                      <h2 className="overview-chapter__title">
                        {chapters[2].chapterName}
                      </h2>
                      <p className="overview-chapter__description">
                        Ga zelf aan de slag met materiaal om EDO te leren
                        kennen, vertrouwd te worden met de EDO-principes of
                        anderen te inspireren.
                      </p>
                      <Link
                        className="btn-light"
                        to="/prikkel/vormingsmateriaal"
                      >
                        <span className="btn-text">Download materiaal</span>
                        <i className="icon-chevron-right"></i>
                      </Link>{" "}
                    </div>
                    <div className="overview-chapter__img overview-chapter__img--3"></div>
                  </section>
                </main>
              </div>
            )}
          ></Route>
          <Route path={["/prikkel/introductie"]}>
            <IntroductiePrikkel chapters={chapters}></IntroductiePrikkel>
          </Route>
          <Route path={["/prikkel/praktijk"]}>
            <PraktijkPrikkel chapters={chapters}></PraktijkPrikkel>
          </Route>
          <Route path={["/prikkel/vormingsmateriaal"]}>
            <VormingsmateriaalPrikkel
              chapters={chapters}
            ></VormingsmateriaalPrikkel>
          </Route>
        </Switch>

        <NavigationBottom type={"prikkel"}></NavigationBottom>
        <Footer type={"prikkel"}></Footer>
      </div>
    );
  }
}

export default PrikkelHome;
