import React, { Component } from "react";
import { Link } from "react-router-dom";
import asset1 from "../../../assets/icons/school/edo-effectieve-school@2x.png";
import iconPluralistisch from "../../../assets/icons/school/pluralistische-communicatie.svg";
import iconRelaties from "../../../assets/icons/school/ondersteunende-relaties.svg";
import iconCollectief from "../../../assets/icons/school/collectieve-effectiviteit.svg";
import iconAanpasbaarheid from "../../../assets/icons/school/aanpasbaarheid.svg";
import iconDemocratie from "../../../assets/icons/school/democratische-besluitvorming.svg";
import iconVisie from "../../../assets/icons/school/gedeelde-visie.svg";
import { HashLink } from "react-router-hash-link";
import { Waypoint } from "react-waypoint";
import grid from "../../../assets/icons/algemeen/grid.png";

class EffectieveSchool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubtitle: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const chapters = this.props.chapters;
    const subchapters = [
      {
        title: "Door een bril kijken naar je eigen school",
        url: "#bril",
        alt: "Iconen van de Door een bril kijken naar je eigen school.",
      },
      {
        title: "Pluralistische communicatie",
        url: "#pluralistisch",
        alt: "Schema van pluralistische communicatie.",
      },
      {
        title: "Ondersteunende relaties",
        url: "#relaties",
        alt: "Schema van wat Duurzame Ontwikkeling is.",
      },
      {
        title: "Aanpasbaarheid",
        url: "#aanpasbaarheid",
        alt: "Iconen van de Duurzame Ontwikkelingsdoelen.",
      },
      {
        title: "Democratische besluitvorming",
        url: "#democratie",
        alt: "Iconen van de Duurzame Ontwikkelingsdoelen.",
      },
      {
        title: "Gedeelde visie",
        url: "#visie",
        alt: "Iconen van de Gedeelde visie.",
      },
      {
        title: "Collectieve effectiviteit",
        url: "#collectief",
        alt: "Iconen van de Duurzame Ontwikkelingsdoelen.",
      },
    ];

    return (
      <div id="daarom-edo" className="theoryPage">
        <nav className="navbar navbar-left">
          <a
            className="navbar-toggler navbar-toggler--school collapsed"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Inhoudstafel <i class="icon-chevron-down"></i>
          </a>

          <div className="collapse navbar-collapse" id="navbarNav">
            <Link
              to={chapters[0].url}
              className="navbar-left__item  navbar-left__item--school navbar-left__item--active "
            >
              {chapters[0].title}
            </Link>

            <HashLink
              smooth
              to={subchapters[0].url}
              className={`navbar-left__subitem navbar-left__subitem--school ${
                this.state.activeSubtitle === subchapters[0].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[0].title}
            </HashLink>

            <HashLink
              smooth
              to={subchapters[1].url}
              className={`navbar-left__subitem navbar-left__subitem--school ${
                this.state.activeSubtitle === subchapters[1].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[1].title}
            </HashLink>

            <HashLink
              smooth
              to={subchapters[2].url}
              className={`navbar-left__subitem navbar-left__subitem--school ${
                this.state.activeSubtitle === subchapters[2].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[2].title}
            </HashLink>

            <HashLink
              smooth
              to={subchapters[3].url}
              className={`navbar-left__subitem navbar-left__subitem--school ${
                this.state.activeSubtitle === subchapters[3].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[3].title}
            </HashLink>

            <HashLink
              smooth
              to={subchapters[4].url}
              className={`navbar-left__subitem navbar-left__subitem--school ${
                this.state.activeSubtitle === subchapters[4].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[4].title}
            </HashLink>

            <HashLink
              smooth
              to={subchapters[5].url}
              className={`navbar-left__subitem navbar-left__subitem--school ${
                this.state.activeSubtitle === subchapters[5].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[5].title}
            </HashLink>

            <HashLink
              smooth
              to={subchapters[6].url}
              className={`navbar-left__subitem navbar-left__subitem--school  ${
                this.state.activeSubtitle === subchapters[6].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[6].title}
            </HashLink>

            <Link
              to={chapters[1].url}
              className="navbar-left__item navbar-left__item--school "
            >
              {chapters[1].title}
            </Link>
          </div>
        </nav>

        <main>
          <section>
            <h1 className="theoryPage__title">
              Wat is een EDO- effectieve school?
            </h1>

            <p className="theoryPage__text theoryPage__text--bold">
              Educatie voor duurzame ontwikkeling (EDO) wordt pas echt krachtig
              als je er met het hele schoolteam in slaagt om een efficiënt,
              duurzaam en bovenal effectief schoolbeleid uit te tekenen voor
              EDO. Het beleid van een school creëert immers de
              organisatiecontext waarin leerkrachten en leerlingen samen aan de
              slag gaan om te leren over hoe we met z’n allen werk kunnen maken
              van duurzame ontwikkeling. Maar hoe kan je als school EDO nu een
              plaats geven in het schoolbeleid?
            </p>

            <p className="theoryPage__text">
              Net zoals de edo-principes (onderzoekend, samen en actiegericht)
              in de klas van tel zijn, zullen de kenmerken van een
              edo-effectieve school een rol spelen bij het schoolbeleid. Een
              effectief schoolbeleid gaat uit van een{" "}
              <span className="theory__text-emphasis--bold">onderzoekende</span>{" "}
              manier van werken waarbij{" "}
              <span className="theory__text-emphasis--bold">samen</span> wordt
              gekeken hoe het beleid{" "}
              <span className="theory__text-emphasis--bold">actiegericht</span>{" "}
              kan inzetten op EDO.
            </p>

            <p className="theoryPage__text">
              De kenmerken van een edo- effectieve school hangen nauw samen.
              Bijvoorbeeld, hoe er gesproken wordt op de school kan invloed
              hebben op de manier van besluitvorming. Inzetten op één van deze
              kenmerken komt vaak ten gunste van de andere kenmerken:{" "}
              <span className="theory__text-emphasis--bold">
                ze hangen immers allemaal samen
              </span>
              . De opsplitsing helpt om als school zicht te krijgen op sterktes
              en zwaktes en om prioriteiten te leggen richting een EDO-
              effectieve school.
            </p>

            <h2 className="theoryPage__subtitle" id="bril">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[0].title,
                  });
                }}
              />

              <span className="theory__title-emphasis">
                {subchapters[0].title}
              </span>
            </h2>

            <p className="theoryPage__text">
              Inzetten op de kenmerken van een EDO-effectieve school doe je via
              de bril van duurzaamheid op vlak van ‘leiderschap’ en ‘middelen
              van de school’.
            </p>
          </section>

          <img
            className="theory__asset"
            src={asset1}
            alt={chapters[0].alt}
          ></img>

          <section>
            <h3 className="theoryPage__sub-subtitle">Duurzaam leiderschap</h3>

            <p className="theoryPage__text">
              Duurzaam leiderschap zoekt een evenwicht in wat vroeger goed ging,
              vandaag beter kan en waar het beleid over pakweg vijf jaar wil
              staan. Duurzaam leiderschap weet zich te verspreiden doorheen de
              school en misschien zelfs daarbuiten.
            </p>

            <p className="theoryPage__text">
              Duurzaam leiderschap leidt tot initiatieven en projecten, die een
              langdurig en blijvend karakter hebben binnen de school. Zo’n
              leiderschap is uiteraard geen “one (wo)man’s show”: het bouwt op
              zowel het leiderschap van de directie als het leiderschap van het
              volledige schoolteam.
            </p>

            <p className="theoryPage__blockquote">
              “Vertrouw de mensen waar je mee werkt, geeft die dat mandaat,
              blijf er met uw pollen af, laat die doen. Want als die mislukken,
              zullen die dat wel rechtzetten. En als iets niet lukt, of ze
              hebben iets meer nodig, ja dan komen ze het wel vragen.”{" "}
            </p>

            <h3 className="theoryPage__sub-subtitle">Middelen van de school</h3>

            <p className="theoryPage__text">
              Iedere school heeft een aantal middelen ter beschikking waarmee ze
              aan de slag kunnen: het time management, de professionele
              structuren en de fysieke structuren.
            </p>

            <h5 className="theoryPage__sub-subtitle-sub">Time management</h5>

            <p className="theoryPage__text">
              Dit gaat over hoe de school de beschikbare tijd benut.
              Leerkrachten krijgen bijvoorbeeld letterlijk tijd om een
              bijscholing over EDO te volgen of een EDO-project uit te denken en
              vorm te geven Daarnaast gaat dit ook over de beschikbare
              lestijden. Wanneer er tijd gemaakt wordt om EDO in én overheen
              verschillende vakken aan te pakken, zal het eenvoudiger zijn om
              samen met een onderzoekende houding naar duurzaamheid en EDO te
              kijken.
            </p>

            <p className="theoryPage__blockquote">
              “Dat valt eigenlijk vooral tijdens de WO-lessen, maar het is niet
              zo dat wij vijftig minuten WO geven en daarmee is de kous af. Als
              je een namiddag naar het bos gaat, of je wil een bezoek brengt aan
              een bedrijf dat batterijen recycleert, kan je er nog veel andere
              vakken bij betrekken.”{" "}
            </p>

            <h5 className="theoryPage__sub-subtitle-sub">
              De professionele structuren:
            </h5>

            <p className="theoryPage__text">
              Daarnaast heeft een school heel wat mensen en talenten in huis die
              op een bepaalde manier met elkaar samenwerken: de professionele
              structuren. Door in het beleid goed te kijken naar hoe mensen
              gegroepeerd en georganiseerd zijn en of dit aansluit bij hun
              talenten, competenties en ideeën, kan een EDO-effectieve school de
              professionele structuren waarover ze beschikt verder ontwikkelen.
            </p>

            <p className="theoryPage__blockquote">
              “We hebben een heel dynamisch leerkrachtenteam en binnen die
              leerkrachtengroep zijn er natuurlijk ook heel veel verschillen.
              Sommigen zetten zich heel erg in voor een bepaald onderwerp,
              anderen voor een ander onderwerp. En door collega’s in te zetten
              en samen te zetten op hun talenten en interesses krijg je echt een
              verrijking voor de school.”{" "}
            </p>

            <h5 className="theoryPage__sub-subtitle-sub">
              De fysieke structuren van de school:
            </h5>

            <p className="theoryPage__text">
              Zoals de speelplaats, lesmaterialen, subsidiegeld dat via een
              project is aangevraagd. Wanneer de fysieke structuren onvoldoende
              voorhanden zijn, blijft een EDO-effectieve school niet bij de
              pakken zitten. Beperkte fysieke structuren hinderen een
              EDO-effectieve school niet, ze leiden net tot acties om de
              beschikbare middelen ten volle te benutten. Waar nodig weet de
              EDO-effectieve school de nodige middelen in huis te halen om in te
              zetten op EDO. De keuzes en aanwending van deze fysieke middelen
              tonen dat de school bezig is met EDO.
            </p>

            <p className="theoryPage__blockquote">
              “Als je naar onze speelplaats kijkt, die heeft een ongelofelijk
              belangrijke functie. We hebben die groen gemaakt, er wordt
              regenwater verzameld voor de toiletten en hij heeft een
              belangrijke sociale functie.”
            </p>

            <h2 className="theoryPage__subtitle" id="pluralistisch">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[1].title,
                  });
                }}
              />
              <span className="theory__title-before theory__title-before--school">
                <img src={iconPluralistisch} alt=""></img>
              </span>

              <span
                className="theory__title-emphasis theory__title-emphasis--school"
                style={{ backgroundColor: "#FFA0A0" }}
              >
                {subchapters[1].title}
              </span>
            </h2>

            <h3 className="theoryPage__sub-subtitle">Wat betekent dit?</h3>

            <p className="theoryPage__text">
              Dit betekent dat er ruimte is (of gemaakt wordt) voor
              verschillende meningen en standpunten en dat er kritisch
              gereflecteerd kan worden over zowel de standpunten van de andere
              als over de eigen standpunten.
            </p>

            <p className="theoryPage__text">
              Praten met iemand die er een andere mening op nahoudt, is niet
              altijd even evident. Ook over duurzaamheid lopen de meningen nog
              vaak uit elkaar. In een heel aantal leraarskamers zit er nog wel
              een ‘team-zilverpapier’ tegenover een ‘team-brooddozen’. Toch kan
              het erg leerrijk zijn om andere perspectieven te verkennen en af
              te toetsen aan je eigen opvattingen. Je komt misschien dingen te
              weten waar je zelf nog nooit aan gedacht hebt en misschien blijken
              er in zowel de brooddoos als in het zilverpapier boterhammen met
              palmolie-vrije choco te zitten.
            </p>

            <p className="theoryPage__blockquote">
              “Daardoor krijg je ook meerdere invalshoeken. En net door die
              verschillende invalshoeken uit te wisselen, kan je ook een andere
              kijk op iets krijgen.”
            </p>

            <aside className="theoryPage__school theoryPage__school--middelen">
              <span>Voorbeelden van middelen</span>
              <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
                <li>
                  Time management: door tijdens vergaderingen echt tijd te
                  voorzien om verschillende opvattingen aan bod te laten komen.
                </li>
                <li>
                  Fysieke structuren: bord voorzien om verschillende
                  meningen/ideeën aan op te hangen.
                </li>
              </ul>
            </aside>

            <aside className="theoryPage__school theoryPage__school--leiderschap">
              <span>Voorbeelden van duurzaam leiderschap</span>
              <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
                <li>
                  Als schoolleider houd je rekening met de verschillende
                  opvattingen van de teamleden en zorg je ervoor dat ze ook
                  elkaars opvattingen erkennen.
                </li>
              </ul>
            </aside>

            <h2 className="theoryPage__subtitle" id="relaties">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[2].title,
                  });
                }}
              />
              <span className="theory__title-before theory__title-before--school">
                <img src={iconRelaties} alt=""></img>
              </span>

              <span
                className="theory__title-emphasis theory__title-emphasis--school"
                style={{ backgroundColor: "#FFB9E1" }}
              >
                {subchapters[2].title}
              </span>
            </h2>

            <h3 className="theoryPage__sub-subtitle">Wat betekent dit?</h3>

            <p className="theoryPage__text">
              Een beleid dat inzet op ondersteunende relaties zorgt ervoor dat
              <b>leerkrachten er niet alleen voorstaan bij EDO</b>. Een
              EDO-effectieve school heeft een team dat EDO ook echt{" "}
              <b>als een team aanpakt</b> en elkaar hierin ondersteunt. Door
              informatie uit te wisselen, blijf je als leerkracht op de hoogte
              van waar iedereen mee bezig is en weet je ook bij wie je ten rade
              kunt gaan als het nodig is. Hierdoor kan je als school samen werk
              maken van EDO en er een project van de hele school van maken.
            </p>

            <p className="theoryPage__blockquote">
              “Bij ons op school voelen veel collega’s zich heel erg betrokken
              bij allerlei zaken en projecten, ook naast het feitelijke
              lesgeven. Ik denk dat we hier heel collegiaal zijn naar elkaar
              toe. En dat is een sterkte van onze school, door die
              collegialiteit kunnen al die andere dingen ook wel gemakkelijker
              verlopen.”{" "}
            </p>

            <p className="theoryPage__text">
              Een EDO-effectieve school{" "}
              <b>
                zoekt ondersteuning indien nodig en biedt ze aan waar het kan
              </b>
              . Ouders, de buurt, organisaties die werken rond EDO of
              duurzaamheid, andere scholen,… De lijst van mogelijke partners is
              eindeloos lang.{" "}
              <b>
                Via die partners kan een EDO-effectieve school andere
                perspectieven verkennen en deze binnenbrengen in de school, een
                dialoog aangaan of samen overgaan tot acties
              </b>
              .
            </p>

            <p className="theoryPage__blockquote">
              “Dus dat betekent dan ook dat wat die leerkracht in haar klas
              doet, dat die versterkt moet worden van buiten uit. Dus dat er
              eigenlijk een diversiteit van mensen rondom die leerkracht moet
              komen die elk een bepaalde meerwaarde kunnen bieden. Zo kan het
              een meerwaarde zijn dat iemand als imker hier iets komt vertellen
              over het belang van de bijen. En zo omringen wij de leerkrachten
              met mensen die dus vanuit hun invalshoek onze school versterken.
              Zo staan wij en de leerkrachten er niet alleen voor staat maar
              creëren we eigenlijk een soort krachtige groep rondom die
              leerlingen. En die krachtige groep, dat werkt echt versterkend en
              maakt het geheel ook duurzaam in de tijd.”
            </p>

            <aside className="theoryPage__school theoryPage__school--middelen">
              <span>Voorbeelden van middelen</span>
              <p>
                Een EDO-effectieve school zet de middelen in om samenwerking
                binnen het team en buiten de school te versterken:
              </p>
              <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
                <li>
                  Opzetten van werkgroepen (professionele structuren) om
                  samenwerking binnen het schoolteam te versterken.
                </li>
                <li>
                  Tijd vrijmaken om met partners aan tafel te gaan zitten.
                </li>
                <li>
                  (fysieke) Ruimte voorzien om de school open te stellen voor
                  externe partners.
                </li>
                <li>
                  Financiële middelen via subsidies, projecten,… met partners
                </li>
              </ul>
            </aside>

            <aside className="theoryPage__school theoryPage__school--leiderschap">
              <span>Voorbeelden van duurzaam leiderschap</span>
              <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
                <li>
                  Duurzaam leiderschap is een katalysator voor samenwerking en
                  ondersteunende relaties. Het zorgt ervoor dat mensen samen
                  verder raken dan alleen door hen te stimuleren, motiveren en
                  zelf het voorbeeld te geven.
                </li>
                <li>
                  Duurzaam leiderschap ondersteunt en stimuleert kwaliteitsvol
                  leren voor iedereen.
                </li>
              </ul>
            </aside>

            <h2 className="theoryPage__subtitle" id="aanpasbaarheid">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[3].title,
                  });
                }}
              />
              <span className="theory__title-before theory__title-before--school">
                <img src={iconAanpasbaarheid} alt=""></img>
              </span>

              <span
                className="theory__title-emphasis theory__title-emphasis--school"
                style={{ backgroundColor: "#8BB8FF" }}
              >
                {subchapters[3].title}
              </span>
            </h2>

            <h3 className="theoryPage__sub-subtitle">Wat betekent dit?</h3>

            <p className="theoryPage__text">
              Een school met een hoge collectieve effectiviteit is ervan
              overtuigd dat de leerlingen in staat zijn om te leren (over
              duurzame ontwikkeling) en dat ze als school positief kan bijdragen
              aan dat leren. Wil je als school werk maken van een effectief
              EDO-beleid? Dan kan het zeker helpen dat heel de school overtuigd
              is van hun capaciteiten om dit aan te pakken. Een positieve
              perceptie van de school over haar capaciteiten kan uitgebouwd
              worden door onder andere het delen van succeservaringen: wat
              kunnen we al goed en hoe komt dit?
            </p>

            <p className="theoryPage__blockquote">
              “EDO, dat dat heel moeilijk te toetsen en te testen is van: hebben
              ze dat wel meegenomen? Ik denk dat als je daar als team
              achterstaat, je leeft dat voor, dat dat de sterkte is van een klas
              en de school ook. . . Je bent daar zelf als leerkracht van
              overtuigd en je moet een beetje dat idealisme hebben en daarvoor
              gaan.”
            </p>

            <aside className="theoryPage__school theoryPage__school--middelen">
              <span>Voorbeelden van middelen</span>
              <p>
                Collectieve effectiviteit wordt o.a. versterkt door het delen
                van succes ervaringen, een EDO-effectieve school weet deze
                zichtbaar te maken (fysiek) en maakt expliciet tijd en ruimte
                vrij om deze te delen.
              </p>
            </aside>

            <aside className="theoryPage__school theoryPage__school--leiderschap">
              <span>Voorbeelden van duurzaam leiderschap</span>
              <p>
                Duurzaam leiderschap zet in op het verder versterken van deze
                overtuiging door teamleden te motiveren, overtuigen en te
                erkennen.
              </p>
            </aside>

            <h2 className="theoryPage__subtitle" id="democratie">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[4].title,
                  });
                }}
              />
              <span className="theory__title-before theory__title-before--school">
                <img src={iconDemocratie} alt=""></img>
              </span>

              <span
                className="theory__title-emphasis theory__title-emphasis--school"
                style={{ backgroundColor: "#FFCA89" }}
              >
                {subchapters[4].title}
              </span>
            </h2>

            <h3 className="theoryPage__sub-subtitle">Wat betekent dit?</h3>

            <p className="theoryPage__text">
              Een school met een hoge collectieve effectiviteit is ervan
              overtuigd dat de leerlingen in staat zijn om te leren (over
              duurzame ontwikkeling) en dat ze als school positief kan bijdragen
              aan dat leren. Wil je als school werk maken van een effectief
              EDO-beleid? Dan kan het zeker helpen dat heel de school overtuigd
              is van hun capaciteiten om dit aan te pakken. Een positieve
              perceptie van de school over haar capaciteiten kan uitgebouwd
              worden door onder andere het delen van succeservaringen: wat
              kunnen we al goed en hoe komt dit?
            </p>

            <p className="theoryPage__blockquote">
              “EDO, dat dat heel moeilijk te toetsen en te testen is van: hebben
              ze dat wel meegenomen? Ik denk dat als je daar als team
              achterstaat, je leeft dat voor, dat dat de sterkte is van een klas
              en de school ook. . . Je bent daar zelf als leerkracht van
              overtuigd en je moet een beetje dat idealisme hebben en daarvoor
              gaan.”
            </p>

            <aside className="theoryPage__school theoryPage__school--middelen">
              <span>Voorbeelden van middelen</span>
              <p>
                Collectieve effectiviteit wordt o.a. versterkt door het delen
                van succes ervaringen, een EDO-effectieve school weet deze
                zichtbaar te maken (fysiek) en maakt expliciet tijd en ruimte
                vrij om deze te delen.
              </p>
            </aside>

            <aside className="theoryPage__school theoryPage__school--leiderschap">
              <span>Voorbeelden van duurzaam leiderschap</span>
              <p>
                Duurzaam leiderschap zet in op het verder versterken van deze
                overtuiging door teamleden te motiveren, overtuigen en te
                erkennen.
              </p>
            </aside>

            <h2 className="theoryPage__subtitle" id="visie">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[5].title,
                  });
                }}
              />
              <span className="theory__title-before theory__title-before--school">
                <img src={iconVisie} alt=""></img>
              </span>

              <span
                className="theory__title-emphasis theory__title-emphasis--school"
                style={{ backgroundColor: "#C7DCDD" }}
              >
                {subchapters[5].title}
              </span>
            </h2>

            <h3 className="theoryPage__sub-subtitle">Wat betekent dit?</h3>

            <p className="theoryPage__text">
              Wat een gedeelde visie betekent laat zich het best vatten in
              volgende vraag: “Wat is EDO en waarom doen we het?”.
            </p>

            <p className="theoryPage__text">
              Omdat EDO een breed gegeven is, is het niet eenvoudig om hier een
              eenduidig antwoord op te formuleren. De EDO-effectieve school zal
              voor zichzelf uit moeten maken wat het voor hen inhoudt.
            </p>

            <p className="theoryPage__text">
              De EDO- principes, in functie van de actiecompetentie van de
              leerlingen, zijn hierbij richtinggevend. Wanneer deze concepten
              een plaats krijgen in het schoolbeleid rond EDO, zal het voor de
              school ook makkelijker zijn om concreet werk te maken van EDO. Het
              tweede deel van de vraag richt zich op hoe de school gemotiveerd
              is om werk te maken van EDO. Idealiter is de school intrinsiek
              overtuigd van de meerwaarde van EDO en wil men er vanuit een
              persoonlijke overtuiging werk van maken.
            </p>

            <p className="theoryPage__blockquote">
              “Omdat het gedragen wordt. De bedoeling is ook dat je als
              leerkracht toont dat je achter de beslissing staat. En anders als
              het via meerderheid of dergelijke zou gaan, dan zou je dat niet
              krijgen. Ik denk dat de gedragenheid dan veel minder zou zijn.”
            </p>

            <aside className="theoryPage__school theoryPage__school--middelen">
              <span>Voorbeelden van middelen</span>
              <ul className="theoryPage__text theoryPage__ul--school theoryPage__ul">
                <li>
                  Een EDO-effectieve school toont wat de visie van de school is
                  (letterlijk fysiek tonen dat men rond EDO werkt).
                </li>
                <li>
                  Een EDO-effectieve school werkt aan een gedeelde visie door in
                  de professionele structuren bijvoorbeeld een overlegplatform
                  rond die visie op te zetten.
                </li>
              </ul>
            </aside>

            <aside className="theoryPage__school theoryPage__school--leiderschap">
              <span>Voorbeelden van duurzaam leiderschap</span>
              <p>
                De schoolleider draagt de schoolvisie uit, consulteert samen met
                het team over die visie. Vaak zal de schoolleider de start van
                de olievlek zijn.{" "}
              </p>
            </aside>

            <h2 className="theoryPage__subtitle" id="collectief">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[6].title,
                  });
                }}
              />
              <span className="theory__title-before theory__title-before--school">
                <img src={iconCollectief} alt=""></img>
              </span>

              <span
                className="theory__title-emphasis theory__title-emphasis--school"
                style={{ backgroundColor: "#FFEAA5" }}
              >
                {subchapters[6].title}
              </span>
            </h2>

            <h3 className="theoryPage__sub-subtitle">Wat betekent dit?</h3>

            <p className="theoryPage__text">
              Een school met een hoge collectieve effectiviteit is ervan
              overtuigd dat de leerlingen in staat zijn om te leren (over
              duurzame ontwikkeling) en dat ze als school positief kan bijdragen
              aan dat leren. Wil je als school werk maken van een effectief
              EDO-beleid? Dan kan het zeker helpen dat heel de school overtuigd
              is van hun capaciteiten om dit aan te pakken. Een positieve
              perceptie van de school over haar capaciteiten kan uitgebouwd
              worden door onder andere het delen van succeservaringen: wat
              kunnen we al goed en hoe komt dit?
            </p>

            <p className="theoryPage__blockquote">
              “EDO, dat dat heel moeilijk te toetsen en te testen is van: hebben
              ze dat wel meegenomen? Ik denk dat als je daar als team
              achterstaat, je leeft dat voor, dat dat de sterkte is van een klas
              en de school ook. . . Je bent daar zelf als leerkracht van
              overtuigd en je moet een beetje dat idealisme hebben en daarvoor
              gaan.”
            </p>

            <aside className="theoryPage__school theoryPage__school--middelen">
              <span>Voorbeelden van middelen</span>
              <p>
                Collectieve effectiviteit wordt o.a. versterkt door het delen
                van succes ervaringen, een EDO-effectieve school weet deze
                zichtbaar te maken (fysiek) en maakt expliciet tijd en ruimte
                vrij om deze te delen.
              </p>
            </aside>

            <aside className="theoryPage__school theoryPage__school--leiderschap">
              <span>Voorbeelden van duurzaam leiderschap</span>
              <p>
                Duurzaam leiderschap zet in op het verder versterken van deze
                overtuiging door teamleden te motiveren, overtuigen en te
                erkennen.
              </p>
            </aside>
          </section>

          <nav className="navbar-bottom">
            <div className="navbar-bottom__link navbar-bottom__back btn-light btn-light--hoverless"></div>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/school/introductie"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__next btn-light"
              to={chapters[1].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-text btn-text--nav">
                {chapters[1].title}
              </span>
              <i className="icon-chevron-right icon-chevron-right--nav"></i>
            </Link>
          </nav>
        </main>
      </div>
    );
  }
}

export default EffectieveSchool;
