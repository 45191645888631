import React, { Component } from "react";

import HeaderPage from "../../../components/HeaderPage";
import { Route, Switch } from "react-router";

import PraktijkVoorbeeld1 from "./PraktijkVoorbeeld1";
import PraktijkVoorbeeld2 from "./PraktijkVoorbeeld2";
import PraktijkVoorbeeld3 from "./PraktijkVoorbeeld3";
import HeaderPageMobile from "../../../components/HeaderPageMobile";
import { Link } from "react-router-dom";

class PraktijkProject extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = [
      {
        number: 1,
        chapterName: "Didactisch ontwerp",
        chapterUrl: "/project/introductie",
      },
      {
        number: 2,
        chapterName: "Projecten in de praktijk",
        chapterUrl: "/project/praktijk",
      },
      {
        number: 3,
        chapterName: "Vormingsmateriaal",
        chapterUrl: "/project/vormingsmateriaal",
      },
    ];
    const subchapters = [
      {
        number: 1,
        title: "EDO project 'voor elkaar'",
        url: "/project/praktijk/voorbeeld1",
        description:
          "Leerlingen van de eerste graad B-stroom van PTS campus Mechelen gaan aan de slag met de vraag ‘wat kunnen we voor elkaar doen?’. Dit EDO-project vertrekt vanuit de duurzame ontwikkelingsdoelstellingen: ‘geen armoede’ (SDG 1), ‘geen honger’ (SDG 2) en ‘goede gezondheid en welzijn’ (SDG 3). ",
      },
      {
        number: 2,
        title: "EDO-project 'Wondere wereld – Afval, weg ermee!'",
        url: "/project/praktijk/voorbeeld2",
        description:
          "De leerlingen van de tweede graad van basisschool Tabor Bellem werken binnen het jaarthema ‘Wondere wereld’ rond het deelthema ‘Afval, weg ermee!’. Ervaren, onderzoeken, vaststellen en uitdrukken staan centraal in hun leerproces wanneer inhouden over bijvoorbeeld zwerfvuil, plastic soep en afvalverwerking aan bod komen.",
      },
      {
        number: 3,
        title: "EDO-project 'consumindermaart'",
        url: "/project/praktijk/voorbeeld3",
        description:
          "Onder de titel ‘maart consumindermaand’ staan de leerlingen van de eerste graad uit de Sint Lodewijk stil bij hun eigen consumptiegedrag en handelingen. Ze onderzoeken de impact ervan op de wereld en hoe ze die kunnen verminderen.",
      },
    ];
    return (
      <div>
        {" "}
        <HeaderPage
          type={"project"}
          chapters={chapters}
          activeChapter={2}
        ></HeaderPage>
        <HeaderPageMobile
          type={"project"}
          chapters={chapters}
          activeChapter={1}
        ></HeaderPageMobile>
        <Switch>
        <Route path={["/project/praktijk/voorbeeld1"]}>
            <PraktijkVoorbeeld1 chapters={subchapters} />
          </Route>
          <Route path={["/project/praktijk/voorbeeld2"]}>
            <PraktijkVoorbeeld2 chapters={subchapters} />
          </Route>
          <Route path={["/project/praktijk/voorbeeld3"]}>
            <PraktijkVoorbeeld3 chapters={subchapters} />
          </Route>
          
          <Route
            path={["/project/praktijk", "/project/praktijk/overzicht"]}
            exact
            render={() => (
              <div id="praktijk-project" className="theoryPage">
                <main>
                  <section>
                    <h1 className="theoryPage__title">
                      Laat je inspireren door prikkelende voorbeelden uit de
                      praktijk
                    </h1>
                  </section>
                  <div className="praktijkvoorbeelden__wrapper praktijkvoorbeelden__wrapper--project">
                    {subchapters.map((subchapter, i) => (
                      <div key={i} className="praktijkvoorbeeld__asset">
                        <div
                          className={`praktijkvoorbeeld__img--${subchapter.number}`}
                        ></div>
                        <div>
                          <h3 className="theoryPage__sub-subtitle">
                            {subchapter.title}
                          </h3>
                          <p className="theoryPage__text">
                            {subchapter.description}
                          </p>
                          <Link className="btn-light" to={subchapter.url}>
                            <span className="btn-text">Lees meer</span>
                            <i className="icon-chevron-right"></i>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </main>
              </div>
            )}
          ></Route>
        </Switch>
      </div>
    );
  }
}

export default PraktijkProject;
