import React, { Component } from "react";
import { Link } from "react-router-dom";
import asset1 from "../../../assets/imgs/project/introductie/project_begeleiden.svg";
import { HashLink } from "react-router-hash-link";
import { Waypoint } from "react-waypoint";
import grid from "../../../assets/icons/algemeen/grid.png";

class ProjectBegeleiden extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubtitle: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const chapters = this.props.chapters;
    const subchapters = chapters[4].subchapters;

    return (
      <div id="didactische-concepten" className="theoryPage">
        <nav className="navbar navbar-left">
          <a
            className="navbar-toggler navbar-toggler--project collapsed"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Inhoudstafel <i class="icon-chevron-down"></i>
          </a>
          <div className="collapse navbar-collapse" id="navbarNav">
            {" "}
            <Link
              to={chapters[0].url}
              className="navbar-left__item navbar-left__item--project "
            >
              {chapters[0].title}
            </Link>
            <Link
              to={chapters[1].url}
              className="navbar-left__item navbar-left__item--project "
            >
              {chapters[1].title}
            </Link>
            <Link
              to={chapters[2].url}
              className="navbar-left__item navbar-left__item--project "
            >
              {chapters[2].title}
            </Link>
            <Link
              to={chapters[3].url}
              className="navbar-left__item navbar-left__item--project "
            >
              {chapters[3].title}
            </Link>
            <Link
              to={chapters[4].url}
              className="navbar-left__item navbar-left__item--project navbar-left__item--active"
            >
              {chapters[4].title}
            </Link>
            <Link
              to={chapters[5].url}
              className="navbar-left__item navbar-left__item--project"
            >
              {chapters[5].title}
            </Link>
          </div>
        </nav>
        <main>
          <section>
            <h1 className="theoryPage__title">{chapters[4].title}</h1>

            <p className="theoryPage__text">
              Hoe begeleid je een EDO project dat inzet op autonomie en eigenaarschap van de leerlingen?  
            </p>

            <p className="theoryPage__text">
              Als leerkracht is het belangrijk om te reflecteren over welke rol je opneemt tijdens het leerproces van leerlingen: <b>‘Wie ben ik als leerkracht?’</b> en <b>‘Wat doe ik als leerkracht’</b>.   
            </p>

            <ul className="theoryPage__text theoryPage__ul">
              <li>
                <b>‘Wie ben ik als leerkracht?’</b> hangt sterk samen met wie je als persoon bent: wat zijn je interesses, talenten, waarden, je passies? En op welke manier straal je dit uit en kan je leerlingen inspireren?  
              </li>
              <li>
                <b>‘Wat doe ik als leerkracht?’</b> staat stil bij hoe je het leerproces van leerlingen kan ondersteunen: hoe kan je leerlingen coachen om te groeien in hun actiecompetentie? Op welke manier zet je in op de 3 EDO-principes (onderzoekend – samen – actiegericht)? 
              </li>
            </ul>

            <p className="theoryPage__text">
              Beide vragen kan je vanuit je eigen rol benaderen, maar het kan ook krachtig zijn om deze als leerkrachtenteam te bekijken. Hoe kan je elkaars interesses en passies inzetten doorheen een EDO-project? Hoe kunnen jullie elkaar hierin ondersteunen en aanvullen?  
            </p>
          </section>

          <img
            className="theory__asset"
            src={asset1}
            alt={chapters[1].alt}
          ></img>

          <nav className="navbar-bottom">
            <Link
              className="navbar-bottom__link navbar-bottom__back btn-light"
              to={chapters[3].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <i className="icon-chevron-left"></i>
              <span className="btn-text">{chapters[3].title}</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/project/introductie"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__next btn-light"
              to={chapters[5].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-text">{chapters[5].title}</span>
              <i className="icon-chevron-right"></i>
            </Link>
          </nav>
        </main>
      </div>
    );
  }
}

export default ProjectBegeleiden;
