import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";

import iconEdoBasis from "../assets/icons/algemeen/logo_EDO.svg";
import iconEdoPrikkel from "../assets/icons/prikkel/logo_EDO_prikkel.svg";
import iconEdoProject from "../assets/icons/project/logo_EDO_project.svg";
import iconEdoSchool from "../assets/icons/school/logo_EDO_school.svg";

class HeaderPageMobile extends Component {
  showSettings(event) {
    event.preventDefault();
  }

  render() {
    return (
      <div className="headerPageMobile" style={{ position: "sticky", top: 0 }}>
        <div className="headerPageMobile__left">
          {" "}
          <Link
            to={
              this.props.type === "prikkel"
                ? "/prikkel"
                : this.props.type === "project"
                ? "/project"
                : this.props.type === "school"
                ? "/school"
                : "/"
            }
          >
            <img
              alt="Icon van de EDO-subsite"
              src={
                this.props.type === "prikkel"
                  ? iconEdoPrikkel
                  : this.props.type === "project"
                  ? iconEdoProject
                  : this.props.type === "school"
                  ? iconEdoSchool
                  : iconEdoBasis
              }
            ></img>
          </Link>
        </div>

        <Menu
          customCrossIcon={
            <div>
              <div className="cross-line cross-line1"></div>
              <div className="cross-line cross-line2"></div>
            </div>
          }
          right
          className="mobile-menu"
        >
          <ul className="mobile-menu__item mobile-menu__rest">
            <li>
              <NavLink activeClassName="is-active" to="/">
                Portaal
              </NavLink>
            </li>
          </ul>
          <ul className="mobile-menu__item mobile-menu__prikkel">
            <li>
              <Link
                to="/prikkel"
                className={`navigationBottom__navLink${
                  this.props.type === "prikkel" ? "--active" : ""
                } prikkel-arrow`}
              >
                EDO <span className="prikkel-color">Prikkel</span>{" "}
              </Link>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/prikkel/introductie">
                Een introductie
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/prikkel/praktijk">
                Prikkels in de praktijk
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="is-active"
                to="/prikkel/vormingsmateriaal"
              >
                Vormingsmateriaal
              </NavLink>
            </li>
          </ul>
          <ul className="mobile-menu__item mobile-menu__project">
            <li>
              <Link
                to="/project"
                className={`navigationBottom__navLink${
                  this.props.type === "project" ? "--active" : ""
                } project-arrow`}
              >
                EDO <span className="project-color">Project</span>
              </Link>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/project/introductie">
                Didactisch ontwerp
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/project/praktijk">
                Projecten in de praktijk
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="is-active"
                to="/project/vormingsmateriaal"
              >
                Vormingsmateriaal
              </NavLink>
            </li>
          </ul>
          <ul className="mobile-menu__item mobile-menu__school">
            <li>
              <Link
                to="/school"
                className={`navigationBottom__navLink${
                  this.props.type === "school" ? "--active" : ""
                } school-arrow`}
              >
                EDO <span className="school-color">School</span>{" "}
              </Link>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/school/introductie">
                Effectief worden
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/school/praktijk">
                Schoolbreed in de praktijk
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="is-active"
                to="/school/vormingsmateriaal"
              >
                Vormingsmateriaal
              </NavLink>
            </li>
          </ul>
          <ul className="mobile-menu__item mobile-menu__rest">
            <li>
              <NavLink activeClassName="is-active" to="/over-valies">
                Over VALIES
              </NavLink>
            </li>
          </ul>
          <ul className="mobile-menu__item mobile-menu__rest">
            <li>
              <NavLink activeClassName="is-active" to="/contact">
                Contact
              </NavLink>{" "}
            </li>
          </ul>
        </Menu>
      </div>
    );
  }
}

export default HeaderPageMobile;
