import React, { Component } from "react";
import { Link } from "react-router-dom";
import grid from "../../../assets/icons/algemeen/grid.png";
import iconOnderzoekend from "../../../assets/icons/algemeen/icon_onderzoekend.svg";
import iconSamen from "../../../assets/icons/algemeen/icon_samen.svg";
import iconActiegericht from "../../../assets/icons/algemeen/icon_actiegericht.svg";
import praktijkvoorbeeld3Brief from "../../../assets/imgs/project/praktijkvoorbeelden/foto_brief.png";
import praktijkvoorbeeld3Aardappel from "../../../assets/imgs/project/praktijkvoorbeelden/foto_aardappel.png";
import praktijkvoorbeeld3Onderzoek from "../../../assets/imgs/project/praktijkvoorbeelden/foto_onderzoek.png";
import praktijkvoorbeeld3Kaart from "../../../assets/imgs/project/praktijkvoorbeelden/foto_consuminderkaart.png";

import doelenlijst from "../../../assets/docs/project/praktijkvoorbeelden/download_EDO project ‘consumindermaart’_doelenlijst.pdf";

class PraktijkVoorbeeld1 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = this.props.chapters;
    return (
      <div id="praktijk-prikkel" className="theoryPage">
        <main>
          <section>
            <h1 className="theoryPage__title">
              EDO project 'consumindermaart'
            </h1>
            <p className="theoryPage__text theoryPage__text--bold">
              St- Lodewijk is een secundaire handelsschool in Antwerpen. De
              voorbije 2 jaren zette een kernteam zich in op EDO. Zo werd er een
              schoolbreed project uitgevoerd om de waarden uit het pedagogisch
              plan te koppelen aan de SDG’s en de leerlingenraad actiever te
              betrekken. Hieronder vind je het verloop van het project
              'consuminderen’.
            </p>

            <p className="theoryPage__text">
              Onder de titel ‘maart consumindermaand’ worden de leerlingen
              uitgedaagd om een actie te ondernemen die bijdraagt aan een meer
              duurzame wereld. Alle leerlingen van de eerste graad staan in het
              kader van de vastenmaand stil bij het eigen consumptiegedrag en
              handelingen; en onderzoeken de impact ervan op de wereld en hoe ze
              die kunnen verminderen. Voor dit project werken leerkrachten
              Natuurwetenschappen, Nederlands, Plastische opvoeding en
              levensbeschouwelijke vakken samen aan de EDO-principes. Tijdens
              een ‘consuminderbeurs’ worden de doorlopen acties gepresenteerd
              aan medeleerlingen. Deze presentatie wordt voorbereid in de vakken
              Nederlands (telt als mondeling examen) en het geheel wordt
              voorgesteld in een poster voorbereid in het vak Plastische
              Opvoeding (opdracht collagetechnieken).
            </p>
          </section>

          <aside>
            <h2 className="theoryPage__subtitle">
              In dit EDO-project wordt gewerkt aan ...{" "}
            </h2>

            <h2 className="theoryPage__subtitle theoryPage__subtitle--marked theoryPage__subtitle--marked-onderzoekend">
              <span className="theory__title-before">
                <img
                  src={iconOnderzoekend}
                  alt="Icoon van onderzoekend leren"
                ></img>
              </span>
              Onderzoekend leren
            </h2>

            <ul className="theoryPage__text theoryPage__ul theoryPage__ul--onderzoekend">
              <li>
                Leerlingen verkennen oorzaken, gevolgen van het eigen
                consumptiegedrag.
              </li>
              <li>
                Leerlingen onderzoeken de productie en de impact van consumeren
                op de wereld (hier en elders).
              </li>
            </ul>

            <h2 className="theoryPage__subtitle theoryPage__subtitle--marked theoryPage__subtitle--marked-samen">
              <span className="theory__title-before">
                <img src={iconSamen} alt="Icoon van samen leren"></img>
              </span>
              Samen leren
            </h2>

            <ul className="theoryPage__text theoryPage__ul theoryPage__ul--samen">
              <li>
                Leerlingen gaan in gesprek met elkaar en met hun familie over
                het eigen consumeren.
              </li>
              <li>Leerlingen gaan in gesprek met elkaar en hun ouders.</li>
              <li>
                Leerlingen leren omgaan met uiteenlopende waarden en meningen.
              </li>
            </ul>

            <h2 className="theoryPage__subtitle theoryPage__subtitle--marked theoryPage__subtitle--marked-actiegericht">
              <span className="theory__title-before">
                <img
                  src={iconActiegericht}
                  alt="Icoon van actiegericht leren"
                ></img>
              </span>
              Actiegericht leren
            </h2>

            <ul className="theoryPage__text theoryPage__ul theoryPage__ul--actiegericht">
              Leerlingen bedenken creatieve oplossingen en toekomstgerichte
              handelingen die ze nadien uittesten:
              <li>
                Leerlingen vertrekken vanuit de eigen dagelijkse handelingen.
              </li>
              <li>
                Leerlingen bedenken acties om de impact op de wereld te
                verkleinen.
              </li>
              <li>Leerlingen testen hun acties thuis uit.</li>
              <li>Leerlingen presenteren hun onderzoek en acties.</li>
            </ul>

            <div className="btn-wrapper">
              <a className="btn-light" href={doelenlijst} download>
                <span className="btn-text">Download algemene doelenlijst</span>
              </a>
            </div>
          </aside>

          <section>
            <h2 className="theoryPage__subtitle">Didactisch ontwerp</h2>

            <h3 className="theoryPage__sub-subtitle">
              Uitdaging: minder consumeren tijdens de vastenmaand!
            </h3>

            <p className="theoryPage__text">
              Als smaakmaker wordt een brief verstuurd naar de ouders en de
              leerlingen. Zo worden ze geprikkeld om aan de slag te gaan rond
              hun eigen consumptiegedrag.
            </p>
          </section>

          <img
            src={praktijkvoorbeeld3Brief}
            alt=""
            className="theoryPage__asset"
          />

          <section>
            <h3 className="theoryPage__sub-subtitle">
              Verkennen: van ‘wat valt je op’ tot het wetenschappelijk
              onderzoeken
            </h3>

            <div className="theoryPage__flex theoryPage__flex--left">
              <div>
                <img
                  src={praktijkvoorbeeld3Aardappel}
                  alt=""
                  className="theoryPage__asset"
                />
              </div>

              <p className="theoryPage__text">
                Ter voorbereiding van het project worden de leerlingen
                uitgedaagd om hun eigen gewoontes en meningen in beeld te
                brengen. De leerlingen maken minstens 5 foto’s uit hun omgeving
                waarvan ze vinden dat er iets aan moet veranderen. Bijvoorbeeld
                meer carpoolen, minder afval op straat, zuiniger omgaan met
                water, minder vlees eten, enzovoort. " Als ik ga winkelen dan
                doe ik de groenten altijd in een plastiek zakje.”
              </p>
            </div>

            <div className="theoryPage__flex theoryPage__flex--right">
              <p className="theoryPage__text">
                De leerlingen kiezen een thema waarmee ze graag aan de slag
                willen gaan, en linken dit met grote thema’s zoals energie,
                mobiliteit, grondstoffen, … Ze gaan de uitdaging aan om zelf
                minder impact te veroorzaken, gedurende de maand maart. Dit
                wordt gekoppeld aan een wetenschappelijke aanpak waarbij de
                leerlingen een onderzoeksvraag formuleren (bv. In hoeverre zijn
                mensen in de samenleving bezig met hun plastic verbruik?), een
                hypothese verwoorden en onderzoeken wat het probleem achter het
                probleem is (bv. Mensen zijn bezig met hun plastic verbruik maar
                zijn nog snel geneigd om plastic verpakkingen te gebruiken.).
              </p>

              <div>
                <img
                  src={praktijkvoorbeeld3Onderzoek}
                  alt=""
                  className="theoryPage__asset"
                />
              </div>
            </div>

            <p className="theoryPage__text">
              Er wordt onderzocht wie er impact heeft op de oorzaken en wie de
              gevolgen ondervindt van bv. plastiek verbruik. Tijdens de les
              godsdienst wordt er ingegaan op de eigen verantwoordelijkheid en
              hoever we kunnen gaan in het beïnvloeden van elkaar. De leerlingen
              onderzoeken ook de eigen invloed op de verschillende onderzochte
              problemen.
            </p>
          </section>

          <section>
            <h3 className="theoryPage__sub-subtitle">
              Van verkennen naar bijdragen: hoe kunnen we de impact verkleinen?
            </h3>

            <p className="theoryPage__text">
              De leerlingen dromen van een toekomst aan de hand van het
              onderzochte probleem. Welke toekomst willen ze graag zien? Wat is
              er nodig om deze droom te bereiken? Wat kan je nu doen om een stap
              te zetten richting deze droom? Aan de hand van deze denkoefening
              bedenken leerlingen acties die ze dan effectief zullen uitvoeren.
            </p>

            <p className="theoryPage__blockquote">
              “Ik droom van een wereld zonder plastiek. Daarvoor moeten we
              minder plastiek gebruiken. Ik kan hierover in gesprek gaan met
              mijn ouders tijdens het winkelen. Ik kan zorgen dat ik zelf minder
              plastiek gebruik door een herbruikbare winkeltas mee te brengen.”
            </p>

            <p className="theoryPage__text">
              De leerlingen voeren hun actie uit gedurende de maand maart.
              Iedere dag vinken ze een nummertje af op de consuminderkaart. In
              een logboek houden ze hun bevindingen bij (moeilijkheden, wat valt
              op, welke reacties ontvangen ze).
            </p>
          </section>

          <img
            src={praktijkvoorbeeld3Kaart}
            alt=""
            className="theoryPage__asset"
          />

          <section>
            <p className="theoryPage__blockquote">
              “Eigenlijk viel het best goed mee om wegwerpplastiek te vermijden.
              Ook mijn ouders deden mee!”
            </p>

            <h3 className="theoryPage__sub-subtitle">
              Terugblik: presentatie tijdens de consuminderbeurs
            </h3>

            <p className="theoryPage__text">
              Het onderzoek eindigt met een besluit waarbij de leerlingen
              aantonen hoe ze hebben ‘geconsuminderd’. Aan de hand van een
              creatieve presentatie lichten de leerlingen hun ondervindingen
              toe. Zo krijgen ze ook zicht op elkaars bevindingen, de
              moeilijkheden en successen.
            </p>

            <h3 className="theoryPage__sub-subtitle">
              Reflectie aanpak leerkrachten{" "}
            </h3>

            <p className="theoryPage__blockquote">
              “Zelf heb ik veel bijgeleerd uit dit project. Het was nieuw en de
              eerste keer dat ik op school zelf een project inéén gestoken heb.“
            </p>

            <p className="theoryPage__blockquote">
              “Het is anders dan andere lessen omdat we in dit project
              vakoverschrijdend werken. Dan heb je echt het gevoel dat iedereen
              achter hetzelfde doel staat. Je werkt echt samen met een team aan
              iets! De leerlingen voelen dat ook aan, het is meer dan gewoon
              eens een lesje volgen. Leerlingen zijn extra gemotiveerd omdat ze
              zien hoe de vakken zijn verbonden met elkaar. Het is een groot
              project waar ze zelf bij betrokken zijn en zelf een stuk
              onderzoeken. Dit zorgde voor een hogere interesse en
              eigenaarschap.”
            </p>

            <p className="theoryPage__blockquote">
              “Je wil de leerlingen zelf mee laten beslissen, maar ergens moet
              je toch als leerkracht het overzicht behouden. Je kan hen een
              beetje een leidraad meegeven waarbinnen ze zelf keuzes kunnen
              maken. Daarom is het belangrijk om de opbouw en aanpak van het
              project goed voor te bereiden. De methodieken van de EDO-carrousel
              en de EDO- principes waren hierbij een grote houvast."
            </p>

            <p className="theoryPage__blockquote">
              “In een vakoverschrijdend project is het niet altijd evident om
              alle collega’s mee te krijgen. Sommigen zijn bij de aanvang minder
              enthousiast want het vergt soms een extra engagement. Het is
              belangrijk om hier voldoende aandacht voor te hebben.”
            </p>

            <h3 class="theoryPage__sub-subtitle">Good to know</h3>

            <ul className="theoryPage__text theoryPage__ul">
              <li>
                <a href="/#/prikkel/vormingsmateriaal">
                  vormingsmateriaal EDO-prikkel
                </a>
              </li>
              <li>
                <a
                  href="https://wwf.be/nl/scholen/de-energiedoos"
                  target="_blank"
                >
                  Energiedoos WWF
                </a>
              </li>
              <li>
                <a href="https://11.be/teachforsdgs" target="_blank">
                  SDG brochure ‘Teach for SDG’s
                </a>
              </li>
            </ul>
          </section>

          <nav className="navbar-bottom">
            <Link
              className="navbar-bottom__link navbar-bottom__back btn-light"
              to={chapters[1].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <i className="icon-chevron-left"></i>
              <span className="btn-text">{chapters[1].title}</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/prikkel/praktijk"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <div className="navbar-bottom__link navbar-bottom__back btn-light btn-light--hoverless"></div>
          </nav>
        </main>
      </div>
    );
  }
}

export default PraktijkVoorbeeld1;
