import React, { Component } from "react";
import Footer from "../../components/Footer";
import HeaderPage from "../../components/HeaderPage";
import HeaderPageMobile from "../../components/HeaderPageMobile";
import NavigationBottom from "../../components/NavigationBottom";
import { Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import IntroductieSchool from "./introductie/IntroductieSchool";
import PraktijkSchool from "./praktijk/PraktijkSchool";
import VormingsmateriaalSchool from "./vormingsmateriaal/VormingsmateriaalSchool";
import SchoolShape from "../../assets/icons/school/hero-image_school.svg";
import EffectieveSchool from "./introductie/EffectieveSchool";
import { HashLink } from "react-router-hash-link";

class SchoolHome extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = [
      {
        number: 1,
        chapterName: "Effectief worden",
        chapterUrl: "/school/introductie",
      },
      {
        number: 2,
        chapterName: "Schoolbreed in de praktijk",
        chapterUrl: "/school/praktijk",
      },
      {
        number: 3,
        chapterName: "Vormingsmateriaal",
        chapterUrl: "/school/vormingsmateriaal",
      },
    ];

    return (
      <div>
        <Switch>
          <Route
            path={["/school"]}
            exact
            render={() => (
              <div>
                <HeaderPage
                  type={"school"}
                  chapters={chapters}
                  activeChapter={0}
                ></HeaderPage>
                <HeaderPageMobile
                  type={"school"}
                  chapters={chapters}
                  activeChapter={0}
                ></HeaderPageMobile>
                <main id="school-home">
                  <div className="overview-top">
                    <div className="overview-top__info">
                      <h2 className="overview-top__title">
                        Bouw samen en schoolbreed aan jouw EDO-school
                      </h2>
                      <HashLink className="btn-dark" to="#verken-school">
                        <span className="btn-text">Verken</span>
                        <i className="icon-chevron-right"></i>
                      </HashLink>{" "}
                    </div>
                    <img
                      className="overview-top__shape overview-top__shape--prikkel"
                      src={SchoolShape}
                      alt="School vorm"
                    ></img>
                  </div>
                  <section className="overview-chapter1" id="verken-school">
                    <div className="overview-chapter__info">
                      <h4 className="overview-chapter__subtitle school-color">
                        School
                      </h4>
                      <h2 className="overview-chapter__title">
                        {chapters[0].chapterName}
                      </h2>
                      <p className="overview-chapter__description">
                      EDO wordt pas krachtig als je er met het hele schoolteam kan aan bouwen. Verken op welke manier je samen een efficiënt, duurzaam en effectief schoolbeleid voor EDO kan uittekenen. 
                      </p>
                      <Link className="btn-light" to="/school/introductie">
                        <span className="btn-text">Duik in de kaders</span>
                        <i className="icon-chevron-right"></i>
                      </Link>{" "}
                    </div>
                    <div className="overview-chapter__img overview-chapter__img--1"></div>
                  </section>
                  <section className="overview-chapter2 overview-chapter--mirrored">
                    <div className="overview-chapter__info">
                      <h4 className="overview-chapter__subtitle school-color">
                        School
                      </h4>
                      <h2 className="overview-chapter__title">
                        {chapters[1].chapterName}
                      </h2>
                      <p className="overview-chapter__description">
                        Verken schoolbrede voorbeelden uit de praktijk en krijg zicht hoe je EDO een plaats kan geven in het schoolbeleid.
                      </p>
                      <Link className="btn-light" to="/school/praktijk">
                        <span className="btn-text">Laat je inspireren</span>
                        <i className="icon-chevron-right"></i>
                      </Link>{" "}
                    </div>
                    <div className="overview-chapter__img overview-chapter__img--2"></div>
                  </section>
                  <section className="overview-chapter3">
                    <div className="overview-chapter__info">
                      <h4 className="overview-chapter__subtitle school-color">
                        School
                      </h4>
                      <h2 className="overview-chapter__title">
                        {chapters[2].chapterName}
                      </h2>
                      <p className="overview-chapter__description">
                        Ga zelf aan de slag met materiaal om te groeien richting een EDO-effectieve school of anderen te inspireren. 
                      </p>
                      <Link
                        className="btn-light"
                        to="/school/vormingsmateriaal"
                      >
                        <span className="btn-text">Download materiaal</span>
                        <i className="icon-chevron-right"></i>
                      </Link>{" "}
                    </div>
                    <div className="overview-chapter__img overview-chapter__img--3"></div>
                  </section>
                </main>
              </div>
            )}
          ></Route>
          <Route path={["/school/introductie"]}>
            <IntroductieSchool chapters={chapters}></IntroductieSchool>
          </Route>
          <Route path={["/school/praktijk"]}>
            <PraktijkSchool chapters={chapters}></PraktijkSchool>
          </Route>
          <Route path={["/school/vormingsmateriaal"]}>
            <VormingsmateriaalSchool
              chapters={chapters}
            ></VormingsmateriaalSchool>
          </Route>
        </Switch>
        <NavigationBottom type={"school"}></NavigationBottom>
        <Footer type={"school"}></Footer>
      </div>
    );
  }
}

export default SchoolHome;
