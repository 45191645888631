import React, { Component } from "react";
import { Route, Switch } from "react-router";

import HeaderPage from "../../../components/HeaderPage";
import HeaderPageMobile from "../../../components/HeaderPageMobile";

import Groeien from "./Groeien";
import EffectieveSchool from "./EffectieveSchool";
import OverzichtSchool from "./OverzichtSchool";

class IntroductieSchool extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = this.props.chapters;

    const subchapters = [
      {
        number: 1,
        title: "EDO-effectieve school",
        url: "/school/introductie/edo-effectieve-school",
        subchapters: [
          {
            title: "Door een bril kijken naar je eigen school",
            hash: "#door-een-bril-kijken-naar-je)-eigen-school",
          },
          {
            title: "Pluralistische communicatie",
            hash: "#pluralistische-communicatie",
          },
          {
            title: "Ondersteunende relaties",
            hash: "#ondersteunende-relaties",
          },
          {
            title: "Aanpasbaarheid",
            hash: "#aanpasbaarheid",
          },
          {
            title: "Democratische besluitvorming",
            hash: "#democratische-besluitvorming",
          },
          {
            title: "Gedeelde visie",
            hash: "#gedeelde-visie",
          },
          {
            title: "Collectieve effectiviteit",
            hash: "#collectieve-effectiviteit",
          },
        ],
      },
      {
        number: 2,
        title: "Groeien richting een EDO-effectieve school",
        url: "/school/introductie/groeien-richting-een-edo-effectieve-school",
        subchapters: [
          {
            title: "Educatie richting actiecompetentie",
            hash: "#educatie-richting-actiecompetentie",
          },
        ],
      },
    ];
    return (
      <div>
        <HeaderPage
          type={"school"}
          chapters={chapters}
          activeChapter={1}
        ></HeaderPage>
        <HeaderPageMobile
          type={"school"}
          chapters={chapters}
          activeChapter={1}
        ></HeaderPageMobile>
        <Switch>
          <Route path={["/school/introductie/edo-effectieve-school"]} exact>
            <EffectieveSchool chapters={subchapters} />
          </Route>
          <Route
            path={[
              "/school/introductie/groeien-richting-een-edo-effectieve-school",
            ]}
            exact
          >
            <Groeien chapters={subchapters} />
          </Route>
          <Route
            path={["/school/introductie/overzicht", "/school/introductie"]}
          >
            <OverzichtSchool subchapters={subchapters} />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default IntroductieSchool;
