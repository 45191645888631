import React, { Component } from "react";
import { Link } from "react-router-dom";
import asset1 from "../../../assets/imgs/project/introductie/project_opstarten.svg";
import { HashLink } from "react-router-hash-link";
import { Waypoint } from "react-waypoint";
import grid from "../../../assets/icons/algemeen/grid.png";

class ProjectOpstarten extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubtitle: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const chapters = this.props.chapters;
    const subchapters = [
      {
        title: "Vertrekken vanuit actualiteit",
        url: "#actualiteit",
        alt: "Vertrekken vanuit actualiteit",
      },
      {
        title: "Vertrekken vanuit (onderwijs)doelen",
        url: "#doelen",
        alt: "Vertrekken vanuit (onderwijs)doelen",
      },
      {
        title: "Vertrekken vanuit de SDG's ",
        url: "#sdg",
        alt: "Vertrekken vanuit de SDG's ",
      },
      {
        title: "Vertrekken vanuit een samenwerking met partners",
        url: "#samenwerking",
        alt: "Vertrekken vanuit een samenwerking met partners ",
      },
    ];

    return (
      <div id="didactische-concepten" className="theoryPage">
        <nav className="navbar navbar-left">
          <a
            className="navbar-toggler navbar-toggler--project collapsed"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Inhoudstafel <i class="icon-chevron-down"></i>
          </a>
          <div className="collapse navbar-collapse" id="navbarNav">
            {" "}
            <Link
              to={chapters[0].url}
              className="navbar-left__item navbar-left__item--project "
            >
              {chapters[0].title}
            </Link>
            <Link
              to={chapters[1].url}
              className="navbar-left__item navbar-left__item--project "
            >
              {chapters[1].title}
            </Link>
            <Link
              to={chapters[2].url}
              className="navbar-left__item navbar-left__item--project navbar-left__item--active"
            >
              {chapters[2].title}
            </Link>
            <HashLink
              smooth
              to={subchapters[0].url}
              className={`navbar-left__subitem navbar-left__subitem--project ${
                this.state.activeSubtitle === subchapters[0].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[0].title}
            </HashLink>
            <HashLink
              smooth
              to={subchapters[1].url}
              className={`navbar-left__subitem navbar-left__subitem--project ${
                this.state.activeSubtitle === subchapters[1].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[1].title}
            </HashLink>
            <HashLink
              smooth
              to={subchapters[2].url}
              className={`navbar-left__subitem navbar-left__subitem--project ${
                this.state.activeSubtitle === subchapters[2].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[2].title}
            </HashLink>
            <HashLink
              smooth
              to={subchapters[3].url}
              className={`navbar-left__subitem navbar-left__subitem--project ${
                this.state.activeSubtitle === subchapters[3].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[3].title}
            </HashLink>
            <Link
              to={chapters[3].url}
              className="navbar-left__item navbar-left__item--project"
            >
              {chapters[3].title}
            </Link>
            <Link
              to={chapters[4].url}
              className="navbar-left__item navbar-left__item--project"
            >
              {chapters[4].title}
            </Link>
            <Link
              to={chapters[5].url}
              className="navbar-left__item navbar-left__item--project"
            >
              {chapters[5].title}
            </Link>
          </div>
        </nav>
        <main>
          <section>
            <h1 className="theoryPage__title">{chapters[2].title}</h1>

            <p className="theoryPage__text theoryPage__text--bold">
              Een kwaliteitsvol EDO-project start vanuit opportuninteiten
              aanwezig in de brede schoolcontext: welke vragen leven er bij
              leerlingen? Welke maatschappelijke gebeurtenissen zijn er actueel?
              Wie zijn interessante lokale partners om mee samen te werken? En
              hoe kan dit aansluiten bij de leerdoelen?
            </p>

            <p className="theoryPage__text">
              Je gaat als leerkracht op zoek naar een aanknopingspunt
              aansluitend op de leefwereld van de leerlingen. Dit zorgt voor een
              authentieke leeractiviteit waarbij leerlingen concrete acties
              kunnen bedenken en uittesten in de eigen omgeving.
            </p>

            <p className="theoryPage__text">
              Het uitwerken van een EDO-project betekent aansluiting zoeken bij
              de <b>actualiteit</b>, <b>(onderwijs)doelen</b>, de <b>SDG’s</b>{" "}
              (Duurzame Ontwikkelingsdoelstellingen) en/of vanuit een
              samenwerking met <b>partners</b>. Uiteraard kan je ook meerdere
              vertrekpunten combineren.
            </p>
          </section>

          <img
            className="theory__asset"
            src={asset1}
            alt={chapters[1].alt}
          ></img>

          <section>
            <h2 className="theoryPage__subtitle" id="actualiteit">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[0].title,
                  });
                }}
              />
              {subchapters[0].title}
            </h2>

            <p className="theoryPage__text">
              Een EDO-project vertrekt vanuit een maatschappelijke kwestie die
              bij voorkeur gelinkt is aan de leefwereld van de leerlingen. Als
              leerkracht dien je dus alert te zijn voor wat er leeft in de klas,
              op school, in de buurt, in de samenleving, en bij de leerlingen
              zelf… kortom in de dagdagelijkse actualiteit.
            </p>

            <p className="theoryPage__text">
              <i>
                “In het kader van wereldwaterdag (22/3) kiest een basisschool
                ervoor om een schoolbreed EDO-project rond water uit te werken.
                Het thema leeft bij veel kinderen van ouders met een
                tuinbouwbedrijf, de extreme droogte van afgelopen zomer zorgde
                voor heel wat uitdagingen. De leerkrachten zien kansen om dit
                verder te verkennen en naast de focus op direct waterverbruik
                ook stil te staan bij het indirect waterverbruik gelinkt aan
                voeding, kledij, … “{" "}
              </i>
            </p>

            <p className="theoryPage__text">
              Een dagelijkse actualiteit kan echter evengoed de inhoud van je
              boterhamdoos zijn, tenslotte brengen we elke dag eten mee naar
              school. Ook dit is een manier om de wereld binnen te brengen in de
              school: Welk brood kies je? Welk broodbeleg? Wat is de hoeveelheid
              afval per lunch? Wat is de watervoetafdruk? Wat zijn verschillen
              tussen boterhamdozen in de klas?, .... Zo zie je maar dat een
              boterhamdoos heel wat vragen kan oproepen!
            </p>

            <p className="theoryPage__text">
              Kijk zeker ook naar talenten en interesses van ouders. Misschien
              weet een ouder veel over wetenschappen, natuur, voeding, …. Zo
              kunnen leerlingen op een andere manier in aanraking komen met
              kennis.
            </p>

            <h2 className="theoryPage__subtitle" id="doelen">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[1].title,
                  });
                }}
              />
              {subchapters[1].title}{" "}
            </h2>

            <p className="theoryPage__text">
              Een kwaliteitsvol EDO-project biedt kansen om aan uiteenlopende
              leerdoelen te werken. Een EDO-project is dus meer dan iets extra
              bovenop de bestaande lessen. De breedheid aan inhoudelijke thema’s
              en diverse vaardigheden zorgen er net voor dat een EDO-project een
              vertrekpunt kan zijn voor kwalitatief leren, gelinkt aan de
              verschillende leerdoelen.
            </p>

            <p className="theoryPage__text">
              Een EDO–project is de uitgelezen kans om te werken aan algemene
              (of generieke en vaak transversale) doelen. EDO- leren betekent
              samenwerken, in dialoog gaan met elkaar, omgaan met verschillende
              meningen, ondernemen, creatief denken, …. EDO vindt als geen ander
              aansluiting bij de 21ste eeuwse vaardigheden.
            </p>

            <p className="theoryPage__text">
              Binnen een EDO-project zijn er tal van mogelijkheden om met
              specifieke leerdoelen aan de slag te gaan. Je kan linken leggen
              met wetenschappen of wereldoriëntatie (wat is een broeikasgas?, …
              Wat betekent indirect waterverbruik?, …), maar evengoed met humane
              en creatieve vakken (Welke meningen leven er bij de leerlingen?,
              Hoe ga je om met meningen, Welke oplossing kun je voor een
              probleem bedenken?). Bij de voorbereiding van het EDO-project is
              het dus de moeite om te verkennen welke specifieke leerdoelen
              (vanuit specifieke vakken of domeinen) je kan evalueren.
            </p>

            <p className="theoryPage__text">
              <i>
                “In een secundaire school wil men in een vakoverschrijdend
                EDO-project rond klimaatverandering niet enkel focussen op
                doelen uit natuurwetenschappen. De leerkrachten zien oa. ook
                kansen om aan doelen Nederlands te werken door leerlingen
                teksten rond klimaatverandering te analyseren, hun bevindingen
                vanuit de onderzoeksopdrachten uit de lessen natuurwetenschappen
                aan elkaar te presenteren, …”{" "}
              </i>
            </p>

            <h2 className="theoryPage__subtitle" id="sdg">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[2].title,
                  });
                }}
              />
              {subchapters[2].title}{" "}
            </h2>

            <p className="theoryPage__text">
              De 17 Duurzame Ontwikkelingsdoelstellingen (Sustainable
              Development Goals of SDG’s) geven een goed overzicht van de
              grootste uitdagingen waarvoor we momenteel staan en waar we iets
              aan kunnen veranderen. Ook in onderwijs kan je deze uitdagingen
              als EDO-thema vastpakken op school.
            </p>

            <p className="theoryPage__text">
              De SDG’s bieden een soort taal die ook buiten onderwijs gebruikt
              wordt en die leerlingen helpen begrijpen op welke manier ze kunnen
              bijdragen aan een duurzame wereld. Linken leggen met de SDG’s kan
              ook leiden tot verdieping: Wat verstaan we juist onder de SDG die
              werd geselecteerd? Welke acties worden hier en in andere landen
              gedaan gekoppeld aan deze SDG?, …
            </p>

            <p className="theoryPage__text">
              Je kan een thema dat leeft op school linken aan één of meerdere
              SDG’s of je kan vertrekken vanuit een specifieke SDG en zo gaan
              kijken hoe we die in de eigen omgeving terugvinden.
            </p>

            <p className="theoryPage__text">
              <i>
                “De leerlingen van een secundaire school maken aan het begin van
                het schooljaar kennis met de 17 SDG’s. Ze bekijken samen een
                film en verkennen daarna met welke SDG’s ze reeds aan de slag
                zijn gegaan op school. Dit leidt tot een selectie van enkele
                SDG’s waar de school gericht wil op inzetten tijdens de
                EDO-projectweek.”
              </i>
            </p>

            <h2 className="theoryPage__subtitle" id="samenwerking">
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[3].title,
                  });
                }}
              />
              {subchapters[3].title}{" "}
            </h2>

            <h3 className="theoryPage__sub-subtitle">
              Welke organisaties en partners kan je betrekken bij het
              EDO-project?{" "}
            </h3>

            <p className="theoryPage__text">
              Scholen zijn geen eilanden die los staan van de lokale buurt of
              samenleving. Het is interessant om binnen een EDO-project samen te
              werken met externe partners (bv. een lokale fietswinkel,
              buurtwinkel, een NGO of organisatie, maar ook ouders of
              buurtbewoners); en samen aan de slag te gaan rond actuele vragen
              die leven in de schoolomgeving.
            </p>

            <p className="theoryPage__text">
              <i>
                “In de buurt van een basisschool vraagt een lokale actiegroep al
                enkele jaren aandacht voor de natuur in de brede omgeving van de
                school. Wanneer de plannen voor een ontbossing nabij de school
                concreter worden, willen enkele leraren dit aangrijpen om
                hierrond aan de slag te gaan in de klas. Samen met de lokale
                actiegroep verkennen de leerlingen wat natuurwaarde voor hen
                betekent en op welke manier ze iets samen kunnen ondernemen.”{" "}
              </i>
            </p>

            <h3 className="theoryPage__sub-subtitle">
              Welke leerkrachten en ander schoolpersoneel kan je betrekken bij
              het EDO-project?
            </h3>

            <p className="theoryPage__text">
              Naast een samenwerking met externe partners is het binnen een
              EDO-project verrijkend om binnen de school samen te werken met
              andere leerkrachten, andere vakken, of andere schoolpersoneel
              (zoals het poetspersoneel, een tuinier, directie, medewerkers van
              het secretariaat, ….). Zo krijg je verschillende stemmen en
              specialiteiten binnen het project, waarbij leerlingen op meer
              diverse wijze ondersteund kunnen worden. Door verschillende vakken
              en domeinen te betrekken krijg je meer tijd en ruimte om inzicht
              te krijgen in de EDO–materie. Het samenwerken met collega’s en
              schoolpersoneel zorgt niet alleen voor een gedragen schoolcultuur
              , maar ook voor afstemming en professionalisering op de werkvloer!
            </p>

            <p className="theoryPage__text">
              <i>
                “Door het project samen te geven met andere leerkrachten, slaag
                ik er meer in om mijn eigen expertise en talent als leerkracht
                in te zetten. Komt daar ook nog eens bij dat ik veel van mijn
                collega’s leer en dat we elkaar echt kunnen ondersteunen.”{" "}
              </i>
            </p>
          </section>
          <nav className="navbar-bottom">
            <Link
              className="navbar-bottom__link navbar-bottom__back btn-light"
              to={chapters[1].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <i className="icon-chevron-left"></i>
              <span className="btn-text">{chapters[1].title}</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/project/introductie"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__next btn-light"
              to={chapters[3].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-text">{chapters[3].title}</span>
              <i className="icon-chevron-right"></i>
            </Link>
          </nav>
        </main>
      </div>
    );
  }
}

export default ProjectOpstarten;
