import React, { Component } from "react";
import { Link } from "react-router-dom";
import grid from "../../../assets/icons/algemeen/grid.png";
import iconOnderzoekend from "../../../assets/icons/algemeen/icon_onderzoekend.svg";
import iconSamen from "../../../assets/icons/algemeen/icon_samen.svg";
import iconActiegericht from "../../../assets/icons/algemeen/icon_actiegericht.svg";
import praktijkvoorbeeld2Bellem from "../../../assets/imgs/project/praktijkvoorbeelden/Foto5-2_Els_Bellem.jpg";
import praktijkvoorbeeld2Afsluiter from "../../../assets/imgs/project/praktijkvoorbeelden/afsluiter.png";
import praktijkvoorbeeld2Artikel from "../../../assets/imgs/project/praktijkvoorbeelden/artikel.png";

import doelenlijst from "../../../assets/docs/project/praktijkvoorbeelden/download_EDO project ‘wondere wereld'_doelenlijst.pdf";

class PraktijkVoorbeeld2 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = this.props.chapters;
    return (
      <div id="praktijk-prikkel" className="theoryPage">
        <main>
          <section>
            <h1 className="theoryPage__title">
              EDO project 'Wondere wereld – Afval, weg ermee!'
            </h1>
            <p className="theoryPage__text theoryPage__text--bold">
              Tabor Bellem is een basisschool in Aalter die een klimaat schept
              waarin leerlingen, directie, leerkrachten en ondersteunend
              personeel aangespoord worden om waarden, zoals eerbied voor de
              anderen, solidariteit, verantwoordelijkheid, soberheid,
              creativiteit, … bewuster te beleven en waar de harmonische
              ontwikkeling van de leerlingen centraal staat. Via een jaarthema
              en het opnemen van engagementen wordt dit concreet gemaakt.
            </p>
            <p className="theoryPage__text">
              Bij de uitwerking van het jaarthema 'Wondere wereld' staat de
              ontwikkeling van EDO-onderwijsarrangementen met integratie van
              verschillende leergebieden vanuit het leerplan Zill centraal. De
              apotheose van deze projectwerking is een schoolfeest waar de
              leerlingen per graad hun leerproces en leerresultaten rond een
              deelthema aan het brede publiek presenteren.
            </p>
            <p className="theoryPage__text">
              De leerlingen van de tweede graad werken binnen het jaarthema
              'Wondere wereld' rond het deelthema 'Afval, weg ermee!'. Ervaren,
              onderzoeken, vaststellen en uitdrukken staan centraal in hun
              leerproces wanneer inhouden over bijvoorbeeld zwerfvuil, plastic
              soep en afvalverwerking aan bod komen. De leerlingen werken in de
              maand voor het schoolfeest gedurende een aantal dagdelen aan dit
              project onder begeleiding van de leraar die voornamelijk een
              coachende rol opneemt.
            </p>
          </section>

          <aside>
            <h2 className="theoryPage__subtitle">
              In dit EDO-project wordt gewerkt aan ...{" "}
            </h2>
            <h2 className="theoryPage__subtitle theoryPage__subtitle--marked theoryPage__subtitle--marked-onderzoekend">
              <span className="theory__title-before">
                <img
                  src={iconOnderzoekend}
                  alt="Icoon van onderzoekend leren"
                ></img>
              </span>
              Onderzoekend leren
            </h2>
            <ul className="theoryPage__text theoryPage__ul theoryPage__ul--onderzoekend">
              <li>
                Leerlingen koppelen concrete casussen (zwerfvuil) aan grotere
                duurzaamheidskwesties (plastic soep, afvalverwerking)
              </li>
              <li>
                Leerlingen verkennen oorzaken, gevolgen en diverse betrokkenen
                van duurzaamheidskwesties (zwerfvuil, afvalverwerking, plastic
                soep) en verbinden die met elkaar.
              </li>
            </ul>

            <h2 className="theoryPage__subtitle theoryPage__subtitle--marked theoryPage__subtitle--marked-samen">
              <span className="theory__title-before">
                <img src={iconSamen} alt="Icoon van samen leren"></img>
              </span>
              Samen leren
            </h2>
            <ul className="theoryPage__text theoryPage__ul theoryPage__ul--samen">
              <li>
                Leerlingen verkennen uiteenlopende meningen en waarden gelinkt
                aan de verschillende duurzaamheidskwesties.
              </li>
              <li>
                Leerlingen verkennen hoe ze zelf betrokken zijn met de
                verschillende duurzaamheidskwesties en wat dit met hen doet.
              </li>
            </ul>

            <h2 className="theoryPage__subtitle theoryPage__subtitle--marked theoryPage__subtitle--marked-actiegericht">
              <span className="theory__title-before">
                <img
                  src={iconActiegericht}
                  alt="Icoon van actiegericht leren"
                ></img>
              </span>
              Actiegericht leren
            </h2>
            <ul className="theoryPage__text theoryPage__ul theoryPage__ul--actiegericht">
              Leerlingen bedenken creatieve oplossingen en toekomstgerichte
              handelingen die ze nadien promoten om in de praktijk te brengen:
              <li>
                Leerlingen bedenken verschillende acties gelinkt aan de
                duurzaamheidskwesties.
              </li>
              <li>Leerlingen wisselen ideeën voor acties met elkaar uit.</li>
              <li>
                Leerlingen stellen hun acties voor (o.a. voorstel aan
                burgemeester: geen ballonnen meer bij huwelijken)
              </li>
            </ul>

            <div className="btn-wrapper">
              <a className="btn-light" href={doelenlijst} download>
                <span className="btn-text">Download algemene doelenlijst</span>
              </a>
            </div>
          </aside>

          <section>
            <h2 className="theoryPage__subtitle">Didactisch ontwerp</h2>

            <h3 class="theoryPage__sub-subtitle">Uitdaging: smaakmaker</h3>

            <p className="theoryPage__text">
              Het jaarthema 'Wondere wereld' wordt bij de leerlingen en actoren
              in de schoolomgeving geïntroduceerd via een{" "}
              <a
                class="link-inline link-inline--project"
                href="https://www.youtube.com/watch?v=7lHNTWh9p8Q&t=2s"
                target="_blank"
              >
                schoollied
              </a>{" "}
              en bijhorende videoclip, gebracht door het volledige schoolteam.
              De leerlingen zijn op die manier getriggerd om op zoek te gaan
              naar een goede manier om zorg te dragen voor onze wondere wereld.
              Het schoollied is een eerste sensibilisering om mee te helpen aan
              een grote verandering die klein begint: bij hen op school en in de
              omgeving.
            </p>

            <h3 class="theoryPage__sub-subtitle">Verkennen</h3>

            <p className="theoryPage__text">
              De leerlingen krijgen inspraak rond welk aan het jaarthema gelinkt
              onderwerp ze willen werken en verbinden dit met de SDG's. In de
              tweede graad wordt het thema <b>'Afval, weg ermee!'</b> gekozen.
              Het project start met een verkenning van zwerfvuil vanuit de
              ervaring en verontwaardiging dat er in de schoolomgeving, waar ook
              het natuurgebied 'De Kraenepoel' ligt, veel afval achtergelaten
              wordt.
            </p>

            <p className="theoryPage__text">
              De leerlingen ruimen zwerfvuil op en inventariseren welke soorten
              zwerfvuil ze allemaal vinden. Vervolgens maken ze een mindmap per
              soort zwerfvuil met informatie over het materiaal zoals
              bijvoorbeeld de afbraaktijden. Ze zijn verrast door de lange
              afbraaktijd van de ballonnen.
            </p>

            <p className="theoryPage__text">
              Onder de vorm van collages met trefwoorden visualiseren ze de
              oorzaken en gevolgen van zwerfvuil en ontdekken de problematiek
              van de plastic soep, door ook na te gaan hoe het elders in de
              wereld gesteld is met zwerfvuil.
            </p>

            <p className="theoryPage__text">
              Hun interesse in de plastic soep is gewekt en de leerlingen
              onderzoeken de kwestie: Wat? Waar? Oorzaken? Gevolgen? De
              leerlingen worden er zich gaandeweg meer en meer van bewust dat
              het belangrijk is om afval te beperken en exploreren hergebruik en
              recyclage.
            </p>

            <p className="theoryPage__text">
              Tijdens een bezoek aan de afvalenergiecentrale van IVM te Eeklo
              ontdekken ze dat restafval ook met zorg voor het milieu en het
              klimaat verwerkt kan worden. Tijdens de verkenningsfase krijgen de
              leerlingen de kans om regelmatig hun (uiteenlopende) meningen,
              waarden en gevoelens te delen en te illustreren hoe anderen en
              zijzelf betrokken zijn bij de afvalkwestie.
            </p>
          </section>

          <img
            src={praktijkvoorbeeld2Bellem}
            alt=""
            className="theoryPage__asset"
          />

          <section>
            <h3 class="theoryPage__sub-subtitle">Bijdragen</h3>

            <p className="theoryPage__text">
              Na de uitgebreide verkenning van de afvalkwestie bedenken de
              leerlingen we creatieve oplossingen om met deze
              duurzaamheidskwestie om te gaan. Naast het beperken van de
              afvalberg op school door het verder sorteren, geen plastic
              wegwerpverpakkingen en het gebruik van brood- en koekendozen
              beslissen de leerlingen vooral in de zetten op het sensibiliseren
              van de omgeving. Ze bereiden een attractieve tentoonstelling met
              presentaties voor die zowel een informerend als appellerend
              karakter heeft en de bezoekers op het schoolfeest moet motiveren
              om hun steentje bij te dragen aan het verkleinen van de afvalberg.
            </p>

            <p className="theoryPage__text">
              Vanuit de ontdekking van de lange afbraaktijd van ballonnen en het
              doorlopen leerproces nodigen de leerlingen de burgemeester van
              Aalter uit en doen hem een onderbouwd voorstel om het gebruik van
              het oplaten van ballonnen bij huwelijken in de gemeente aan banden
              te leggen. De leerlingen ontwerpen ook een affiche 'Deze ballon
              gaat niet op'. Het voorstel verschijnt in de lokale pers en in het
              gemeentelijk informatieblad.
            </p>

            <h3 class="theoryPage__sub-subtitle">Gezamenlijke afsluiter</h3>

            <p className="theoryPage__text">
              Het EDO-project eindigt met het schoolfeest 'Wondere wereld'
              waarop alle leerlingen het leerproces en de leerresultaten van de
              respectievelijke deelthema's voorstellen. Dit gebeurt onder de
              vorm van een eco-wandeling waarbij het publiek diverse locaties
              (kerk, plein, zaal, school, …) waar interactief tentoongesteld
              wordt bezoekt. Er zijn ook heel wat randactiviteiten zoals: een
              gezinsworkshop nestkastjes maken, rijden met gekke fietsen, een
              bar met bio- & fair tradeproducten, een sappentrapper en de
              feestelijke opening van de wilgenhut met lied, dans en speech door
              de leerlingen.
            </p>
          </section>

          <img
            src={praktijkvoorbeeld2Afsluiter}
            alt=""
            className="theoryPage__asset"
          />

          <section>
            <h3 class="theoryPage__sub-subtitle">Delen en uitwisselen</h3>

            <p className="theoryPage__text">
              Tijdens het project kan het verloop via een blog op de
              schoolwebsite gevolgd worden. Gaandeweg zetten de leerlingen het
              geleerde en wat ze belangrijk vinden om mee te nemen over afval
              vast op flappen en onder de vorm van creatieve 3D-werkstukken. Die
              vormen de basis voor de tentoonstelling voor het schoolfeest
              waarop de leerlingen de producten en processen aan het publiek
              presenteren. Dankzij de persaandacht rond de actie 'Deze ballon
              gaat niet op' wordt het project ook in de brede omgeving gedeeld
              en wint de school een cheque van 720 euro van het project
              Mooimakers.
            </p>
          </section>

          <img
            src={praktijkvoorbeeld2Artikel}
            alt=""
            className="theoryPage__asset"
          />

          <section>
            <h3 class="theoryPage__sub-subtitle">Reflectie aanpak</h3>

            <p className="theoryPage__text">
              Leerling:{" "}
              <i>
                <b>
                  'Ik ben blij dat ik kan meedoen aan dit project, het wordt
                  tijd dat we de volwassenen bewust maken'
                </b>
              </i>
            </p>

            <p className="theoryPage__text">
              Leraar:{" "}
              <i>
                <b>
                  ''De grootste leerwinst zit in het proces dat de kinderen
                  doormaakten: van onbewust en onwetend naar begaan met ons
                  milieu en onze toekomst'
                </b>
              </i>
            </p>

            <p className="theoryPage__text">
              Leraar:{" "}
              <i>
                <b>
                  ''We zijn trots op de resultaten van het project, onder meer
                  op de gedragsverandering en de bewustmaking bij de kinderen én
                  de volwassenen'{" "}
                </b>
              </i>
            </p>

            <p className="theoryPage__text">
              Leraar:{" "}
              <i>
                <b>
                  ''De reacties van ouders en participanten aan het toonmoment
                  maakten het volledige team trots!'
                </b>
              </i>
            </p>

            <p className="theoryPage__text">
              Leraar:{" "}
              <i>
                <b>
                  ''Het koppelen van de inhouden aan de EDO-principes
                  (onderzoekend, samen, actiegericht) en de bijhorende
                  Zill-doelen is voor veel collega's niet altijd even
                  makkelijk.'
                </b>
              </i>
            </p>

            <p className="theoryPage__text">
              Ouder:{" "}
              <i>
                <b>
                  ''Zelfs thuis wijzen de kinderen ons terecht als we iets niet
                  op de goede manier doen en we de wondere wereld kunnen
                  schaden. Het project zit echt tot onder hun vel'.
                </b>
              </i>
            </p>

            <h3 class="theoryPage__sub-subtitle">Good to know</h3>

            <ul className="theoryPage__text theoryPage__ul">
              <li>
                <a href="https://mooimakers.be/  " target="_blank">
                  https://mooimakers.be/
                </a>
              </li>
            </ul>
          </section>

          <nav className="navbar-bottom">
            <Link
              className="navbar-bottom__link navbar-bottom__back btn-light"
              to={chapters[0].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <i className="icon-chevron-left"></i>
              <span className="btn-text">{chapters[0].title}</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/project/praktijk"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__next btn-light"
              to={chapters[2].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-text">{chapters[2].title}</span>
              <i className="icon-chevron-right"></i>
            </Link>
          </nav>
        </main>
      </div>
    );
  }
}

export default PraktijkVoorbeeld2;
