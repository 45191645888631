import React, { Component } from "react";

import HeaderPage from "../../../components/HeaderPage";
import { Route, Switch } from "react-router";

import PraktijkVoorbeeld1 from "./PraktijkVoorbeeld1";
import PraktijkVoorbeeld2 from "./PraktijkVoorbeeld2";
import PraktijkVoorbeeld3 from "./PraktijkVoorbeeld3";
import PraktijkVoorbeeld4 from "./PraktijkVoorbeeld4";
import HeaderPageMobile from "../../../components/HeaderPageMobile";
import { Link } from "react-router-dom";
class PraktijkPrikkel extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = [
      {
        number: 1,
        chapterName: "Een introductie",
        chapterUrl: "/prikkel/introductie",
      },
      {
        number: 2,
        chapterName: "Prikkels in de praktijk",
        chapterUrl: "/prikkel/praktijk",
      },
      {
        number: 3,
        chapterName: "Vormingsmateriaal",
        chapterUrl: "/prikkel/vormingsmateriaal",
      },
    ];
    const subchapters = [
      {
        number: 1,
        title: "Plastiek op je bord",
        url: "/prikkel/praktijk/voorbeeld1",
        description:
          "Myrthe is aangedaan. Gisteren zag ze op Karrewiet een filmpje over een aangespoelde walvis. Na onderzoek bleek de walvis 22 kg plastic in de maag te hebben: vuilniszakken, visnetten, lijnen en buizen. Hoe kan dit, vraagt ze zich af? … ",
      },
      {
        number: 2,
        title: "Black Friday in de klas",
        url: "/prikkel/praktijk/voorbeeld2",
        description:
          "Een school beslist, gekoppeld aan het jaarthema ‘het kan met wat minder’, deel te nemen aan de #MissieMinder-actiedag van MOS. In de klas van leerkracht Frank focussen ze zich op het kopen van kledij, meer bepaald de wereldwijde impact van de fast fashion industrie … ",
      },
      {
        number: 3,
        title: "Mag je kiezen waar je woont?",
        url: "/prikkel/praktijk/voorbeeld3",
        description:
          "In de klas merkt leerkracht Els dat de nieuwsberichten rond vluchtelingen discussies losweken. Sommige leerlingen reageren empathisch, anderen zijn bezorgd over de impact op onze maatschappij … ",
      },
      {
        number: 4,
        title: "Met de klas op schoolreis!",
        url: "/prikkel/praktijk/voorbeeld4",
        description:
          "De grote vakantie komt eraan. In de klas wordt reeds druk uitgewisseld waar de reisbestemming dit jaar heengaat. Marco gaat op bezoek bij familie in Marokko en Francesca op safari in Kenia … ",
      },
    ];
    return (
      <div>
        {" "}
        <HeaderPage
          type={"prikkel"}
          chapters={chapters}
          activeChapter={2}
        ></HeaderPage>
        <HeaderPageMobile
          type={"prikkel"}
          chapters={chapters}
          activeChapter={1}
        ></HeaderPageMobile>
        <Switch>
          <Route path={["/prikkel/praktijk/voorbeeld2"]}>
            <PraktijkVoorbeeld2 chapters={subchapters} />
          </Route>
          <Route path={["/prikkel/praktijk/voorbeeld3"]}>
            <PraktijkVoorbeeld3 chapters={subchapters} />
          </Route>
          <Route path={["/prikkel/praktijk/voorbeeld4"]}>
            <PraktijkVoorbeeld4 chapters={subchapters} />
          </Route>
          <Route path={["/prikkel/praktijk/voorbeeld1"]}>
            <PraktijkVoorbeeld1 chapters={subchapters} />
          </Route>
          <Route
            path={["/prikkel/praktijk", "/prikkel/praktijk/overzicht"]}
            exact
            render={() => (
              <div id="praktijk-prikkel" className="theoryPage">
                <main>
                  <section>
                    <h1 className="theoryPage__title">
                      Laat je inspireren door prikkelende voorbeelden uit de
                      praktijk
                    </h1>
                  </section>
                  <div className="praktijkvoorbeelden__wrapper praktijkvoorbeelden__wrapper--prikkel">
                    {subchapters.map((subchapter, i) => (
                      <div key={i} className="praktijkvoorbeeld__asset">
                        <div
                          className={`praktijkvoorbeeld__img--${subchapter.number}`}
                        ></div>
                        <div>
                          <h3 className="theoryPage__sub-subtitle">
                            {subchapter.title}
                          </h3>
                          <p className="theoryPage__text">
                            {subchapter.description}
                          </p>
                          <Link className="btn-light" to={subchapter.url}>
                            <span className="btn-text">Lees meer</span>
                            <i className="icon-chevron-right"></i>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </main>
              </div>
            )}
          ></Route>
        </Switch>
      </div>
    );
  }
}

export default PraktijkPrikkel;
