import React, { Component } from "react";
import HeaderPage from "../../../components/HeaderPage";
import downloadShape from "../../../assets/icons/school/download_school_file.svg";
import HeaderPageMobile from "../../../components/HeaderPageMobile";

import vormingsmateriaal1 from "../../../assets/docs/school/vormingsmateriaal/Beginsituatieanalyse adhv de SDG’s.zip";
import vormingsmateriaal2 from "../../../assets/docs/school/vormingsmateriaal/Kansencirkel EDO.zip";
import vormingsmateriaal3 from "../../../assets/docs/school/vormingsmateriaal/Reflectie EDO op school en in de klas.zip";
import vormingsmateriaal4 from "../../../assets/docs/school/vormingsmateriaal/EDO placemat.zip";
import vormingsmateriaal5 from "../../../assets/docs/school/vormingsmateriaal/Werken in een PLG met TGI.zip";

class VormingsmateriaalSchool extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const chapters = this.props.chapters;

    const assets = [
      {
        title: "Beginsituatieanalyse adhv de SDG’s",
        type: ".zip",
        description:
          "Beginsituatieanalyse rond educatie voor duurzame ontwikkeling voor teams die zich willen toeleggen op een rijk EDO-aanbod op school.",
        shape: downloadShape,
        url: vormingsmateriaal1,
      },
      {
        title: "Kansencirkel EDO",
        type: ".zip",
        description:
          "Werkvorm om met een team vanuit een waarderende houding concrete doelen of acties m.b.t. EDO te formuleren en selecteren.",
        shape: downloadShape,
        url: vormingsmateriaal2,
      },
      {
        title: "Reflectie EDO op school en in de klas",
        type: ".zip",
        description:
          "Drie handvaten om in functie van kwaliteitsontwikkeling als team te reflecteren over EDO op school en in de klas.",
        shape: downloadShape,
        url: vormingsmateriaal3,
      },
      {
        title: "EDO placemat",
        type: ".zip",
        description:
          "EDO-placemat die één oogopslag informatie biedt over het waartoe, het wat, het hoe en het beleid met betrekking tot EDO in de klas en op school. ",
        shape: downloadShape,
        url: vormingsmateriaal4,
      },
      {
        title: "Werken in een PLG met TGI",
        type: ".zip",
        description:
          "Handvatten voor het werken in professionele leergemeenschappen gericht op de implementatie van EDO a.d.h.v. themagecentreerde interactie (TGI).",
        shape: downloadShape,
        url: vormingsmateriaal5,
      },
    ];

    return (
      <div>
        <HeaderPage
          type={"school"}
          chapters={chapters}
          activeChapter={3}
        ></HeaderPage>
        <HeaderPageMobile
          type={"school"}
          chapters={chapters}
          activeChapter={3}
        ></HeaderPageMobile>
        <div className="theoryPage">
          <main>
            <section>
              <h1 className="theoryPage__title">
                Ga zelf aan de slag met het vormingsmateriaal
              </h1>
            </section>
            <div className="materiaal__wrapper">
              {assets.map((asset, i) => (
                <a key={i} className="materiaal__asset" href={asset.url} target="_blank" download>
                    <img src={asset.shape} alt={`${asset.shape}`}></img>
                    <div className="materiaal__info">
                    <h3 className="theoryPage__sub-subtitle">{asset.title}</h3>
                    <p className="small">{asset.description}</p>
                    </div>
                    <small className="prikkel-color">{asset.type}</small>
                </a>
              ))}
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default VormingsmateriaalSchool;
