import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import grid from "../../../assets/icons/algemeen/grid.png";

import { Waypoint } from "react-waypoint";
import { sdgImages } from "../../../assets/icons/algemeen/sdg/sdgImports";
class EDOThemas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubtitle: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const chapters = this.props.chapters;
    const subchapters = [
      {
        title: "SDG's als brede inspiratie",
        url: "brede-inspiratie",
        alt: "",
      },
      {
        title: "Enkele thema's",
        url: "enkele-themas",
        alt: "",
      },
    ];

    return (
      <div id="edo-themas" className="theoryPage">
        <nav className="navbar navbar-left">
          <a
            className="navbar-toggler navbar-toggler--prikkel collapsed"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Inhoudstafel <i class="icon-chevron-down"></i>
          </a>
          <div className="collapse navbar-collapse" id="navbarNav">
            <Link
              to={chapters[0].url}
              className="navbar-left__item navbar-left__item--prikkel"
            >
              {chapters[0].title}
            </Link>
            <Link
              to={chapters[1].url}
              className="navbar-left__item navbar-left__item--prikkel"
            >
              {chapters[1].title}
            </Link>

            <Link
              to={chapters[2].url}
              className="navbar-left__item navbar-left__item--prikkel"
            >
              {chapters[2].title}
            </Link>

            <Link
              to={chapters[3].url}
              className="navbar-left__item navbar-left__item--prikkel navbar-left__item--active"
            >
              {chapters[3].title}
            </Link>

            <HashLink
              smooth
              to={`#${subchapters[0].url}`}
              className={`navbar-left__subitem navbar-left__subitem--prikkel ${
                this.state.activeSubtitle === subchapters[0].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[0].title}
            </HashLink>
            <HashLink
              smooth
              to={`#${subchapters[1].url}`}
              className={`navbar-left__subitem navbar-left__subitem--prikkel ${
                this.state.activeSubtitle === subchapters[1].title
                  ? "navbar-left__subitem--active"
                  : ""
              }`}
            >
              {subchapters[1].title}
            </HashLink>
            <Link
              to={chapters[4].url}
              className="navbar-left__item navbar-left__item--prikkel "
            >
              {chapters[4].title}
            </Link>
          </div>
        </nav>
        <main>
          <section>
            <h1 className="theoryPage__title">{chapters[3].title}</h1>
            <p className="theoryPage__text theoryPage__text--bold">
              Hoe kunnen we er samen voor zorgen dat er genoeg is voor altijd en
              voor iedereen? Een goede gezondheidszorg, geen discriminatie, een
              gezonde biodiversiteit, veiligheid, democratie, een stabiel
              klimaat. We staan voor heel wat grote uitdagingen. Elk van die
              uitdagingen kan je als EDO-thema vastpakken op school.
            </p>

            <h2 className="theoryPage__subtitle" id={subchapters[0].url}>
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[0].title,
                  });
                }}
              />

              {subchapters[0].title}
            </h2>
            <p className="theoryPage__text">
              De Duurzame Ontwikkelingsdoelstellingen (SDG’s) geven een beeld
              van de grootste uitdagingen en kunnen inspiratie bieden voor het
              kiezen van een EDO-thema.
            </p>
            <div className="sdg-wrapper">
              {sdgImages.map((sdg, i) => (
                <img
                  className="sdg-img"
                  key={i}
                  alt={`SDG nummer ${i + 1}`}
                  src={sdg}
                ></img>
              ))}
            </div>

            <h2 className="theoryPage__subtitle" id={subchapters[1].url}>
              <Waypoint
                onEnter={() => {
                  this.setState({
                    activeSubtitle: subchapters[1].title,
                  });
                }}
              />
              {subchapters[1].title} {" "}
            </h2>
            <h3 className="theoryPage__sub-subtitle">
              Voorbeeld 1: thema voeding
            </h3>
            <p className="theoryPage__text">
              In een handboek wordt ingegaan op de nieuwe actieve
              voedingsdriehoek. De leerlingen hielden ter voorbereiding op de
              les gedurende een week een voedings- en bewegingsdagboek bij en
              vergelijken hun levenswijze met het advies in de driehoeken. Er
              ontstaat een discussie:{" "}
              <i className="theoryPage__text--bold">
                “Dit is toch niet haalbaar?” “Waarom mogen we zo weinig rood
                vlees en geen frietjes eten?”, “Mijn ouders zeggen dat rood
                vlees vroeger gezond was!”
              </i>
              . Je noteert de vragen die de leerlingen hebben. Elk groepje
              selecteert de vragen die zij zullen onderzoeken en bereidt een
              presentatie voor. Tijdens de verschillende presentaties worden de
              inzichten uit het onderzoek, de voedingsdriehoek en de duurzame
              ontwikkelingsdoelstellingen (zoals bv. SDG3: goede gezondheid,
              SDG2: duurzame landbouw, SDG 14 & 15: leven in het water en het
              land en SDG13: klimaatactie) schematisch weergegeven. Op basis
              hiervan kan je samen met de leerlingen overleggen welke acties zij
              willen ondernemen.
            </p>
            <div className="sdg-wrapper">
              <img
                src={sdgImages[1]}
                alt={`SDG nummer 2}`}
                className="sdg-img"
              />
              <img
                src={sdgImages[2]}
                alt={`SDG nummer 3}`}
                className="sdg-img"
              />
              <img
                src={sdgImages[13]}
                alt={`SDG nummer 14}`}
                className="sdg-img"
              />
              <img
                src={sdgImages[14]}
                alt={`SDG nummer 15}`}
                className="sdg-img"
              />
            </div>
            <h3 className="theoryPage__sub-subtitle">
              Voorbeeld 2: thema sociale relaties
            </h3>
            <p className="theoryPage__text">
              In de gang ontstaat commotie... leerlingen reageren emotioneel
              omdat één van hen via sociale media gepest wordt omdat ze
              ‘afdragertjes’ draagt van haar zus. Je weet dat ze het thuis niet
              breed hebben. Je ziet kansen om rond mentaal welzijn (SDG 3) en
              armoede (SDG 1) te werken. Ook zie je linken met het waarderen van
              een circulaire economie (SDG 12).
            </p>
            <div className="sdg-wrapper">
              <img
                src={sdgImages[0]}
                alt={`SDG nummer 1}`}
                className="sdg-img"
              />
              <img
                src={sdgImages[2]}
                alt={`SDG nummer 3}`}
                className="sdg-img"
              />
              <img
                src={sdgImages[11]}
                alt={`SDG nummer 12}`}
                className="sdg-img"
              />
            </div>
            <h3 className="theoryPage__sub-subtitle">
              Voorbeeld 3: thema landbouw
            </h3>
            <p className="theoryPage__text">
              De ouders van Jos zijn landbouwers. Jos vertelt in de klas dat ze
              het de laatste jaren erg moeilijk hebben op de boerderij: door de
              droogte is er steeds minder oogst en afgelopen jaar was er teveel
              regen zodat de groenten rot werden... Hij vraagt zich af of
              landbouwers het altijd zo moeilijk gehad hebben.
            </p>
            <p className="theoryPage__text">
              Je ziet verbanden met duurzame voedselvoorziening (SDG 2),
              klimaatverandering (SDG 13), leven in het water (SDG 14) wanneer
              waterlopen droog komen te staan, drinkwatervoorziening die in de
              zomer beperkt wordt, leven op het land (SDG 13), eerlijke
              verloning voor werk (SDG 8) en de situatie in zuiderse landen
            </p>
            <div className="sdg-wrapper">
              <img
                src={sdgImages[1]}
                alt={`SDG nummer 2}`}
                className="sdg-img"
              />
              <img
                src={sdgImages[7]}
                alt={`SDG nummer 8}`}
                className="sdg-img"
              />
              <img
                src={sdgImages[12]}
                alt={`SDG nummer 13}`}
                className="sdg-img"
              />
              <img
                src={sdgImages[13]}
                alt={`SDG nummer 14}`}
                className="sdg-img"
              />
            </div>
            <h3 className="theoryPage__sub-subtitle">
              Voorbeeld 4: thema vluchtelingen
            </h3>
            <p className="theoryPage__text">
              In de krant lees je een artikel over de hongerstaking van
              vluchtelingen. Je merkt dat er heel uiteenlopende meningen zijn
              over vluchtelingen en wil daarom dit gevoelige thema ter sprake
              brengen aan de hand van dit artikel. Je ziet heel wat verbanden
              met de Duurzame Ontwikkelingsdoelstellingen: geen armoede (SDG1),
              goede gezondheid en welzijn (SDG3), eerlijk werk (SDG8),
              ongelijkheid verminderen (SDG10), en vrede en veiligheid (SDG 16)
            </p>
            <div className="sdg-wrapper">
              <img
                src={sdgImages[0]}
                alt={`SDG nummer 1}`}
                className="sdg-img"
              />
              <img
                src={sdgImages[2]}
                alt={`SDG nummer 3}`}
                className="sdg-img"
              />
              <img
                src={sdgImages[7]}
                alt={`SDG nummer 8}`}
                className="sdg-img"
              />
              <img
                src={sdgImages[9]}
                alt={`SDG nummer 10}`}
                className="sdg-img"
              />
              <img
                src={sdgImages[15]}
                alt={`SDG nummer 16}`}
                className="sdg-img"
              />
            </div>
          </section>
          <nav className="navbar-bottom">
            <Link
              className="navbar-bottom__link navbar-bottom__back btn-light"
              to={chapters[2].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <i className="icon-chevron-left"></i>{" "}
              <span className="btn-text">{chapters[2].title}</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/prikkel/introductie"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__next btn-light"
              to={chapters[4].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn-text">{chapters[4].title}</span>
              <i className="icon-chevron-right"></i>
            </Link>
          </nav>
        </main>
      </div>
    );
  }
}

export default EDOThemas;
