import React, { Component } from "react";
import { Link } from "react-router-dom";

class OverzichtSchool extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="tegeloverzicht__wrapper">
        <section className="tegeloverzicht tegeloverzicht-school">
          {this.props.subchapters.map((subchapter) => (
            <Link
              key={subchapter.number}
              className="subchapter__tile subchapter__tile--school"
              to={subchapter.url}
            >
              <div className="subchapter__img-wrapper">
                <div
                  className={`subchapter__img subchapter__img--school${subchapter.number}`}
                ></div>
              </div>
              <div className="subchapter__title subchapter__title--school">
                <h4>{subchapter.title}</h4>
              </div>
            </Link>
          ))}
        </section>
      </div>
    );
  }
}

export default OverzichtSchool;
