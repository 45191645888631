import React, { Component } from "react";
import { Link } from "react-router-dom";

import { HashLink } from "react-router-hash-link";
import { Waypoint } from "react-waypoint";
import grid from "../../../assets/icons/algemeen/grid.png";

import asset1 from "../../../assets/imgs/school/EDO-kompas.jpg";
import EDOkompas from "../../../assets/docs/school/EDO-Kompas.pdf";

class Groeien extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubtitle: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const chapters = this.props.chapters;
    const subchapters = chapters[1].subchapters;

    return (
      <div id="didactische-concepten" className="theoryPage">
        <nav className="navbar navbar-left">
          <a
            className="navbar-toggler navbar-toggler--school collapsed"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Inhoudstafel <i class="icon-chevron-down"></i>
          </a>
          <div className="collapse navbar-collapse" id="navbarNav">
            {" "}
            <Link
              to={chapters[0].url}
              className="navbar-left__item navbar-left__item--school "
            >
              {chapters[0].title}
            </Link>
            <Link
              to={chapters[1].url}
              className="navbar-left__item navbar-left__item--school navbar-left__item--active"
            >
              {chapters[1].title}
            </Link>
          </div>
        </nav>
        
        <main>
            <section>
                <h1 className="theoryPage__title">{chapters[1].title}</h1>
            
                <p className="theoryPage__text theoryPage__text--bold">
                    Samen als school groeien vereist een typische EDO- aanpak met het volledige schoolteam. De kenmerken van een <Link to={chapters[0].url}>EDO-effectieve school</Link> kunnen helpen om als school zicht te krijgen op sterktes en zwaktes en om prioriteiten te bepalen. Hieronder kan je 4 ‘richtingen’ van het EDO-kompas vinden om te werken aan een EDO-effectieve school. Het is een manier om het groeiproces van de school mee vorm te geven en hierover in dialoog te gaan. 
                </p>

                <ul className="theoryPage__ol">
                    <li>
                        Samen doelen formuleren
                        <ul>
                            <li>Werk een EDO-visie uit</li>
                            <li>Maak de EDO-visie zichtbaar</li>
                            <li>Werk een actieplan uit</li>
                            <li>EDO als vast punt op de agenda</li>
                        </ul>
                    </li>
                    <li>
                        Ogen openhouden voor EDO-kansen
                        <ul>
                            <li>Aansluiten bij wat leeft</li>
                            <li>Betrekken van de buurt</li>
                            <li>Living lab</li>
                            <li>Inspelen op talenten</li>
                        </ul>
                    </li>
                    <li>
                        Samen aan de slag
                        <ul>
                            <li>Start een EDO-kernteam op</li>
                            <li>Zet een EDO-buddy in</li>
                            <li>Ga participatief aan de slag</li>
                            <li>Ga co-creatief aan de slag</li>
                        </ul>
                    </li>
                    <li>
                        De school als lerende organisatie
                        <ul>
                            <li>Professionaliseren</li>
                            <li>Delen van leerervaringen</li>
                            <li>Experimenteren</li>
                            <li>Stap per stap</li>
                        </ul>
                    </li>
                </ul>

                <img
                    className="theory__asset"
                    src={asset1}
                    alt={chapters[0].alt}
                ></img>

                <a className="btn-dark" href={EDOkompas} download>
                    <span className="btn-text">Download deze checklist</span>
                    <i className="icon-arrow-down-1"></i>
                </a>
          </section>

          <nav className="navbar-bottom">
            <Link
              className="navbar-bottom__link navbar-bottom__back btn-light"
              to={chapters[0].url}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <i className="icon-chevron-left"></i>
              <span className="btn-text">{chapters[0].title}</span>
            </Link>
            <Link
              className="navbar-bottom__link navbar-bottom__up btn-light"
              to="/school/introductie"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={grid}
                alt="Icoon om naar het overzicht terug te keren"
                className="grid-image"
              />
              <span>overzicht</span>
            </Link>

            <div className="navbar-bottom__link navbar-bottom__next btn-light btn-light--hoverless"></div>
          </nav>
        </main>
      </div>
    );
  }
}

export default Groeien;
